import { BaseMasterItems } from './BaseMasterItems';
import { BookingEvent } from './BookingEvent';
import { JanitorJob } from './JanitorJob';

export class JanitorJobs extends BaseMasterItems {

    public loadFromBookingEvents(bookingEvents: Array<BookingEvent>){
        bookingEvents.forEach((bookingEvent: BookingEvent) => {
            this.createSetupJob(bookingEvent);
            this.createPackupJob(bookingEvent);
        });
    }
    
    private createSetupJob(bookingEvent: BookingEvent){
        let setupJanitorJob: JanitorJob = new JanitorJob();
        setupJanitorJob.loadFromBookingEvent(bookingEvent, 'setup');
        this.items.push(setupJanitorJob);
    }
    
    private createPackupJob(bookingEvent: BookingEvent){
        let packupJanitorJob: JanitorJob = new JanitorJob();
        packupJanitorJob.loadFromBookingEvent(bookingEvent, 'packup');
        this.items.push(packupJanitorJob);
    }

    public orderByStartTimeAscending(){
        console.log("ORDER BY START TIME ASCENDING");
        let orderedJobs = new Array<JanitorJob>();
        let jobsCount = this.items.length;

        for (let i = 0; i < jobsCount; i++) {
            let itemToAdd: JanitorJob = this.items[i];

            if(i == 0){
                orderedJobs.push(itemToAdd);
                continue;
            }

            let orderedJobsCount = orderedJobs.length;

            for (let j = 0; j < orderedJobsCount; j++) {
                // insert before first higher dateTime
                if(orderedJobs[j].startTime > itemToAdd.startTime){
                    orderedJobs.splice(j, 0, itemToAdd);
                    break;
                }
                // insert if it is last element
                if(j == orderedJobsCount -1){
                    orderedJobs.push(itemToAdd);
                    break;
                }
            }
        }

        this.items = orderedJobs;
    }
}
