import { BaseMasterItem } from './BaseMasterItem';
import { MasterDataService } from 'src/app/services/MasterData.service';

export class EventType extends BaseMasterItem {
    selfBookingRelevant: boolean;
    selfBookingNotice:string;
    selfBookingGroups:string;

    loadFromJson(jsonItem){
        super.loadFromJson(jsonItem);
        this.selfBookingRelevant = jsonItem.selfBookingRelevant,
        this.selfBookingNotice = jsonItem.selfBookingInformation,
        this.selfBookingGroups = jsonItem.selfBookingGroups
    }
}
