import { BaseMasterItem } from './BaseMasterItem';
import { MasterDataService } from 'src/app/services/MasterData.service';
import { cwd } from 'node:process';

export class Seating extends BaseMasterItem {
    
    description: string;

    loadFromJson(jsonItem) {
        console.log(jsonItem);
        super.loadFromJson(jsonItem);
        this.description = jsonItem.description;
   }
}
