import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { BookingEvents } from 'src/app/shared/models/BookingEvents';
import { MasterDataService } from 'src/app/services/MasterData.service';
import { Room } from 'src/app/shared/models/room';
import { InitializationService } from 'src/app/services/initialization.service';
import { BookingEvent } from 'src/app/shared/models/BookingEvent';

import { HubConnection } from '@aspnet/signalr';
import { BookingEventsService } from 'src/app/services/BookingEvents.service';
import * as moment from 'moment';

@Component({
  selector: 'app-roomDisplay',
  templateUrl: './roomDisplay.component.html',
  styleUrls: ['./roomDisplay.component.scss'],
})
export class RoomDisplayComponent implements OnInit {
  roomId: number;
  room: Room;
  events: BookingEvents;
  eventsGroupedByDate: any = [];
  currentEvent: BookingEvent;
  
  currentDateEvents = [];
  currentDisplayEvent: BookingEvent;
  currentDateTime:Date;

  displayUpcomingEvents: boolean = false;

  upcomingEventsColumns: string[] = ['title', 'start', 'end'];
  public upcomingEventsData: Array<any> = [
    // { title: 'AGM Meeting', start: '7:30', end: '8:00'},
    { title: 'Brisbane Telegraph Conference', start:  '8:00', end: '9:00'},
    { title: 'Annual Budget Meeting', start: '9:00', end: '9:30'},
    { title: 'Project meeting (AST)', start: '9:30', end: '10:15'},
    { title: 'Project meeting (AST)', start: '11:00', end: '11:45'},
    { title: 'Client Meeting', start: '12:30', end: '1:30'},
    { title: 'Client Meeting [Online]', start: '2:00', end: '3:15'},
    { title: 'Client Meeting', start: '3:30', end: '4:00'},
    { title: 'Board Meeting', start: '4:00', end: '4:30'},
    { title: 'Board Meeting', start: '4:30', end: '6:00'},
    { title: 'Board Meeting', start: '6:00', end: '8:00'},
  ];

  public upcomingEventsfirstItemData: Array<any> = [
    { title: 'Brisbane Telegraph Conference', start: '8:00', end: '9:00'},
    { title: 'Annual Budget Meeting', start: '9:00', end: '9:30'},
    { title: 'Project meeting (AST)', start: '9:30', end: '10:15'},
  ]
  
  constructor(
    private route: ActivatedRoute,
    private masterDataService: MasterDataService,
    private initializationService: InitializationService,
    private bookingEventService: BookingEventsService
  ) {}

  ngOnInit() {
    this.loadData();

    let interval = setInterval(() => {
      if(this.events){
        this.getCurrentDateTime();
        clearInterval(interval);
      }
    },50);
  }

  showNavbar() {
    document.getElementById('nav-bar').style.display = 'block';
  }

  loadData() {
    this.route.params.subscribe((params) => {
      this.roomId = params['id'];

      this.getEvents(1);
      this.getProperties(1);
    });
  }

  // Get all events for this room
  getEvents(id: number) {
    this.bookingEventService.getBookingEventsByRoom(id).subscribe((data) => {
      this.events = data;
      this.groupEventsByDate(this.events);
    });
  }

  getProperties(id: number) {
    this.initializationService.initializationComplete.subscribe(
      (initializationComplete) => {
        if (initializationComplete == true) {
          let room = this.masterDataService.rooms.items.filter(
            (rooms) => rooms.id == id
          );

          if (room.length == 1) {
            this.room = room[0];
          }

          console.log(this.room);
        }
      }
    );
  }

  groupEventsByDate(events) {
    events.items.forEach((e) => {
      let startDate = new Date(e.startTime);
      startDate.setHours(0, 0, 0, 0);

      // Checks to see if the event date is in the array
      let contains = false;
      let index;
      for (let i = 0; i < this.eventsGroupedByDate.length; i++) {
        const element = this.eventsGroupedByDate[i].startDate;
        if (element.getTime() == startDate.getTime()) {
          contains = true;
          index = i;
          break;
        }
      }

      // Pushes data to the array
      if (contains == true) {
        this.eventsGroupedByDate[index].events.push(e);
      } else {
        this.eventsGroupedByDate.push({
          startDate: startDate,
          events: [e],
        });
      }
    });

    console.log(this.eventsGroupedByDate);
  }

  //gets current event
  getCurrentEvent() {
    let isEvent = false;

    this.events.items.forEach(e => {
      if(e.startTime <= this.currentDateTime && e.endTime > this.currentDateTime){
        this.currentEvent = e;
        isEvent = true;
      }
    })

    if(isEvent = false){
      this.currentEvent = null;
    }
  }

  // time / date display
  intervalID;
  getCurrentDateTime() {
    this.intervalID = setInterval(() => {
      if(this.currentDateTime){
        this.currentDateTime = new Date(this.currentDateTime.getTime());
        this.getCurrentEvent();
      } else {
        this.currentDateTime = new Date();
        this.dateAdd(0);
        this.getCurrentEvent();
      }
    }, 1000);
  }
  
  dateAdd(n){
    this.currentDateTime = new Date(
      this.currentDateTime.getTime() + n * 24 * 60 * 60 * 1000
    );

    this.currentDateEvents = [];
    
    this.events.items.forEach(e => {
      if(this.currentDateTime.toDateString() == e.startTime.toDateString()){
        this.currentDateEvents.push(e);
      }
    });

    console.log(this.currentDateEvents);
  }
    
  ngOnDestroy() {
    clearInterval(this.intervalID);
  }

  public displayUpcomingEventsToggle(){
    console.log("display upcoming events toggle");
    this.displayUpcomingEvents == true ? this.displayUpcomingEvents = false : this.displayUpcomingEvents = true;
  }
}
