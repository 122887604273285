import { BaseMasterItem } from './BaseMasterItem';

export abstract class BaseMasterItems {
    public items = [];
    loadFromJson<T extends BaseMasterItem>(c: {new (): T}, data: Array<Object>) {
        data.forEach(jsonItem => {
            let newItem:BaseMasterItem = new c();
            newItem.loadFromJson(jsonItem);
            this.items.push(newItem);
        });
    }

    getById(id: number) {
        for (let index0 = 0; index0 < this.items.length; index0++) {
            let item = this.items[index0];
            if (item.id === id) {
                return item;
            }
        }
        return null;
    }

    getByIds(ids: Array<number>): Array<BaseMasterItem> {
        const retArray = new Array<BaseMasterItem>();
        for (let index0 = 0; index0 < ids.length; index0++) {
            const id = ids[index0];
            retArray.push(this.getById(id));
        }
        return retArray;
    }

    removeItemById(id: number){
        let index = 0;
        this.items.forEach(i => {
            if(i.id == id){
                this.items.splice(index, 1);
                return;
            }
            index++;
        })
    }

    public removeAtIndex(i: number){
        this.items.splice(i, 1);
    }

    replaceItemAtIndex(item: any, index: number){
        this.items.splice(index, 1, item);
    }

    public triggerMaterialTableChangeDetection(){
        this.items = this.items.concat();
    }

}
