import { MsalService } from "@azure/msal-angular";

export class AdUsers {

    value: Array<{id: string, displayName: string, mail: string}>;

    loadFromAdAccountsData(data){
        this.value = data.value;
    }

    getUserByUserId(id: string){
        return this.value.filter(user => user.id == id)[0];
    }
}
