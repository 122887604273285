import { BaseMasterItem } from "./BaseMasterItem";
import { BookingEvent } from "./BookingEvent";
import { Equipments } from "./Equipments";
import { Seating } from "./seating";

export class JanitorJob extends BaseMasterItem {
    startTime: Date;
    endTime: Date;
    roomId: number;
    jobType: string;
    numberOfAttendees: number;
    seating: Seating;
    equipment: Equipments;

    public loadFromBookingEvent(bookingEvent: BookingEvent, jobType: string){
        console.log(bookingEvent);

        this.roomId = bookingEvent.roomId;
        this.seating = bookingEvent.seating;
        this.jobType = jobType;
        this.numberOfAttendees = bookingEvent.numberOfAttendees;

        if(jobType == 'setup'){
            this.startTime = this.calculateSetupStartTime(bookingEvent);
            this.endTime = bookingEvent.startTime;
            this.equipment = bookingEvent.equipment;
        } else if(jobType == 'packup'){
            this.startTime = bookingEvent.endTime;
            this.endTime = this.calculatePackupEndTime(bookingEvent);
        }
    }

    private calculateSetupStartTime(bookingEvent: BookingEvent){
        let dateTime = new Date(bookingEvent.startTime);
        dateTime.setMinutes(dateTime.getMinutes() - bookingEvent.setupTimeMinutes);
        return dateTime;
    }
    
    private calculatePackupEndTime(bookingEvent: BookingEvent){
        let dateTime = new Date(bookingEvent.endTime);
        dateTime.setMinutes(dateTime.getMinutes() + bookingEvent.packupTimeMinutes);
        return dateTime;
    }
}
