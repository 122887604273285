import { Time } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toHour'
})
export class ToHourPipe implements PipeTransform {

  transform(value: number): unknown {

    const newT: number = value % 24;
    if (newT == 0) {
      return '12am';
    } else if (newT < 12) {
      return `${newT}am`;
    } else {
      const commonT: number = newT % 12;
      if (commonT == 0) {
        return `12pm`
      }
      return `${commonT}pm`
    }
    return null;
  }

}
