import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, Host, HostListener, OnInit, ViewChild } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BookingEventsService } from 'src/app/services/BookingEvents.service';
import { RootService } from 'src/app/services/Root.service';
import { BookingEvents } from 'src/app/shared/models/BookingEvents';
import { WeeksBookingEvents } from 'src/app/shared/models/WeeksBookingEvents';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  // View/Rendering
  @ViewChild('pieWrapper', { static: false }) pieWrapper: ElementRef;
  @ViewChild('barWrapper', { static: false }) barWrapper: ElementRef;

  // Public Variables
  public createdBookingEvents: BookingEvents;
  public attendingBookingEvents: BookingEvents;
  public upcomingBookingEvents: WeeksBookingEvents;

  // Private Varaibles
  private account: AccountInfo

  constructor(private bookingEventsService: BookingEventsService, private msalService: MsalService, private rootService: RootService) {}

  ngOnInit(): void {
    this.getCurrentUsersCreatedBookings();



    this.account = this.msalService.instance.getActiveAccount();
    this.loadMyDashboard().subscribe();
    this.data.sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      } else if (b.date > a.date) {
        return 1;
      }
      return 0;
    });
  }

  ngAfterViewInit(): void {
    this.rootService.navigationExpanded$.subscribe(() => {
      this.resizeCharts();
    });
  }

  private getCurrentUsersCreatedBookings(){
    
  }

  private loadMyDashboard(): Observable<void> {
    return forkJoin([
      // All loading functions run in here
      this.getMyCreatedBookingEvents(),
      this.getMyAttendingBookingEvents()
    ]).pipe(
      map(() => {
        // Do stuff
        return null;
      })
    );
  }

  /**
   * Get the current user's created booking events.
   * This function will also set the booking events locally.
   * @returns An observable of the user's booking events
   */
  private getMyCreatedBookingEvents(): Observable<BookingEvents> {
    return this.bookingEventsService.getCurrentUsersFutureBookingEvents(this.account.localAccountId).pipe(
      tap((bookingEvents: BookingEvents) => {
        bookingEvents.setUserRelations('created');
        this.createdBookingEvents = bookingEvents;
      })
    );
  }

  private getMyAttendingBookingEvents(): Observable<BookingEvents> {
    const now = new Date();
    return this.bookingEventsService.getCurrentUsersFutureBookingEventsAttending(this.account.localAccountId, now).pipe(
      tap((bookingEvents: BookingEvents) => {
        bookingEvents.setUserRelations("attending");
        this.attendingBookingEvents = bookingEvents;
      })
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeCharts();
  }

  // =============== Dummy Data ==================

  public data: Array<any> = [
    { date: this.getRandomFutureDate(), title: 'AGM Meeting', location: 'Room 2B, Floor 11', attendees: 36, catering: true, video: true },
    { date: this.getRandomFutureDate(), title: 'Brisbane Telegraph Conference', location: '(External) Brisbane Exhibition Hall', attendees: 5200, catering: true, video: false },
    { date: this.getRandomFutureDate(), title: 'Annual Budget Meeting', location: 'Online (Teams)', attendees: 15, catering: true, video: true },
    // { date: this.getRandomFutureDate(), title: 'Project meeting (AST)', location: 'Meetinng Room 6', attendees: 5, catering: false, video: false },
    // { date: this.getRandomFutureDate(), title: 'Project Meeting (AST)', location: 'Room 6A', attendees: 3, catering: true, video: true },
    // { date: this.getRandomFutureDate(), title: 'Client Meeting', location: 'Room 6A', attendees: 2, catering: false, video: false },
    // { date: this.getRandomFutureDate(), title: 'Client Meeting [Online]', location: 'Room 11B', attendees: 3, catering: false, video: true },
    // { date: this.getRandomFutureDate(), title: 'Client Meeting', location: 'Room 13', attendees: 2, catering: false, video: false },
    // { date: this.getRandomFutureDate(), title: 'Board Meeting', location: 'Meeting Boardroom 3', attendees: 12, catering: true, video: false },
    // { date: this.getRandomFutureDate(), title: 'Board Meeting', location: 'Meeting Room 3', attendees: 8, catering: true, video: true },
    // { date: this.getRandomFutureDate(), title: 'Board Meeting', location: 'Meeting Room 3', attendees: 11, catering: true, video: true }
  ];

  private getRandomFutureDate(): moment.Moment {
    return moment().add(this.generateNumber(1, 200), 'days').hours(this.generateNumber(8, 16)).minute(Math.ceil(this.generateNumber(1, 60)/15) * 15);
  }

  private generateNumber(min: number = 0, max: number): number {
    const ceiling: number = max + 1;
    const num: number = Math.floor(Math.random() * ( ceiling - min ) + min);
    return num % ceiling;
  }

  public readonly colorScheme = {
    domain: [
      '#E26FFC',
      '#FCD87C',
      '#8C62FC',
      '#B3FC49',
      '#0577c6',
      '#5683FC',
      '#FF9961',
      '#05EBCB',
      '#55FF69',
      '#3BFFF9',
      '#AF47FF'
    ]
  }

  // options
  public pieData: any[] = [
    { name: 'Corporate', value: 15, extra: {} },
    { name: 'Fundraising', value: 2, extra: {} },
    { name: 'Conference', value: 14, extra: {} },
    { name: 'Workshop', value: 3, extra: {} },
    { name: 'Virtual', value: 21, extra: {} },
    { name: 'Networking', value: 6, extra: {} },
    { name: 'Meeting', value: 112, extra: {} }
  ];
  public barData: any[] = [
    { name: 'Week 1', value: 0, extra: {} },
    { name: 'Week 2', value: 2, extra: {} },
    { name: 'Week 3', value: 6, extra: {} },
    { name: 'Week 4', value: 2, extra: {} },
    { name: 'Week 5', value: 5, extra: {} },
    { name: 'Week 6', value: 1, extra: {} },
    { name: 'Week 7', value: 3, extra: {} },
    { name: 'Week 8', value: 11, extra: {} },
    { name: 'Week 9', value: 6, extra: {} },
    { name: 'This week (10)', value: 4, extra: {} }
  ]

  public pieChartDimensions: any[] = [0, 0];
  public barChartDimensions: any[] = [0, 0];

  resizeCharts(previousWidth?: number, previousHeight?: number, obj?: ElementRef): void {
    // Smooth the transition for the resizing
    for (var index = 1; index <= 5; index++) {
      setTimeout(() => {
        this.pieChartDimensions = [this.pieWrapper.nativeElement.offsetWidth, this.pieWrapper.nativeElement.offsetHeight]
        this.barChartDimensions = [this.barWrapper.nativeElement.offsetWidth, this.barWrapper.nativeElement.offsetHeight]
        console.log('test!')
      }, 50 * index);
    }
  }
}
