import { Room } from './room';
import { Rooms } from './rooms';
export class DepartmentRooms {
    departmentId: number;
    rooms: Rooms;

    public setDepartmentId(id: number){
        this.departmentId = id;
    }

    public initRooms(){
        this.rooms = new Rooms();
    }

    public addRooms(department){
        department.rooms.rooms.forEach(room => {
            let newRoom = new Room();
            newRoom.loadFromJson(room);
            this.addRoom(newRoom);
        });
    }

    public addRoom(room: Room){
        this.rooms.items.push(room);
    }
}
