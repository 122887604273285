import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Department } from '../shared/models/department';
import { Departments } from '../shared/models/departments';
import { DataService } from './data.service';
import { SqlService } from './Sql.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

constructor(
    private sqlService: SqlService,
    private dataService: DataService
  ) { }


  public getDepartment(){

  }

  public getDepartments(): Observable<Departments> {
    return this.dataService.getItems("departments", "getAllDepartments").pipe(map((departmentsData: any) => {
      let departments = new Departments();
      departments.loadFromJson(Department, departmentsData.items);
      return departments;
    }));
  }

  public createDepartment(department: Department): Observable<Department>{
    // let departmentToPost = new Department();
    // departmentToPost.loadFromForm(department.departmentForm);

    return this.sqlService.create("departments", "createDepartment", department).pipe(map(departmentData => {
      let departmentToReturn = new Department();
      departmentToReturn.loadFromJson(departmentData);
      
      return departmentToReturn;
    }))
  }
  
  public updateDepartment(department: Department){
    console.log("update department")
    let departmentToUpdate = new Department();
    departmentToUpdate.loadFromForm(department.departmentForm);
    console.log(departmentToUpdate);
    
    return this.sqlService.update("departments", "updateDepartment", departmentToUpdate).pipe(map(departmentData => {
      let departmentToReturn = department;
      departmentToReturn.update(departmentData);
      
      console.log(departmentData);

      return departmentToReturn;
    }));
  }

  public deleteDepartment(departmentId: number): Observable<any>{
    return this.dataService.deleteItem("departments", departmentId);
  }

}
