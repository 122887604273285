import { THIS_EXPR } from '@angular/compiler/src/output/output_ast'
import { MasterDataService } from 'src/app/services/MasterData.service'
import { BaseMasterItems } from './BaseMasterItems'
import { BookingEvent } from './BookingEvent'
import { Breaks } from './breaks'
import { Equipments } from './Equipments'
import { Session } from './session'
import { SessionForm } from './sessionForm'

export class Sessions extends BaseMasterItems {

    public initForm(masterDataService: MasterDataService, bookingEventEquipments: Equipments, breaks: Breaks, startDateTime: Date, endDateTime: Date){
        console.log("-- init session form --")
        
        // add session to items if editing a booking event that is already created
        // add empty session to items if youre creating a new booking event
        let session = new Session();
        session.initForm(masterDataService, bookingEventEquipments, breaks, startDateTime, endDateTime);
        
        this.items.push(session);
        this.setSessionsIndex();
    }

    public addSession(masterDataService: MasterDataService){
        let sessionToAdd = new Session();
        sessionToAdd.initForm(masterDataService);

        this.items.push(sessionToAdd);
        this.setSessionsIndex();        
    }

    public deleteSession(){
        console.log("delete session - method not implemented");
         
    }

    public setSessionsIndex(){
        for (let i = 0; i < this.items.length; i++) {
            let session: Session = this.items[i];
            session.index = i;            
        }
    }

    
}
