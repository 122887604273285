
export class BookingForm {
    title: string;
    description: string;

    initForm(){
        this.title = undefined;
        this.description = undefined;
    }

    
}
