import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Bookings } from '../shared/models/bookings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Booking } from '../shared/models/booking';
import { cwd } from 'node:process';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private dataService: DataService) { }

  public addBooking(booking){
    return this.dataService.createNewItem("Bookings", booking).pipe(map(newBooking => {
      let booking = new Booking();
      booking.loadFromJson(newBooking);

      return booking;
    }));
  }

  public addBookings(bookings){
    return this.dataService.createNewItems("bookings", bookings);
  }


  public getBookingById(id): Observable<Booking>{
    return this.dataService.getItems("bookings", id).pipe(map(bookingData => {
      let booking = new Booking();
      booking.loadBookingAndEventsFromData(bookingData);

      return booking;
    }))
  }

  public getBookingsByIds(ids?: Array<string>): Observable<Bookings>{
    console.log("TODO: CHANGE THIS");
    return this.dataService.getItems("bookings", "getBookingsByIds", ids).pipe(map((data: Array<any>) => {
      let bookings = new Bookings();
      bookings.loadFromJson(Booking, data);

      return bookings;
    }));
  }

  public getAllBookings(): Observable<Bookings> {
    return this.dataService.getItems("Bookings").pipe(map((bookingsData: Array<any>) => {
      let bookings = new Bookings();

      bookings.loadFromJson(Booking, bookingsData);
    
      return bookings;
    }));
  }
  
  public getAllBookingsAndEvents(): Observable<Bookings> {
    return this.dataService.getItems("Bookings").pipe(map((bookingsData: Array<any>) => {
      let bookings = new Bookings();
      bookings.loadBookingAndEventsFromData(bookingsData);
    
      return bookings;
    }))
  }

  public getBookingsByUserId(id: string): Observable<any>{
    return this.dataService.getBookingsByUserId(id).pipe(map(bookingsData => {

      console.log(bookingsData);

      let bookings = new Bookings;
      bookings.loadBookingAndEventsFromData(bookingsData.bookings);
      
      return bookings;
    }));
  }

  public getBookingsBySeriesId(id: number){
    return this.dataService.getBookingsBySeriesId(id).pipe(map(bookingsData => {
      let bookings = new Bookings;
      bookings.loadBookingAndEventsFromData(bookingsData);
      
      return bookings;
    }))
  }

  public deleteBooking(id): Observable<any>{
    return this.dataService.deleteItem("Bookings", id);
  }


}
