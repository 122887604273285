import { Component, OnInit } from '@angular/core';
import { CatererService } from 'src/app/services/Caterer.service';
import { UserService } from 'src/app/services/User.service';
import { CatererJobs } from 'src/app/shared/models/Catering/CatererJobs';

@Component({
  selector: 'app-catering',
  templateUrl: './catering.component.html',
  styleUrls: ['./catering.component.scss']
})
export class CateringComponent implements OnInit {

  selectedDate: Date;

  catererJobs: CatererJobs;
  cateringTableColumns: string[] = ['title', 'quantity', 'notes'];

  constructor(
    private catererService: CatererService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.selectedDate = new Date()
    this.getTodaysUsersJobs();
  }

  private getTodaysUsersJobs(){
    let userId = this.userService.getCurrentUsersId();

    this.catererService.getCatererJobsByDate(this.selectedDate, userId).subscribe((catererJobs: CatererJobs) => {
      this.catererJobs = catererJobs;
      this.catererJobs.orderByStartTimeAscending();
    });
  }

  public change_selectedDate(){
    this.getTodaysUsersJobs();
  }

}
