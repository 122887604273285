import { BaseMasterItem } from './BaseMasterItem';
import { BookingEvents } from './BookingEvents';
import { BookingEvent } from './BookingEvent';

export class Booking extends BaseMasterItem{

    creatorComSID: string;
    seriesId: number;
    bookingEvents: BookingEvents;

    disabled: boolean = false;
    expanded: boolean = false;

    loadFromJson(jsonItem){
        super.loadFromJson(jsonItem);
        this.creatorComSID = jsonItem.creatorComSID;
        this.seriesId = jsonItem.seriesId;
    }

    loadBookingAndEventsFromData(jsonItem){
        console.log(jsonItem);

        super.loadFromJson(jsonItem);
        //this.creatorComSID = jsonItem.booking.creatorComSId; 
        //this.seriesId = jsonItem.booking.seriesId;

        //if(jsonItem.booking){
        //}

        if(jsonItem.bookingEvents != undefined){
            this.bookingEvents = new BookingEvents();
            this.bookingEvents.loadFromJson(BookingEvent, jsonItem.bookingEvents);
        }
    }
}
