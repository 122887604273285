import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { generate } from 'rxjs';
import { CalendarEntry, CalendarRow } from '../event-calendar-overview/event-calendar-overview.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  public roomSelection: FormControl = new FormControl();
  public date: Date = new Date();
  public department: string = 'Department 1'

  public filter: boolean = false;
  public hoursPerDay: number = 12;
  public sectionPerHour: number = 4;
  public now = new Date();
  public data: CalendarRow[] = [];

  constructor() { }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.data = [
      {
        label: 'Room One',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Two',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Three',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Four',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Five',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Six',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Seven',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Eight',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Nine',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Ten',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Eleven',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Twelve',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Thirteen',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Forteen',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Fifteen',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Sixteen',
        entries: this.generateRandomEntries()
      },
      {
        label: 'Room Seventeen',
        entries: this.generateRandomEntries()
      }
    ];

    this.roomSelection.setValue(this.data.map(x => x.label))
  }

  private generateRandomEntries(prev: Date = null): CalendarEntry[] {
    const first: boolean = prev == null;
    const entries: CalendarEntry[] = [];
    const num = this.generateNumber(0, 10);
    if (first && num > 7) {
      return [];
    }

    if (prev == null) {
      const start = moment().hour(this.generateNumber(6, 11)).minutes(this.generateNumber(0, 60)).toDate()
      let end = moment(start).add(this.generateNumber(30, 300), 'minutes').toDate()

      entries.push({
        title: this.generateRandomTitle(),
        start,
        end
      });

      prev = moment(end).toDate();
    } else {
      const start = moment(prev).add(this.generateNumber(30, 90), 'minutes').toDate()
      let end = moment(start).add(this.generateNumber(30, 600), 'minutes').toDate()

      if (end.getHours() >= 18) {
        end = moment(end).hours(18).minutes(0).toDate();
      }

      if (start.getDate() == this.now.getDate() && end.getDate() == this.now.getDate()) {
        entries.push({
          title: this.generateRandomTitle(),
          start,
          end
        });
      }

      prev = moment(end).toDate();
    }

    const n = this.generateNumber(0, 1);
    if (n > 0) {
      const more = this.generateRandomEntries(prev);
      for (var index = 0; index < more.length; index++) {
        entries.push(more[index]);
      }
    }

    if (first)
      console.log(entries);

    return entries;
  }

  private generateNumber(min: number = 0, max: number): number {
    const ceiling: number = max + 1;
    const num: number = Math.floor(Math.random() * ( ceiling - min ) + min);
    return num % ceiling;
  }

  private generateRandomTitle(): string {
    const options: string[] = [
      'Board Meeting',
      'Project Update',
      'Project Update',
      'Project Update',
      'Project Update',
      'Client Meeting',
      'Client Meeting',
      'Client Meeting',
      'Stand-up Meeting (SCRUM)',
      'Stand-up Meeting (SCRUM)',
      'Stand-up Meeting (SCRUM)',
      'Financial Meeting',
      'Financial Meeting',
      'Workplace Seminar',
      'Incentive Program Meeting',
      'Upcoming project discussions',
      'AGM',
      'Project Launch Event',
      'Shareholder Meeting',

    ]

    const num = this.generateNumber(0, options.length - 1);
    return options[num];
  }
}
