import { Injectable } from '@angular/core';
import { map, toArray } from 'rxjs/operators';
import { Departments } from '../shared/models/departments';
import { BookingStatuses } from '../shared/models/bookingStatuses';
import { Seatings } from '../shared/models/seatings';
import { Monitors } from '../shared/models/monitors';
import { Department } from '../shared/models/department';
import { Seating } from '../shared/models/seating';
import { Monitor } from '../shared/models/monitor';
import { BookingStatus } from '../shared/models/bookingStatus';
import { TreatmentTypes } from '../shared/models/treatmentTypes';
import { TreatmentType } from '../shared/models/treatmentType';
import { EventTypes } from '../shared/models/eventTypes';
import { EventType } from '../shared/models/eventType';
import { ActionCodes } from '../shared/models/actionCodes';
import { ActionCode } from '../shared/models/actionCode';
import { MaxPers } from '../shared/models/maxPers';
import { MaxPerses } from '../shared/models/maxPerses';
import { Equipments } from '../shared/models/Equipments';
import { Equipment } from '../shared/models/Equipment';
import { Rooms } from '../shared/models/rooms';
import { RoomRelations } from '../shared/models/roomRelations';
import { RoomRelation } from '../shared/models/roomRelation';
import { forkJoin, Observable, concat, BehaviorSubject } from 'rxjs';
import { DataService } from './data.service';
import { EventCategories } from '../shared/models/eventCategories';
import { EventCategory } from '../shared/models/eventCategory';
import { People } from '../shared/models/people';
import { Person } from '../shared/models/person';
import { Users } from '../shared/models/User/Users';
import { AzureAdService } from './AzureAd.service';
import { EquipmentService } from './Equipment.service';
import { RoomService } from './Room.service';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {

  eventCategories: EventCategories;
  monitors: Monitors;
  departments: Departments;
  bookingStatuses: BookingStatuses;
  seatings: Seatings;
  treatmentTypes: TreatmentTypes;
  eventTypes: EventTypes;
  actionCodes: ActionCodes;
  rooms: Rooms;
  roomRelations: RoomRelations;
  equipments: Equipments;
  maxPerses: MaxPerses;
  people: People;

  allActiveDirectoryUsers: Users = new Users();
  
  constructor(
    private dataService: DataService,
    private azureAdService: AzureAdService,
    private equipmentService: EquipmentService,
    private roomService: RoomService,
    ) {  }

  /**
   * Called by Initialization Service
     loads all master data items from the SharePoint Service and stores them as properties in this class
     notifies Initialization Service when all observables have been completed
   */
  loadMasterData():Observable<any> {

    let people$ = this.dataService.getItems('People').pipe(map((data: Array<any>) => {
      this.people =  new People();
      this.people.loadFromJson(Person, data);
    }));

    let eventCategories$ = this.dataService.getItems('EventCategories').pipe(map((data: Array<any>) => {
      this.eventCategories =  new EventCategories();
      this.eventCategories.loadFromJson(EventCategory, data);
    }));
    
    let actionCodes$ = this.dataService.getItems('ActionCodes').pipe(map((data: Array<any>) => {
      this.actionCodes =  new ActionCodes();
      this.actionCodes.loadFromJson(ActionCode, data);
    }));

    let eventTypes$ = this.dataService.getItems('EventTypes').pipe(map((data: Array<any>) => {
      this.eventTypes = new EventTypes();
      this.eventTypes.loadFromJson(EventType, data);
    }))

    let treatmentTypes$ = this.dataService.getItems('treatmentTypes').pipe(map((data: Array<any>) => {
      this.treatmentTypes = new TreatmentTypes();
      this.treatmentTypes.loadFromJson(TreatmentType, data);
    }))

    let Monitors$ = this.dataService.getItems('Monitors').pipe(map((data: Array<any>) => {
      this.monitors = new Monitors();
      this.monitors.loadFromJson(Monitor, data);
    }))
    
    let seatings$ = this.dataService.getItems('Seating', 'getAllSeatings').pipe(map((data: Array<any>) => {
      this.seatings = new Seatings();
      this.seatings.loadFromJson(Seating, data);
    })) 

    let bookingStatuses$ = this.dataService.getItems('BookingStatus').pipe(map((data: Array<any>) => {
      this.bookingStatuses = new BookingStatuses();
      this.bookingStatuses.loadFromJson(BookingStatus, data);
    }))

    let departments$ = this.dataService.getItems('departments').pipe(map((data: Array<any>) => {
      this.departments = new Departments();
      this.departments.loadFromJson(Department, data);
    }))

    let allAdUsers$ = this.azureAdService.getAllAdUsers().pipe(map((res) => {
      if(res != undefined){
          this.allActiveDirectoryUsers = res;
        }
    }))
        
    let equipments$ = this.equipmentService.getAllEquipment().pipe(map((data: any) => {
      this.equipments = new Equipments();
      this.equipments.loadFromJson(Equipment, data.items);
    }))
            
    let rooms$ = this.roomService.getAllRooms().pipe(map((rooms: Rooms) => {
      this.rooms = rooms;
    }))

    let maxPers$ = this.dataService.getItems('MaxPers').pipe(map((data: Array<any>) => {
      this.maxPerses = new MaxPerses();
      this.maxPerses.loadFromJson(MaxPers, data);
      this.maxPerses.loadExtraData(this.seatings, this.rooms, this.departments);
    }))

    let roomRelations$ = this.dataService.getItems("RoomRelations").pipe(map((data: Array<any>) => {
      this.roomRelations = new RoomRelations();
      this.roomRelations.loadFromJson(RoomRelation, data);
    }));

    // Array items of observables to be called in sequence
    let d1 = forkJoin([
      // people$,
      // eventCategories$, 
      // actionCodes$, 
      // eventTypes$, 
      // treatmentTypes$, 
      // Monitors$, 
      seatings$, 
      // bookingStatuses$, 
      departments$,
      allAdUsers$,
      // currentUser$
    ]);
    let d2 = equipments$;
    let d3 = rooms$;
    let d4 = forkJoin([
    // maxPers$
    roomRelations$
    ]);

    // Calls an array of observables and emits when finished
    return concat(d1, d2, d3, d4).pipe(toArray());
  }

  public getSeatings(): Seatings{
    return this.seatings;
  }

  public getDepartments(): Departments{
    return this.departments;
  }

  public getRooms(): Rooms{
    return this.rooms;
  }

}