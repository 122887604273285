<div class="room-display-outer">
  <div *ngIf="room" class="room-display-inner">

    <div class="grid-top">
      <div>
        <span style="font-size: xxx-large;">
          {{currentDateTime | date: 'h:mm'}}
        </span>
        <span style="font-size: x-large;">
          {{currentDateTime | date: 'EEEE, MMMM d'}}
        </span>
      </div>

      <div style="font-size: 70px; color: #fff">
        Meeting Room 3
        <!-- <h1 class="d-flex" style="font-size: 60px;">{{ room.title }}</h1> -->
      </div>

      <div *ngIf="!currentEvent" style="font-size: x-large;">
        Available
      </div>
    </div>

    <div class="grid-bottom">

      <div class="event-grid"  *ngIf="!displayUpcomingEvents">
        <div class="grid-bottom-top">
          <div *ngIf="currentEvent">
            <div class="d-flex" style="margin-top:30px; color: #fff; font-size: 60px;">Board Meeting</div>
            <div class="d-flex" style="color: #0a94d6; font-size: xxx-large;">
              <div>6:00</div>
              <div class="ml-1 mr-1"> - </div>
              <div>7:30</div>
            </div>
            <div class="d-flex" style="color: #0a94d6; font-size: xx-large;">Review last year performance.</div>

            <button mat-button color="primary" style="border: 1px solid #0a94d6; margin-top: 5%;">Attend Event</button>
            <!-- <h1 class="d-flex" style="margin-top:40px; color: #fff;">{{currentEvent.title}}</h1>
            <div class="d-flex" style="color: #0a94d6;">
              <h4>{{currentEvent.startTime | date: 'h:mm a'}}</h4>
              <h4 class="ml-1 mr-1"> - </h4>
              <h4>{{currentEvent.endTime | date: 'h:mm a'}}</h4>
            </div>
            <h4 class="d-flex">{{currentEvent.description}}</h4> -->
          </div>
          
          <div *ngIf="!currentEvent" style="height: 100%; width: 100%; justify-content: center; display: flex;">
            <div style="align-self: center;">
              <button mat-button color="primary" style="border: 1px solid #0a94d6;">Book Now</button>
            </div>
          </div>
        </div>
    
        <div class="grid-bottom-bottom">
          <div style="text-align: center;">
            <button mat-icon-button class="expand-button" color="primary" aria-label="Show all upcoming events" (click)="displayUpcomingEventsToggle()">
              <span class="material-icons">expand_less</span>
            </button>
          </div>

          <div style="padding-bottom: 20px">Upcoming Events</div>
          <table mat-table [dataSource]="upcomingEventsfirstItemData" class="mat-elevation-z8" style="width:100%">
            
            <!-- Name Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> title </th>
              <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="start">
              <th mat-header-cell *matHeaderCellDef> start </th>
              <td mat-cell *matCellDef="let element"> {{element.start}} </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="end">
              <th mat-header-cell *matHeaderCellDef> end </th>
              <td mat-cell *matCellDef="let element"> {{element.end}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="upcomingEventsColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: upcomingEventsColumns;"></tr>
          </table>
          
        </div>
      </div>
  
      <div class="upcoming-events-grid" *ngIf="displayUpcomingEvents">
        <div style="text-align: center;">
          <button mat-icon-button color="primary" aria-label="Show all upcoming events" (click)="displayUpcomingEventsToggle()">
            <span class="material-icons"> expand_more</span>
          </button>
          <div style="padding-bottom: 20px; text-align: left; color: #fff;">
            Upcoming Events
          </div>
        </div>


        <div class="upcoming-events-table">
        
          <table mat-table [dataSource]="upcomingEventsData" class="mat-elevation-z8" style="width:100%">
            
            <!-- Name Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> title </th>
              <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="start">
              <th mat-header-cell *matHeaderCellDef> start </th>
              <td mat-cell *matCellDef="let element"> {{element.start}} </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="end">
              <th mat-header-cell *matHeaderCellDef> end </th>
              <td mat-cell *matCellDef="let element"> {{element.end}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="upcomingEventsColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: upcomingEventsColumns;"></tr>
          </table>

        </div>


      </div>
      
    </div>
  </div>
  