
<div class="reporting-outer">
  <div class="reporting-inner" *ngIf="reportingMonth">

    <div class="top-grid">
      <mat-card class="grid-item-top">
        <div style="font-size: 40px; padding-top: 20px;">
          {{"21%"}}
        </div>
        <div style="color: #00000080;">
          average time wasted (Last Month)
        </div>
      </mat-card>
      <mat-card class="grid-item-top">
        <div style="font-size: 40px; padding-top: 20px;">
          {{"24%"}}
        </div>
        <div style="color: #00000080;">
          average time wasted (Year)
        </div>
      </mat-card>
      <mat-card class="grid-item-top">
        <div style="font-size: 40px; padding-top: 20px;">
          Department 9
        </div>
        <div style="color: #00000080;">
          Most Wasted Time (Department)
        </div>
      </mat-card>
      <mat-card class="grid-item-top">
        <div style="font-size: 40px; padding-top: 20px;">
          Room 177
        </div>
        <div style="color: #00000080;">
          Most Wasted Time (Room)
        </div>
      </mat-card>
    </div>

    <div class="middle-grid">
      <mat-card class="grid-item">
        <h1>Utilized/wasted time for all departments (Last month)</h1>
        <div #chartWrapper>
          <ngx-charts-pie-chart
            [view]="chartDimensions"
            [scheme]="colorScheme"
            [results]="reportingMonth.allRoomsPieChart.data"
            [legend]="true"
            [legendTitle]="false"
            [legendPosition]="'below'"
            [labels]="true"
            [doughnut]="true">
          </ngx-charts-pie-chart>
        </div>
      </mat-card>
      
      <mat-card class="grid-item">
        <h1>Utilized/wasted time all departments per month (Last 12 months)</h1>
        <div #chartWrapper>
          <ngx-charts-line-chart
            [view]="chartDimensions"
            [scheme]="colorScheme"
            [legend]="true"
            [legendTitle]="false"
            [legendPosition]="'below'"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxis]="true"
            [yAxis]="true"
            [xAxisLabel]="'Month'"
            [yAxisLabel]="'Time (minutes)'"
            [timeline]="true"
            [results]="reportingMonth.allRoomYearLineChart">
          </ngx-charts-line-chart>
        </div>
      </mat-card>
  
      <mat-card class="grid-item">
        <h1>Utilized/wasted time per Department</h1>
        <div #chartWrapper>
          <ngx-charts-bar-vertical-stacked
            [view]="chartDimensions"
            [scheme]="colorScheme"
            [results]="reportingMonth.departmentsAllRoomBarChart"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="true"
            [legendTitle]="false"
            [legendPosition]="'below'"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxisLabel]="'Departments'"
            [yAxisLabel]="'Time (minutes)'"
            [animations]="true">
          </ngx-charts-bar-vertical-stacked>
        </div>
      </mat-card>
      
      <mat-card class="grid-item">
        <h1>Utilized/wasted time for Department1 per room</h1>
        <div #chartWrapper>
          <ngx-charts-bar-vertical-stacked
            [view]="chartDimensions"
            [scheme]="colorScheme"
            [results]="reportingMonth.selectedDepartmentRoomsBarChart"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="true"
            [legendTitle]="false"
            [legendPosition]="'below'"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxisLabel]="'Rooms'"
            [yAxisLabel]="'Time (minutes)'"
            [animations]="true">
          </ngx-charts-bar-vertical-stacked>
        </div>
      </mat-card>

    </div>


  </div>
</div>