import { MasterDataService } from "src/app/services/MasterData.service";
import { Breaks } from "./breaks";
import { Department } from "./department";
import { Equipments } from "./Equipments";
import { Room } from "./room";

export class Session {

    id: number;
    index: number;
    title: string;
    room: Room;
    department: Department;
    startTime?: Date;
    endTime?: Date;
    
    bookingEventId: number;
    eventDate: Date;

    durationHour: number;
    durationMinute: number;
    startHour: number;
    startMinute: number;
    startHours: Array<{value: number, disabled: boolean}>;
    startMinutes: Array<{value: number, disabled: boolean}>;
    durationHours: Array<{value: number, disabled: boolean}>;
    durationMinutes: Array<{value: number, disabled: boolean}>;
    
    disabledDates: Array<Date>;

    breaks: Breaks;
    equipments: Equipments;



    public initForm(masterDataService: MasterDataService, bookingEventEquipments?: Equipments, breaks?: Breaks, startDateTime?: Date, endDateTime?: Date){

        // start Date
        this.eventDate = startDateTime;

        //create equipments
        this.equipments = new Equipments();
        this.equipments.initForm(masterDataService, bookingEventEquipments); 

        // create breaks
        this.breaks = new Breaks()
        this.breaks.initForm(breaks);

        // SET START TIME DROPDOWN HOURS
        this.startHours = [];
        for (let i = 6; i < 24; i++) {
            this.startHours.push({value: i, disabled: false});
        }
        
        // SET DURATION DROPDOWN HOURS
        this.durationHours = [];
        for (let i = 0; i < 17 ; i++) {
            this.durationHours.push({value: i, disabled: false});
        }
        
        // SET DROPDOWN MINUTES
        this.startMinutes = [];
        this.durationMinutes = [];
        for (let i = 0; i < 4; i++) {
            this.startMinutes.push({value: (i*15), disabled: false});
            this.durationMinutes.push({value: (i*15), disabled: false});
        }

        if(startDateTime){
            this.startHour = startDateTime.getHours();
            this.startMinute = startDateTime.getMinutes();
    
            // duration hour
            let startTimeMinutes = startDateTime.getHours() * 60 + startDateTime.getMinutes();
            let endTimeMinutes = endDateTime.getHours() * 60 + endDateTime.getMinutes();
            let minuteDifference = endTimeMinutes - startTimeMinutes;
    
            this.durationHour = 0;
    
            for (let i = 60; i <= minuteDifference; i=i+60) {            
                this.durationHour = this.durationHour + 1;
            }
    
            this.durationMinute = minuteDifference - (this.durationHour * 60);
        }
    }

    public SetDisabledFormInputs(sessionFilterData: Session){
        // SET DISABLED DATES
        this.disabledDates = [];

        if(sessionFilterData.disabledDates){
            sessionFilterData.disabledDates.forEach((disabledDate: Date) => {
                this.disabledDates.push(disabledDate);
            });
        }

        // SET DISABLED START HOURS
        for (let i = 0; i < this.startHours.length; i++) {
            this.startHours[i].disabled = sessionFilterData.startHours[i].disabled;
        }
        
        // SET DISABLED START MINUTES
        for (let i = 0; i < this.startMinutes.length; i++) {
            this.startMinutes[i].disabled = sessionFilterData.startMinutes[i].disabled;
        }
        
        // SET DISABLED DURATION HOURS
        for (let i = 0; i < this.durationHours.length; i++) {
            this.durationHours[i].disabled = sessionFilterData.durationHours[i].disabled;
        }
        
        // SET DISABLED DURATION MINUTES
        for (let i = 0; i < this.durationMinutes.length; i++) {
            this.durationMinutes[i].disabled = sessionFilterData.durationMinutes[i].disabled;
        }
        
        // SET EQUIPMENT DROPDOWN QUANTITIES
        this.equipments.setQuantityDropdowns(sessionFilterData.equipments.items);
    }


}
