import { Component, ComponentFactoryResolver, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dateTimeSelect',
  templateUrl: './dateTimeSelect.component.html',
  styleUrls: ['./dateTimeSelect.component.css']
})
export class DateTimeSelectComponent {

  @Output() datesChanged = new EventEmitter();
  @Output() formSubmitted = new EventEmitter();

  minutes: Array<Number> = [0, 15, 30, 45];

  hours: Array<Number> = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

  startDropDownHours: Array<{hour:number, valid: boolean}> = [];

  startDate: Date;
  endDate: Date

  startDateValid: boolean = false;
  endDateValid: boolean = false;

  invalidDates: Array<number> = [];

  showStartError

  // Dropdown variables
  startTime: {
    hours: number,
    minutes: number,
  } = {
    hours: 0,
    minutes: 0,
  }

  endTime: {
    hours: number,
    minutes: number
  } = {
    hours: 0,
    minutes: 0,
  }

  ngOnInit(){
    for (let i = 0; i < 19; i++) {
      let hour = {hour: (i+6), valid: true};
      this.startDropDownHours.push(hour);
    }

    console.log(this.startDropDownHours)
  }

   // Fired when any date changes
  dateChanged() {
    console.log(this);
    if(this.startDate){
      if(this.startTime.hours == 24){
        let hours = Number(this.startTime.hours) - 1;
        console.log(hours);
        this.startTime.minutes = 0;
        this.startDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate(), hours, 59, 59, 99);
      } else {
        this.startDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate(), this.startTime.hours, this.startTime.minutes, 0, 0);
      }

      if(this.startTime.hours != 0){
        this.startDateValid = true;
      }
    }

    if(this.endDate){
      if(this.endTime.hours == 24){
        let hours = Number(this.endTime.hours) - 1;
        console.log(hours);
        this.endTime.minutes = 0;
        this.endDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(), hours, 59, 59, 99);
      } else {
        this.endDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(), this.endTime.hours, this.endTime.minutes, 0, 0);
      }

      if(this.endTime.hours != 0){
        this.endDateValid = true;
      }
    }
  }

  invalidDatesChanged(dates){
    console.log("---------------------------------------------------");
    let startDate = this.startDate;
    let endDate = this.endDate;
    let startHour = this.startTime.hours;
    let endHour = this.endTime.hours;

    console.log(dates);
    console.log(startDate);
    console.log(endDate);
    console.log(startHour);
    console.log(endHour);

    // start date blocked....
    // 1. there is an all day event on this day in the selected room
    // 2. there is a start time selected and it is = start time of event
    // 3. there is a start time selected and it is > start time of an event and < the end time of an event

    // start hours blocked...

    // end date blocked...

    // end hours blocked....

    dates.forEach(date => {
      
    });

    console.log("---------------------------------------------------");

  }

  submitForm(e){
    if(e == 0){
      this.formSubmitted.emit('cancel');
      this.refreshEndDateTime();
      this.refreshStartDateTime();
    } else {
      if(this.startDateValid && this.endDateValid){
        this.formSubmitted.emit({dictator: 'save', dates: {start: this.startDate, end: this.endDate}});
        this.refreshEndDateTime();
        this.refreshStartDateTime();
      }
    }
  }

  //Helper functions
  
  validateDates(){
    let startBox = document.getElementById("start-box");
    if(this.startDate >= this.endDate){
      startBox.style.background = ("rgb(255 130 130 / 65%)");
      this.showStartError = true;
    } else {
      startBox.style.background = ("rgb(0 0 0 / 0%)");
      this.showStartError = false;
    }
  }
  
  refreshStartDateTime(){
    this.startDate = undefined;
    this.startTime.hours = 0;
    this.startTime.minutes = 0;
    this.startDateValid = false;
  }

  refreshEndDateTime(){
    this.endDate = undefined;
    this.endTime.hours = 0;
    this.endTime.minutes = 0;
    this.endDateValid = false;
  }
  
  logDates(){
    console.log({"startDate" : this.startDate}, {"endDate" : this.endDate});
  }

}
