import { BaseMasterItem } from './BaseMasterItem';
import { MasterDataService } from 'src/app/services/MasterData.service';

export class TreatmentType extends BaseMasterItem {
    selfBookingRelevant: boolean;

    loadFromJson(jsonItem) {
        super.loadFromJson(jsonItem);
        this.selfBookingRelevant = jsonItem.selfBookingRelevant
   }

}
