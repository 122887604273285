import { Component, OnInit } from '@angular/core';
import { JanitorService } from 'src/app/services/Janitor.service';
import { UserService } from 'src/app/services/User.service';
import { JanitorJobs } from 'src/app/shared/models/JanitorJobs';

@Component({
  selector: 'app-janitor',
  templateUrl: './janitor.component.html',
  styleUrls: ['./janitor.component.scss']
})
export class JanitorComponent implements OnInit {
  todaysJanitorJobs: JanitorJobs;
  selectedDate: Date;

  constructor(
    //private bookingEventsService: BookingEventsService,
    private userService: UserService,
    private janitorService: JanitorService,
  ) { }

  ngOnInit() {
    this.selectedDate = new Date();
    this.getJanitorJobs(this.selectedDate);
  }

  private getJanitorJobs(date: Date){
    let userId = this.userService.getCurrentUsersId();

    this.janitorService.getJanitorJobsByDate(userId, date).subscribe((janitorJobs) => {
      this.todaysJanitorJobs = janitorJobs;
      console.log(this.todaysJanitorJobs);
      this.todaysJanitorJobs.orderByStartTimeAscending();
    });
  }

  public change_selectedDate(){
    this.getJanitorJobs(this.selectedDate);
  }

}
