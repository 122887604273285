<div class="event-wrapper" *ngIf="bookingEvent">

  

  <!-- <mat-toolbar style="background: #0a94d6;color: white;" *ngIf="bookingEvent.bookingEventForm">    
    <h3 *ngIf="!bookingEvent.bookingEventForm.title" style="margin-bottom: 0px;">New Event</h3>
    <h3 *ngIf="bookingEvent.bookingEventForm.title" style="margin-bottom: 0px;">{{bookingEvent.title}}</h3>
  </mat-toolbar> -->
  
  <div class="event" id="event" *ngIf="bookingEvent">
    <div *ngIf="showDevTools" style="position: fixed; bottom: 0px;">
      dev tools
  
      <button class="btn btn-primary" (click)="filterBookingEventForm()">filter booking event</button>
      <button class="btn btn-primary" (click)="logForm()">Log Form</button>
    </div>
  
  
    <!-- <div class="event-header">
    </div> -->
    
    <!-- -------------------------------------------------------- EDIT EVENT FORM START --------------------------------------------------------- -->
    <div *ngIf="uiOptions.editing" class="form-outer">
      <div *ngIf="!!initializationService.initializationComplete" class="form-inner">
        
        <!-- Event Title -->
        <div class="form-row">
          <mat-form-field class="example-full-width">
            <mat-label>Event Name</mat-label>
            <input matInput autocomplete="off" [(ngModel)]="bookingEvent.bookingEventForm.title">
          </mat-form-field>
        </div>
  
        <!-- Booking Select -->
        <div class="form-row">
          <mat-form-field *ngIf="bookings">
            <mat-label>Booking</mat-label>
            <mat-select [(ngModel)]="bookingEvent.bookingEventForm.booking">
              <mat-option *ngFor="let booking of bookings.items" [value]="booking.id">{{booking.title}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
        <!-- ng model Department Dropdown -->
        <div class="form-row">
          <mat-form-field>
            <mat-label>Department</mat-label>
            <mat-select *ngIf="bookingEvent.bookingEventForm.departments.selectedDepartment" [(ngModel)]="bookingEvent.bookingEventForm.departments.selectedDepartment" (change)="filterBookingEventForm()">
              <mat-option *ngFor="let department of bookingEvent.bookingEventForm.departments.items" [value]="department" [disabled]="department.disabled">{{department.title}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
        <!-- ng model Room Dropdown -->
        <div class="form-row">
          <mat-form-field>
            <mat-label>Room</mat-label>
            <mat-select *ngIf="bookingEvent.bookingEventForm.rooms" [(ngModel)]="bookingEvent.bookingEventForm.rooms.selectedRoom" (change)="filterBookingEventForm()">
              <mat-option *ngFor="let room of bookingEvent.bookingEventForm.rooms.items" [value]="room" [disabled]="room.disabled">{{room.title}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
        <div class="form-row">
          <mat-form-field class="form-group">
            <mat-label>Maximum Attendees:</mat-label>
            <input matInput autocomplete="off" [(ngModel)]="bookingEvent.bookingEventForm.maximumAttendees">
          </mat-form-field>
        </div>
        
        <div class="form-row">
          <mat-form-field class="form-group">
            <mat-label>Setup Time (minutes):</mat-label>
            <input matInput autocomplete="off" [(ngModel)]="bookingEvent.bookingEventForm.setupTimeMinutes">
          </mat-form-field>
        </div>
        
        <div class="form-row">
          <mat-form-field class="form-group">
            <mat-label>Packup Time (minutes):</mat-label>
            <input matInput autocomplete="off" [(ngModel)]="bookingEvent.bookingEventForm.packupTimeMinutes">
          </mat-form-field>
        </div>
        
        <!-- [(ngModel)]="room.roomForm.hasCatering" (change)="room.roomForm.cateringToggle()" -->
        <!-- Catering input -->
        <mat-slide-toggle style="margin-bottom: 15px;">Has Catering</mat-slide-toggle>
  
          <!-- -------------------------------------------------------------- SESSION - START --------------------------------------------------------------- -->
          <div *ngIf="bookingEvent.bookingEventForm.sessions">
            <div *ngIf="bookingEvent.bookingEventForm.sessions.items.length > 0">
              <div *ngFor="let session of bookingEvent.bookingEventForm.sessions.items">
  
                <!-- Duration -->
                <div>
                  <div class="d-flex time-dropdown">
                    <div class="hour-dropdown">
                      <mat-form-field>
                        <mat-label>Duration Hours:</mat-label>
                        <mat-select [(ngModel)]="session.durationHour" (change)="filterBookingEventForm()">
                          <mat-option *ngFor="let hour of session.durationHours" [value]="hour.value" [disabled]="hour.disabled">{{hour.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="minute-dropdown">
                      <mat-form-field>
                        <mat-label>Duration Minute:</mat-label>
                        <mat-select [(ngModel)]="session.durationMinute" (change)="filterBookingEventForm()">
                          <mat-option *ngFor="let minute of session.durationMinutes" [value]="minute.value" [disabled]="minute.disabled">{{minute.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <!-- Start Time -->
                <div>
                  <div class="d-flex time-dropdown">
                    <div class="hour-dropdown">
                      <mat-form-field>
                        <mat-label>Start Hours:</mat-label>
                        <mat-select [(ngModel)]="session.startHour" (change)="filterBookingEventForm()">
                          <mat-option *ngFor="let hour of session.startHours" [value]="hour.value" [disabled]="hour.disabled">{{hour.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="minute-dropdown">
                      <mat-form-field>
                        <mat-label>Start Minute:</mat-label>
                        <mat-select [(ngModel)]="session.startMinute" (change)="filterBookingEventForm()">
                          <mat-option *ngFor="let minute of session.startMinutes" [value]="minute.value" [disabled]="minute.disabled">{{minute.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>                  
                  </div>
                </div>
                
                <!-- EVENT DATE - START -->
                <mat-form-field>
                  <mat-label>Event Date</mat-label>
                  <input matInput [matDatepicker]="picker" [(ngModel)]="session.eventDate" [min]="session.minDate" shouldDisableDate="session.disabledDates">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <!-- EVENT DATE - END -->
  
                <!-- EQUIPMENT START -->
                <table mat-table [dataSource]="session.equipments.selectedEquipment.items" class="equipment-table">
                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef> Equipment </th>
                    <td mat-cell *matCellDef="let element"> {{element.title}} </td>
                  </ng-container>
                  <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef> Quantity </th>
                    <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
                  </ng-container>
                  <ng-container matColumnDef="equipmentId">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                      <button mat-icon-button (click)="session.equipments.removeSelectedEquipmentByEquipmentId(element.equipmentId)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="equipmentTableColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: equipmentTableColumns;"></tr>
                </table>
                
                <div class="d-flex add-equipment-outer">
                  <!-- Equipment To Add Input -->
                  <div class="d-flex add-equipment-inner">
                    <div class="hour-dropdown">
                      <mat-form-field>
                        <mat-label>Equipment:</mat-label>
                        <mat-select [(ngModel)]="session.equipments.equipmentToAdd">
                          <mat-option *ngFor="let equip of session.equipments.items" [value]="equip" [disabled]="equip.disabled">{{equip.title}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!-- Equipment To Add Input -->
                    <div class="minute-dropdown">
                      <mat-form-field>
                        <mat-label>Quantity:</mat-label>
                        <mat-select [(ngModel)]="session.equipments.equipmentToAdd.quantity">
                          <mat-option [value]="1">1</mat-option>
                          <mat-option [value]="2">2</mat-option>
                          <mat-option [value]="3">3</mat-option>
                          <mat-option [value]="4">4</mat-option>
                          <!-- <mat-option *ngFor="let quantity of session.equipments.equipmentToAdd.quantityDropdown" [value]="quantity">{{quantity.title}}</mat-option> -->
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                    <button mat-icon-button>
                      <mat-icon (click)="addEquipmentFormSubmit(session)">add</mat-icon>
                    </button>
                </div>
                <!-- ------------- EQUIPMENT - END ------------ -->
                
                <!-- ------------- BREAKS - START ------------ -->
                <!-- ------------- BREAKS - TABLE ------------ -->
                <table mat-table [dataSource]="session.breaks.items" class="breaks-table">
                  
                  <ng-container matColumnDef="start">
                    <th mat-header-cell *matHeaderCellDef> Break Start </th>
                    <td mat-cell *matCellDef="let element"> {{element.startDateTime | date:'h:mm a'}} </td>
                  </ng-container>
                  
                  <ng-container matColumnDef="end">
                    <th mat-header-cell *matHeaderCellDef> Break End </th>
                    <td mat-cell *matCellDef="let element"> {{element.endDateTime | date:'h:mm a'}} </td>
                  </ng-container>
                  
                  <ng-container matColumnDef="breakId">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                      <button mat-icon-button (click)="session.breaks.removeByIndex(i)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="breaksTableColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: breaksTableColumns;"></tr>
                </table>
                
                <!-- ------------- BREAKS - NEW BREAK FORM ------------ -->
                  <div class="d-flex add-equipment-outer">

                    <div class="d-flex add-equipment-inner">
                      <div class="hour-dropdown">
                        <mat-form-field>
                          <mat-label>Start Hour:</mat-label>
                          <mat-select [(ngModel)]="session.breaks.breakToAdd.startHour">
                            <mat-option *ngFor="let time of session.breaks.breakToAdd.startHours" [value]="time.value" [disabled]="time.disabled">{{time.value}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="minute-dropdown">
                        <mat-form-field>
                          <mat-label>Start Minute:</mat-label>
                          <mat-select [(ngModel)]="session.breaks.breakToAdd.startMinute">
                            <mat-option *ngFor="let time of session.breaks.breakToAdd.startMinutes" [value]="time.value">{{time.value}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="d-flex add-equipment-inner">
                      <!-- Equipment To Add Input -->
                      <!-- Equipment To Add Input -->
                      <div class="minute-dropdown">
                        <mat-form-field>
                          <mat-label>End Hour:</mat-label>
                          <mat-select [(ngModel)]="session.breaks.breakToAdd.endHour">
                            <mat-option *ngFor="let time of session.breaks.breakToAdd.endHours" [value]="time.value">{{time.value}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="minute-dropdown">
                        <mat-form-field>
                          <mat-label>End Minute:</mat-label>
                          <mat-select [(ngModel)]="session.breaks.breakToAdd.endMinute">
                            <mat-option *ngFor="let time of session.breaks.breakToAdd.endMinutes" [value]="time.value" [disabled]="time.disabled">{{time.value}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  
                  <div>
                    <button mat-icon-button>
                      <mat-icon (click)="session.breaks.addBreakFormSubmit()">add</mat-icon>
                    </button>
                  </div>
                </div>              
                <!-- ------------- BREAKS - END ------------ -->
                


                <!-- ------------- SEATING - START ------------ -->
                <div class="seating">
                  Seating input dropdown
                </div>
                <!-- ------------- SEATING - END ------------ -->
                

                <!-- ------------- CATERING - START ------------ -->
                <div class="catering">
                  catering table/ add items
                </div>
                <!-- ------------- CATERING - END ------------ -->
                
                

                <!-- ------------- CATERING - START ------------ -->
                <div class="catering">
                  catering
                </div>
                <!-- ------------- CATERING - END ------------ -->
                
                
                
              </div>
            </div>
          </div>
          <!-- -------------------------------------------------------------- SESSION - END --------------------------------------------------------------- -->
  
          <div style="text-align: end; padding-bottom: 10px;">
            <button mat-button matStepperNext>Next</button>
          </div>
        </div>
    </div>
    <!-- -------------------------------------------------------- EDIT EVENT FORM END --------------------------------------------------------- -->
    
    <!-- -------------------------------------------------------- DISPLAY EVENT START --------------------------------------------------------- -->
    <div class="bookingevent-card" *ngIf="bookingEvent && !uiOptions.editing">
      <div class="bookingevent-card-inner">
        
        <!-- CARD HEADER -->
        <div class="card-text-header">
          <i 
            *ngIf="bookingEvent.userRelations.created" 
            style="height: 20px; position: absolute; left: 10px;" 
            class="fa fa-star"
            placement="right"
            popover="You are the creator of this event"
            triggers="hover">
         </i>
          <i 
            *ngIf="bookingEvent.userRelations.attending" 
            style="height: 20px; position: absolute; left: 10px;" 
            class="fa fa-user"
            placement="right"
            popover="You are an attendee of this event"
            triggers="hover">
          </i>
          
          <h5>
            {{bookingEvent.title}}
          </h5>
          
          {{bookingEvent.startTime | date: 'h:mm:ss a'}} -
          {{bookingEvent.endTime | date: 'h:mm:ss a'}}
          {{" | "}}
          {{bookingEvent.startTime | date: 'MMM d, y'}}
          <hr>
        
        </div>
      
        <!-- CARD TEXT BODY -->
        <div class="card-text-body">
          
          <div class="sub-sub-sub-title">Location: </div>
          <div>{{bookingEvent.room.title}}</div>
          
          <div *ngIf="bookingEvent.breaks.items.length > 0">
            <div class="sub-sub-sub-title">Breaks: </div>
            <div *ngFor="let break of bookingEvent.breaks.items"> {{break.breakStart | date: 'h:mm:ss a'}}-{{break.breakEnd | date: 'h:mm:ss a'}}</div>
          </div>
          
          <div>
            <div class="sub-sub-sub-title">description: </div>
            <div>{{"agni dolores eos qui ratione v"}}</div>
          </div>
          
          <div> 
            <div class="sub-sub-sub-title">notes: </div>
            <div>{{"itationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in "}}</div>
          </div>
          
          <div>
            <div>what is the users relationship to this event?</div>
            <div>is the user attending this event?</div>
            <div>is the user the creator of this event?</div>
          </div>
          
          <!-- INVITATION FORM -->
          <div *ngIf="formStates.invitationForm">
            <div class="sub-title center">Invite Users</div>
            <table class="table">
              <tbody *ngIf="adUsers.items.length > 0">
                <tr *ngFor="let user of adUsers.items" id="user.userId" (click)="InvitationUserClick(user)">
                  <td>{{user.displayName}}</td>
                  <td><input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="user.sendInvite"></td>
                </tr>
                <tr>
                  <td><button class="btn btn-primary" (click)="sendInvitations()">Send Invitations</button></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            
          </div>
        </div>
      
        <!-- CARD BUTTON GROUP -->
        <div class="buttons-group">
          
          <div>
            <button class="btn btn-secondary" (click)="showInvitationForm()"><i class="fa fas fa-external-link"></i>Invite Users</button>
            <button class="btn btn-secondary" (click)="requestInvitation()"><i class="fa fas fa-external-link"></i>request Invitation</button>
            <!-- created -->
            <button class="btn btn-primary" (click)="editBookingEvent()"><i class="fa fa-edit"></i>Edit Event</button>
            <button class="btn btn-danger" (click)="deleteBookingEvent(bookingEvent.id)"><i class="fa fa-trash"></i>Delete Event</button>
            <!-- attending -->
            <button class="btn btn-primary" (click)="addAttendance()"><i class="fa fa-plus"></i>Add Attendance</button>
            <button class="btn btn-danger" (click)="deleteAttendance()"><i class="fa fa-trash"></i>remove Attendance</button>
            <button class="btn btn-success" (click)="logEvent()">log booking event</button>
            <button class="btn btn-success" (click)="logEventForm()">log booking event Form</button>
          </div>
          
          <div *ngIf="bookingEvent.userRelations.created == true" class="card-text">
            <!-- created -->
          </div>
          
          <div *ngIf="bookingEvent.userRelations.attending == true" class="card-text">
            <!-- attending -->
          </div>
          
        </div>
      
      </div>
    </div>
    <!-- -------------------------------------------------------- DISPLAY EVENT END --------------------------------------------------------- -->
  
  </div>
</div>