<div class="wrapper-outer" style="width: 100%">
  <div class="wrapper-inner" style="height: 100%;">

    
    <mat-vertical-stepper #stepper style="height: 100%;">
      <mat-step>
        <ng-template matStepLabel>Create Booking</ng-template>
        <div class="booking-form">

          <!-- Create booking form -->
          <!-- <mat-toolbar style="background: #0a94d6;color: white;">
            {{'Create Booking'}}
          </mat-toolbar> -->
        
          <div class="booking-form-body">
          
            <!-- Title input -->
            <div class="form-row">
              <mat-form-field class="example-full-width">
                <mat-label>Booking Title</mat-label>
                <input matInput autocomplete="off" [(ngModel)]="bookingForm.title">
              </mat-form-field>
            </div>
            
            <!-- Description input -->
            <div class="form-row">
              <mat-form-field class="example-full-width">
                <mat-label>Description</mat-label>
                <input matInput autocomplete="off" [(ngModel)]="bookingForm.description">
              </mat-form-field>
            </div>
            
            <div class="form-bottom" id="bottom-button">
              <button mat-button matStepperNext>Next</button>
            </div>
            

          </div>
        </div>

      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Create Event</ng-template>
        <div class="event">
          <app-event></app-event>
        </div>
      </mat-step>
      <mat-step>
        
        <ng-template matStepLabel>Review/ submit</ng-template>

        <div style="max-width: 500px; padding:10px;">

          <div class="booking-display" *ngIf="bookingForm" style="margin-bottom: 10px;">
            <div style="font-size: 20px">Booking</div>
            <mat-divider style="margin-top: 5px; margin-bottom: 15px;"></mat-divider>
            <div class="display-item">
              <span class="display-item-inner">Title:</span>
              <span class="display-item-inner">{{"Annual General Meeting"}}</span>
            </div>
            <div class="display-item">
              <span class="display-item-inner">Category:</span>
              <span class="display-item-inner">{{"-"}}</span>
            </div>
            <div class="display-item">
              <span class="display-item-inner">Description:</span>
              <span class="display-item-inner">{{'Opportunity to members to discuss the functioning of the company'}}</span>
            </div>
          </div>
          
          <div class="event-display">
            <div style="font-size: 20px">Event</div>
            <mat-divider style="margin-top: 5px; margin-bottom: 15px;"></mat-divider>
            <div class="display-item">
              <span class="display-item-inner">Event Title:</span>
              <span class="display-item-inner">{{"Annual General Meeting - IT"}}</span>
            </div>
            
            <div class="display-item">
              <span class="display-item-inner">Department:</span>
              <span class="display-item-inner">{{"General Management"}}</span>
            </div>
            
            <div class="display-item">
              <span class="display-item-inner">Room:</span>
              <span class="display-item-inner">{{"Meeting Room 3"}}</span>
            </div>
            
            <div class="display-item">
              <span class="display-item-inner">Maximum Attendees:</span>
              <span class="display-item-inner">{{"25"}}</span>
            </div>
            
            <div class="display-item">
              <span class="display-item-inner">Has Catering:</span>
              <span class="display-item-inner">{{"True"}}</span>
            </div>
            
            <div class="display-item">
              <span class="display-item-inner">Start Time:</span>
              <span class="display-item-inner">{{startDate | date: 'h:mm a'}}</span>
            </div>

            <div class="display-item">
              <span class="display-item-inner">End Time:</span>
              <span class="display-item-inner">{{endDate | date: 'h:mm a'}}</span>
            </div>
            
            <div class="display-item">
              <span class="display-item-inner">Event Date:</span>
              <span class="display-item-inner">{{startDate | date: 'M/d/yy'}}</span>
            </div>
            
            <div class="display-item">
              <span class="display-item-inner">Equipment:</span>
              <span class="display-item-inner">
                <div>{{"Projector - 1"}}</div>
                <div>{{"Whiteboard - 2"}}</div>
              </span>
            </div>
            
            <div class="display-item">
              <span class="display-item-inner">Breaks:</span>
              <span class="display-item-inner">{{"Breaks : " + "10:00AM - 10:15"}}</span>
            </div>

          </div>

        </div>


        <div class="form-bottom" id="bottom-button">
          <button mat-button>Submit</button>
        </div>

      </mat-step>
    </mat-vertical-stepper>

  </div>
</div>




<div class="wrapper" style="width: 0 !important;">

  <div class="booking-wrapper" id="booking-wrapper">

    <div *ngIf="booking" class="booking-display">
      <div class="d-flex header">
        <h3 style="margin-bottom: 0px;">{{booking.title}}</h3>
      </div>
    </div>

    <!-- Booking Display -->
    <div class="bottom-bar">
      <div>
        <i class="fas fa-plus"></i>
        <p>New Event</p>
      </div>
    </div>



  </div>
  
  <div class="event-wrapper">
  </div>

    <!-- Event Component -->
    <div>

      <div class="event-grid" Id="event-grid" *ngIf="events">
          <div class="event-item" *ngFor="let event of events.items; let i = index">
            <div class="event-item-inner">
              <div class="event-header" style="position: relative;">
                <img style="height: 20px; position: absolute; left: 10px;" src="assets/calendar.svg">
                <h5>
                  {{event.title}}
                </h5>

                <div>
                  
                  <span>
                    {{event.startTime | date: 'h:mm:ss a'}} -
                    {{event.endTime | date: 'h:mm:ss a'}}
                  </span>
                  
                  <span>
                    {{" | "}}
                    {{event.startTime | date: 'MMM d, y'}}
                  </span>
                </div>
              </div>
              
                <div class="grid-expand" id="grid-expand-{{i}}">
                  <div>
                    <hr>
                  </div>

                <div>{{"Department: " +event.department.title}}</div>
                <div>{{"Room: " +event.room.title}}</div>
                <div>
                  <span *ngFor="let b of event.breaks.items">
                    {{"Breaks: "}}
                    {{b.breakStart | date: 'h:mm:ss a'}} - 
                    {{b.breakEnd | date: 'h:mm:ss a'}}
                  </span>
                </div>
                <div>
                  {{"Equipment: "}}
                  <div *ngFor="let equip of event.equipment.items">
                    <span *ngIf="equip.quantity >0">
                      {{equip.title}}
                      {{"x " + equip.quantity}}
                    </span>
                  </div>
                </div>
                <div class="grid-buttons">
                  <button class="btn btn-danger mr-2" (click)="deleteEvent(event)">Delete</button>
                  <button class="btn btn-primary" (click)="editEvent(event.id)">Edit</button>
                </div>
              </div>
    
             
            </div>
          </div>

          <!-- Add Event Button -->
          <div>
            <button class="btn btn-primary" (click)="editEvent(-1)">Add Event</button>
          </div>
      </div>



      <!-- <app-event 
        (eventSubmitted)="addEvent($event)" 
        (eventCanceled)="eventFormCancel()">
      </app-event> -->

    </div>

  

</div>
