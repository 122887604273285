import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Equipment } from '../shared/models/Equipment';
import { Equipments } from '../shared/models/Equipments';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  constructor(
    private dataService: DataService,
  ) { }

  public getAllEquipment(): Observable<Equipments>{
    return this.dataService.getItems('equipment', 'getAllEquipment').pipe(map((equipmentData: any) => {
      let equipment = new Equipments();
      equipment.loadFromJson(Equipment, equipmentData.items);
      return equipment;
    }));
  }

  public createEquipment(equipment: Equipment): Observable<Equipment>{
    return this.dataService.createNewItem('equipment', equipment).pipe(map((equipmentData) => {
      let equipment = new Equipment();
      equipment.loadFromJson(equipmentData);

      return equipment;
    }))
  }
  
  public updateEquipment(equipment: Equipment): Observable<Equipment> {
    return this.dataService.updateItem('equipment', equipment).pipe(map((equipmentData) => {
      let equipment = new Equipment();
      equipment.loadFromJson(equipmentData);
    
      return equipment;
    }))
  }

  public deleteEquipment(equipmentId: number){
    return this.dataService.deleteItem('equipment', equipmentId);
  }

}
