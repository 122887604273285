import { AuthenticationResult } from "@azure/msal-common";
import { BookingEventsService } from "src/app/services/BookingEvents.service";
import { EmailService } from "src/app/services/Email.service";
import { BookingEvent } from "./BookingEvent";
import { User } from "./User/User";
import { Users } from "./User/Users";

export class Email {
    
    sender:  User;
    receivers: Array<User>;
    subject: string;
    message: string;
    accessToken: string;

    setProperties(user: User, users: Users, subject: string, message: string, auth: AuthenticationResult){
        this.sender = user;
        this.receivers = users.filteredUsers;
        this.accessToken = auth.accessToken;
        this.subject = subject;
        this.message = message;
    }


}
