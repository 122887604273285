import { BaseMasterItem } from './BaseMasterItem';
import { Department } from './department';
import { BookingEvents } from './BookingEvents';
import { Equipments } from './Equipments';
import { Equipment } from './Equipment';
import { SubRooms } from './SubRooms';
import { RoomForm } from './roomForm';
import { RoomBasic } from './roomBasic';
import { BookingEventBasic } from './BookingEventBasic';

export class Room extends BaseMasterItem {

    size: number;
    dep: string;
    hasCatering: boolean;
    hasVideoConferencing: boolean;
    timeAvailable: number;
    timeUtilized: number;

    departmentId: number;
    department: Department;
    eventTypesIds: Array<number>;
    eventTypes: Array<BaseMasterItem>;
    equipments: Equipments;
    subRooms: Array<RoomBasic>;
    masterRoom: RoomBasic;

    bookingEvents: Array<BookingEventBasic>;
    blockingEvents:BookingEvents;

    selectable: Boolean;

    companyPricePerMonth: number;
    departmentPricePerHour: number;
    externalPricePerHour: number;

    // form input
    // roomForm: RoomForm;
    formIsPosting: boolean = false;
    disabled: boolean;
    
    sendingRequest:boolean = false;

    loadFromJson(jsonItem){
        // if(jsonItem.id == 1){
        //     console.log("-------------------- room - load from json - json item --------------------");
        //     console.log(jsonItem);
        // }
        
        super.loadFromJson(jsonItem);
        this.size = jsonItem.size;
        this.dep = jsonItem.dep;

        jsonItem.hasCatering == 1 ? this.hasCatering = true : this.hasCatering = false;
        jsonItem.hasVideoConferencing == 1 ? this.hasVideoConferencing = true : this.hasVideoConferencing = false;

        this.departmentId = jsonItem.departmentId;
        this.eventTypesIds = jsonItem.eventTypesId;

        this.companyPricePerMonth = jsonItem.companyPricePerMonth;
        this.departmentPricePerHour = jsonItem.departmentPricePerHour;
        this.externalPricePerHour = jsonItem.externalPricePerHour;
        
        if(jsonItem.timeAvailable){
            this.timeAvailable = jsonItem.timeAvailable;
        }

        if(jsonItem.timeUtilized){
            this.timeUtilized = jsonItem.timeUtilized;
        }

        if(jsonItem.equipments){
            if(jsonItem.equipments.items){
                this.equipments = new Equipments();
                this.equipments.loadFromJson(Equipment, jsonItem.equipments.items);
            }
        }

        // set sub rooms
        if(jsonItem.subRooms){
            this.subRooms = new Array<RoomBasic>();
            jsonItem.subRooms.forEach(jsonRoom => {
                let newSubRoom = new RoomBasic();
                newSubRoom.loadFromJson(jsonRoom);
                this.subRooms.push(newSubRoom);
            });          
        }

        // set master room
        if(jsonItem.masterRoom){
            this.masterRoom = new RoomBasic();
            this.masterRoom.loadFromJson(jsonItem.masterRoom);
        }

        this.setBookingEvents(jsonItem);

    }

    private setBookingEvents(jsonItem){
        if(jsonItem.bookingEvents){
            if(jsonItem.bookingEvents.length > 0){
                this.bookingEvents = new Array<BookingEventBasic>();

                jsonItem.bookingEvent.forEach(bookingEventdata => {
                    let bookingEventToAdd = new BookingEventBasic();
                    bookingEventToAdd.loadFromJson(bookingEventdata);
                    this.bookingEvents.push(bookingEventToAdd);
                });

            }
        }
    }

    loadFromRoomForm(roomForm: RoomForm){
        console.log("-------------- LOAD FROM ROOM FORM ----------------------");
        console.log('roomForm: ')
        console.log(roomForm);

        // set id, title, catering, video conferencing, departmentId
        this.id = roomForm.id;
        this.title = roomForm.title;
        this.hasCatering = roomForm.hasCatering;
        this.hasVideoConferencing = roomForm.hasVideoConferencing;
        this.departmentId = roomForm.department.id;
        this.departmentPricePerHour = roomForm.departmentPricePerHour;
        this.externalPricePerHour = roomForm.externalPricePerHour;
        this.companyPricePerMonth = roomForm.companyPricePerMonth;

        // equipment
        this.equipments = roomForm.equipment.selectedEquipment;
        
        this.masterRoom = roomForm.masterRoom.room;

        // sub rooms
        this.subRooms = roomForm.subRooms.items;
        console.log('room');
        console.log(this);
        console.log('-')
    }

    loadExtraData(departments, articles, Monitors, eventTypes){
        this.department = departments.getById(this.departmentId);
        //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ DELETE THIS?????????????? @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
        //this.articles = articles.getByIds([this.articlesIds]);
        //this.monitors = Monitors.getByIds([this.monitorsIds]);
        //this.eventTypes = eventTypes.getByIds([this.eventTypesIds]);
    }

    loadFromRoom(room: Room){
        this.companyPricePerMonth = room.companyPricePerMonth;
        this.departmentId = room.departmentId;
        this.departmentPricePerHour = room.departmentPricePerHour;
        this.equipments = room.equipments;
        this.externalPricePerHour = room.externalPricePerHour;
        this.hasCatering = room.hasCatering;
        this.hasVideoConferencing = room.hasVideoConferencing;
        this.id = room.id;
        this.masterRoom = room.masterRoom;
        this.subRooms = room.subRooms;
        this.title = room.title;
    }


    // ----------------------------------------------- TOGGLES START -----------------------------------------
    toggleSendingRequest(){
        this.sendingRequest == true ? this.sendingRequest = false : this.sendingRequest = true;
    }
    
    public endFormPosting(){
        this.formIsPosting = false;
    }
    // ----------------------------------------------- TOGGLES END -----------------------------------------

    public getTimeUtilized(){
        if(this.timeUtilized){
            return this.timeUtilized;
        } else {
            return 0;
        }
    }

    public getTimeNotUtilized(){
        let timeNotUtilized = this.timeAvailable - this.timeUtilized;

        if(isNaN(timeNotUtilized)){
            console.log("nan")
            return this.timeAvailable;
        } else {
            return timeNotUtilized;
        }
    }

}
