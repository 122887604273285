import { BaseMasterItems } from '../BaseMasterItems';
import { User } from './User';

export class Users extends BaseMasterItems {

    filteredUsers: Array<User>;

    public loadFromGraphAdAccounts(data){
        data.value.forEach(person => {
          if(this.isAustralianPackOf7Account(person)){
            if(person.mail.includes("@packof7.com")){
              let user = new User();
              user.loadFromGraphAdAccount(person);

              this.items.push(user);
            }
          }
        });

    }

    private isAustralianPackOf7Account(person){
      if(
        person.displayName.includes("Zachary Marley") ||
        person.displayName.includes("Ryan Wilson") ||
        person.displayName.includes("Peter Potrafky") ||
        person.displayName.includes("James Garrett") ||
        person.displayName.includes("Australia Admin")
      ){
        return true;
      }
    }

    public filterAusPackOf7Users(){
        
    }

    public removeUsersWhereSendInviteIsFalse(){
        this.filteredUsers = new Array<User>();

        this.items.forEach((user: User) => {
            if(user.sendInvite == true){
                this.filteredUsers.push(user);
            }
        })
    }

    // sendInvitations(){
    //     console.log("send invitations");
    //     console.log(this.items);

    //     // Authenticate that user  has permission to send invites?
    //     // actually probably do this in the back end

    //     // make a new list of people who the invites are go9ing to be sent to
    //     this.items.forEach((user: User) => {
    //         if(user.sendInvite == true){
    //             console.log(user.displayName);
    //         }
    //     })
    // }

    setFilteredUsers(){
      this.filteredUsers = this.items;
    }


}
