import { Component, OnInit } from '@angular/core';
import { WaitingScreenService } from 'src/app/services/waitingScreen.service';

@Component({
  selector: 'app-failed',
  templateUrl: './failed.component.html',
  styleUrls: ['./failed.component.scss']
})
export class FailedComponent implements OnInit {

  constructor(private waitingScreenService: WaitingScreenService,) { }

  ngOnInit() {
    this.waitingScreenService.deactivate
  }

}
