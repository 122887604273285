import * as internal from "node:stream";
import { UserService } from "src/app/services/User.service";


export class AdUserGroupUserRoleForm {

    id: number;
    adUserGroup: string;
    userRole: string;


}
