<div class="series-wrapper">
  <div class="series-inner" *ngIf="bookings && bookings.items">

    <!-- BOOKING/ BOOKING EVENTS TABLE -->
    <div class="booking-head">
      <h3>{{"Series ID: "}} {{bookings.items[0].seriesId}}</h3>
    </div>

    <div class="row mb-3">
      <div class="col-md-2">
        Repeat Type:
      </div>
      <div class="col-md-3 d-flex">
        <div class="input-group">
          <select class="custom-select" [(ngModel)]="seriesForm.repeatType" (ngModelChange)="formChanged()">
            <option value="1">Daily</option>
            <option value="2">Weekly</option>
            <option value="3">Monthly</option>
            <option value="3">Yearly</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-2">
        Repeat Interval:
      </div>
      <div class="col-md-3 d-flex">
        <input 
          type="number" 
          class="form-control" 
          [(ngModel)]="seriesForm.repeatInterval" 
          (ngModelChange)="formChanged()" min="1">
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-2">
        Repeat until:
      </div>
      <div class="col-md-3 d-flex">
        <input
          autocomplete="off"
          id="startDate-picker"
          placeholder="Datepicker"
          class="form-control"
          #dp="bsDatepicker"
          placement="bottom left"
          bsDatepicker
          [(ngModel)]="seriesForm.repeatUntil"
          (ngModelChange)="formChanged()"
        />
      </div>
    </div>
    
    
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Booking</th>
          <th>Event</th>
          <th>Make headers dynamic</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let b of bookings.items">
          <td scope="row">
            <div>{{b.title}}</div>
            <button class="btn btn-primary"  (click)="editBooking(b.id)">Edit</button>
            <button class="btn btn-danger" (click)="deleteBookingButtonPress(b)">
              <i class="fa fas fa-trash"></i>
            </button>
          </td>
          <td *ngFor="let e of b.bookingEvents.items">{{e.title}} - {{e.startTime | date: 'EEEE, MMMM d, y, h:mm:ss a'}}</td>
        </tr>
        <tr *ngFor="let b of protoBookings.items">
          <td scope="row">index here</td>
          <td *ngFor="let e of b.bookingEvents.items">
            <div (click)="eventClick(e)" class="valid-{{e.formData.valid.isValid}}">
              {{e.title}} - {{e.startTime | date: 'EEEE, MMMM d, y, h:mm:ss a'}}
            </div>

            <!-- ----------------------------------------------------------------------- -->
            <div *ngIf='e.editEvent'>
              <div class="form-group mt-3">
                <label  for="title" class="control-label required">Event Title</label>
                <input [(ngModel)]="e.title" id="title" class="form-control" type="text">
              </div>
  
              <div class="form-group">
                <label for="room" class="control-label required" class="valid-{{e.formData.valid.roomId}}">Room: </label>
                <select [(ngModel)]="e.room.id" (ngModelChange)='eventChanged(e)' class="custom-select">
                  <option [ngValue]="0">Undefined</option>
                  <option *ngFor="let room of e.formData.rooms" [ngValue]="room.id" [disabled]="room.disabled">{{room.title}}</option>
                </select> 
              </div>
  
              <div id="start-box">
              
                <!-- Start Time -->
                <div>
                  <label class="required">Start Time:</label>
                  <div class="d-flex mb-3">
                    <label class="mr-2 mt-auto"  class="valid-{{e.formData.valid.startHour}}">Hours:</label>
                    <select
                      class="custom-select time-input-dropdown"
                      type="number"
                      aria-labelledby="dropdownMenuButton"
                      [(ngModel)]="e.startHour"
                      (ngModelChange)="eventChanged(e)"
                    >
                      <option [ngValue]="undefined">Undefined</option>
                      <option
                        *ngFor="let hour of e.formData.startHours"
                        [disabled]="hour.disabled"
                        [ngValue]="hour.hour"
                        type="number"
                      >
                        {{ hour.hour }}
                      </option>
                    </select>
                    <label class="mr-2 ml-2 mt-auto"  class="valid-{{e.formData.valid.startMinute}}">Minutes:</label>
                    <select
                      class="custom-select time-input-dropdown"
                      type="number"
                      [(ngModel)]="e.startMinute"
                      (ngModelChange)="eventChanged(e)"
                    >
                      <option [ngValue]="undefined">Undefined</option>
                      <option *ngFor="let minute of e.formData.startMinutes" type="number" [disabled]="minute.disabled" [ngValue]="minute.minute">{{minute.minute}}</option>
                    </select>
                  
                  </div>
                </div>
              </div>

              <div id="end-box">
              
                <!-- End Time -->
                <div>
                  <label class="required">End Time:</label>
                  <div class="d-flex mb-3">
                    <label class="mr-2 mt-auto" class="valid-{{e.formData.valid.endHour}}">Hours:</label>
                    <select
                      class="custom-select time-input-dropdown"
                      type="number"
                      aria-labelledby="dropdownMenuButton"
                      [(ngModel)]="e.endHour"
                      (ngModelChange)="eventChanged(e)"
                    >
                      <option [ngValue]="undefined">Undefined</option>
                      <option
                        *ngFor="let hour of e.formData.endHours"
                        [disabled]="hour.disabled"
                        [ngValue]="hour.hour"
                        type="number"
                      >
                        {{ hour.hour }}
                      </option>
                    </select>
                    <label class="mr-2 ml-2 mt-auto" class="valid-{{e.formData.valid.endMinute}}">Minutes:</label>
                    <select
                      class="custom-select time-input-dropdown"
                      type="number"
                      [(ngModel)]="e.endMinute"
                      (ngModelChange)="eventChanged(e)"
                    >
                      <option [ngValue]="undefined">Undefined</option>
                      <option *ngFor="let minute of e.formData.endMinutes" type="number" [disabled]="minute.disabled" [ngValue]="minute.minute">{{minute.minute}}</option>
                    </select>
                  
                  </div>
                </div>
              </div>

              
              <!-- [datesDisabled]="session.disabledDates" -->
              <!-- [minDate]="minDate" -->
              <!-- Start Date -->
              <!-- <div *ngIf="showStartError">Start Date must be before end date</div> -->
              <div>
                <label class="control-label requried" class="valid-{{e.formData.valid.startDate}}">Event Date:</label>
                <input
                  autocomplete="off"
                  id="startDate-picker"
                  placeholder="Datepicker"
                  class="form-control mb-3"
                  #dp="bsDatepicker"
                  placement="bottom left"
                  bsDatepicker
                  [minDate]="minDate"
                  [datesDisabled]="e.formData.disabledDates"
                  [(ngModel)]="e.startDate"
                  (ngModelChange)="eventChanged(e)"
                />
              </div>

              <div>
                <label class="control-label requried" class="valid-{{e.formData.valid.equipmentInputs}}">Equipment:</label>
                <table class="table table-striped" style="margin-bottom: 0px;">
                  <thead>
                    <tr>
                      <th scope="col">Equipment</th>
                      <th scope="col">Quantity</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
              
                    <tr *ngFor="let equipment of e.equipment.items; let i = index">
                      
                        <td style="padding-right: 0px;">
                          <!-- Dropdown for the equipment -->
                          <select class="custom-select selector-valid-{{equipment.valid}}" [(ngModel)]="equipment.id" (ngModelChange)="equipmentChanged(e, equipment)" >
                            <option [ngValue]="undefined">Undefined</option>
                            <option *ngFor="let eq of equipment.dropdownOptions" [disabled]="eq.disabled" [ngValue]="eq.id">
                              {{eq.title}}
                            </option>
                          </select> 
              
                        </td>
              
                        <!-- Dropdown for the quantity -->
                        <td style="padding-right: 0px;">
                          <select class="custom-select" [(ngModel)]="equipment.quantity" (ngModelChange)="eventChanged(e)">
                            <option [ngValue]="0">0</option>
                            <option *ngFor="let quant of equipment.quantityDropdown" [ngValue]="quant">{{quant}}</option>
                          </select> 
                        </td>
                        <td style="width: 1%">
                          <button type="button" class="btn btn-danger" (click)="deleteEquipment(e, equipment)">
                              <i class="fa fas fa-trash"></i>
                          </button>
                        </td>
                        
                      </tr>
                      <button class="btn btn-primary mt-2" type="button" (click)="addEquipment(e)">{{"Add Equipment"}}</button>
              
                  </tbody>
                </table>
              </div>

              <!-- BREAKS -->
              

<div>
  <label class="control-label requried" class="valid-{{e.formData.valid.equipmentInputs}}">Breaks (ADD BREAKS CHECKING VALID):</label>
  
  <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Start</th>
          <th scope="col">End</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let b of e.breaks.items; let i = index;">
          <!-- Start -->
          <th scope="row" style="display: flex;  padding-right: 0px;">
              <select class="custom-select time-input-dropdown" type="number" aria-labelledby="dropdownMenuButton" [(ngModel)]="b.startHour" >
                  <option 
                      *ngFor="let h of [].constructor(18); let i = index;" 
                      (ngModelChange)="breakChanged(b)">
                      {{ i+6 }}
                  </option>
              </select>
              {{":"}}
              <select class="custom-select time-input-dropdown" type="number" aria-labelledby="dropdownMenuButton" [(ngModel)]="b.startMinute" >
                  <option 
                      *ngFor="let min of [].constructor(4); let i = index;" 
                      (ngModelChange)="breakChanged(b)">
                      {{ i*15 }}
                  </option>
              </select>
          </th>
          <!-- End -->
          <td style="padding-right: 0px;">
              <div style="display: flex;">
                  <select class="custom-select time-input-dropdown" type="number" aria-labelledby="dropdownMenuButton" [(ngModel)]="b.endHour" >
                      <option 
                          *ngFor="let hour of [].constructor(19); let i = index;" 
                          (ngModelChange)="breakChanged(b)">
                          {{ i+6 }}
                      </option>
                  </select>
                  {{":"}}
                  <select class="custom-select time-input-dropdown" type="number" aria-labelledby="dropdownMenuButton" [(ngModel)]="b.endMinute" >
                      <option 
                          *ngFor="let min of [].constructor(4); let i = index;" 
                          (ngModelChange)="breakChanged(b)">
                          {{ i*15 }}
                      </option>
                  </select>
              </div>
          </td>
          <td style="width: 1%">
              <button type="button" class="btn btn-danger" (click)="deleteBreak(e,b)">
                  <i class="fa fas fa-trash"></i>
              </button>
          </td>
        </tr>
        <button class="btn btn-primary mt-2" type="button" (click)="addBreak(e)" >{{"Add Break"}}</button>
      </tbody>
    </table>

</div>


            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="row mb-3">
      <div class="col-md-12 d-flex">
        <button class="btn btn-primary" (click)="addBookingsButtonPress()">Add Bookings</button>
      </div>
    </div>

  </div>

</div>