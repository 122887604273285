import { BaseMasterItem } from './BaseMasterItem';
import { MasterDataService } from 'src/app/services/MasterData.service';

export class BookingStatus extends BaseMasterItem {
  selfBookingPriority: number;

  loadFromJson(jsonItem){
    super.loadFromJson(jsonItem);
    this.selfBookingPriority = jsonItem.selfBookingPriority
  }
}
