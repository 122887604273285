import { BaseMasterItems } from './BaseMasterItems';

export class MaxPerses extends BaseMasterItems {

    loadExtraData(seatings, rooms, departments){
        this.items.forEach(maxPers => {
            maxPers.loadExtraData(seatings, rooms, departments)
        });
    }
}
