import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'longDateString'
})
export class LongDateStringPipe implements PipeTransform {

  transform(value: Date): string {
    const temp: moment.Moment = moment(value);
    return temp.format('ddd DD MMM YYYY');
  }

}
