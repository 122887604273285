import { MasterDataService } from 'src/app/services/MasterData.service';
import { Department } from './department';
import { Equipments } from './Equipments';
import { Room } from './room';
import { SubRooms } from './SubRooms';
import { MasterRoom } from './MasterRoom';
import { Departments } from './departments';
import { Equipment } from './Equipment';

export class RoomForm {

    id: number;
    title: string;
    department: Department;
    hasVideoConferencing: boolean;
    hasCatering: boolean;
    departmentPricePerHour: number;
    externalPricePerHour: number;
    companyPricePerMonth: number;

    departments: Departments;
    equipment: Equipments;
    masterRoom: MasterRoom;
    subRooms: SubRooms;

    formIsPosting: boolean;

    // populates the data for this form
    public initEdit(masterDataService: MasterDataService, room: Room,  selectedDepartment: Department){
        this.setBasicData(room);
        this.initDepartment(selectedDepartment, masterDataService);
        this.initEquipment(room, masterDataService);
        this.initMasterRoom(room, masterDataService);
        this.initSubRooms(room, masterDataService);
    }

    private initDepartment(selectedDepartment: Department, masterDataService: MasterDataService){
        this.departments = masterDataService.departments;
        this.department = this.departments.getById(selectedDepartment.id);
    }
    
    private setBasicData(room: Room){
        this.id = room.id;
        this.title = room.title;
        this.hasCatering = room.hasCatering;
        this.hasVideoConferencing = room.hasVideoConferencing;
        this.departmentPricePerHour = room.departmentPricePerHour;
        this.externalPricePerHour = room.externalPricePerHour;
        this.companyPricePerMonth = room.companyPricePerMonth;
    }
    
    private initEquipment(room: Room, masterDataService: MasterDataService){
        this.equipment = new Equipments();
        this.equipment.initForm(masterDataService, room.equipments);
    }
    
    private initMasterRoom(room: Room, masterDataService: MasterDataService){
        this.masterRoom = new MasterRoom();
        this.masterRoom.init();
        this.masterRoom.setRoom(room.masterRoom);
        this.masterRoom.setRoomsDropdown(masterDataService);

    }
    
    private initSubRooms(room: Room, masterDataService: MasterDataService){
        this.subRooms = new SubRooms;
        //this.subRooms.init(room.subRooms);
        this.subRooms.init();
        if(room.subRooms){
            this.subRooms.setRooms(room.subRooms);
        }
        this.subRooms.loadFromMasterDataService(masterDataService);
    }

    // ----------------------------- MASTER ROOM -----------------------------
    public addMasterRoom(){
        this.masterRoom.addRoom();
    }
    
    public deleteMasterRoom(){
        this.masterRoom.deleteRoom();
    }
    
    
    // ----------------------------- SUB ROOMS -----------------------------
    public addSubRoom(){
        if(this.subRooms.selected == undefined){
            return;
        }
        
        this.subRooms.items.push(this.subRooms.selected);
        this.subRooms.selected = undefined;
    }
    
    // ------------------------------ HELPER ---------------------------------
    public click_addEquipment(equipment: Equipment){
        console.log("room form - click add equipment");
        this.equipment.addEquipmentToSelectedEquipment(equipment);
        this.equipment.filterFormDropdown();
        this.equipment.resetEquipmentToAddForm();
    }

    public click_editEquipment(equipment: Equipment){
        console.log("room form - edit equipment - not implemented");
    }

    // ------------------------------ HELPER ---------------------------------
    private resetFormData(){
        // RESET MASTER ROOM
        if(this.masterRoom){
            this.masterRoom.reset();
        }

        // RESET SUB ROOMS
        if(this.subRooms){
            this.subRooms.reset();
        }
    }

    public videoConferencingToggle(){
        this.hasVideoConferencing == false ? this.hasVideoConferencing = false : this.hasVideoConferencing = true;
    }

    public cateringToggle(){
        this.hasCatering == false ? this.hasCatering = false : this.hasCatering = true;
    }

    public deleteSubRoom(id: number){
        console.log("delete sub-room - not implemented");
        console.log('room id: ' + id);
        this.subRooms.removeItemById(id);
    }

    public isNewRoom(){
        if(this.id == -1){
            return true;
        } else {
            return false;
        }
    }

}
