import { BaseMasterItem } from './BaseMasterItem';
import { BreakForm } from './BreakForm';

export class Break extends BaseMasterItem {

    // DB DATA
    bookingEventId: number;
    startDateTime: Date;
    endDateTime: Date;

    // FORM INPUTS
    startHour: number = null;
    startMinute: number = null;
    endHour: number = null;
    endMinute: number = null;

    // FORM DROPDOWNS
    startHours: Array<{value: number, disabled: boolean}>
    startMinutes: Array<{value: number, disabled: boolean}>
    endHours: Array<{value: number, disabled: boolean}>
    endMinutes: Array<{value: number, disabled: boolean}>

    // FORM UI
    editMode: boolean;


    formIndex: number;

    breakForm: BreakForm;

    loadFromJson(jsonItem){
        super.loadFromJson(jsonItem);
        this.bookingEventId = jsonItem.bookingEventId;
        this.startDateTime = new Date(jsonItem.start);
        this.endDateTime = new Date(jsonItem.end);
    }

    public loadFromInputs(){
        console.log("load from inputs - method not implemented");

        this.startDateTime = new Date();
        this.startDateTime.setHours(this.startHour);
        this.startDateTime.setMinutes(this.startMinute);
        this.endDateTime = new Date();
        this.endDateTime.setHours(this.endHour);
        this.endDateTime.setMinutes(this.endMinute);
    }

    public initForm(b?: Break){
        console.log("--------------------- INIT BREAK FORM ------------------------");
        // should recieve data to populate: 
            // the dropdown hours 
            // form inputs

        this.startHours = [];
        this.startMinutes = [];
        this.endHours = [];
        this.endMinutes = [];

        // start hours
        for (let i = 6; i < 24; i++) {
            this.startHours.push({value: i, disabled: false});
        }

        // end hours
        for (let i = 6; i < 25; i++) {
            this.endHours.push({value: i, disabled: false});
        }

        // start/ end minutes
        for (let i = 0; i < 4; i++) {
            this.startMinutes.push({value: (i *15), disabled: false});
            this.endMinutes.push({value: (i *15), disabled: false});
        }

        // SET THE FORM INPUTS FROM DB DATA
        if(b){
            this.startDateTime = b.startDateTime;
            this.endDateTime = b.endDateTime;
            
            this.editMode =  false;
        } else {
            this.editMode = true;
        }
    }

    public setDateTimes(){
        this.startDateTime = new Date();
        this.startDateTime.setHours(this.startHour);
        this.startDateTime.setMinutes(this.startMinute);

        this.endDateTime = new Date();
        this.endDateTime.setHours(this.endHour);
        this.endDateTime.setMinutes(this.endMinute);
    }

    public resetForm(){
        this.startHour = null;
        this.startMinute = null;
        this.endHour = null;
        this.endMinute = null;


    }
    
    // FORM CONTROLS
    public toggleEdit(){
        if(this.editMode){
            this.editMode = false;
            
        } else {
            this.editMode = true;
            
            if(this.startDateTime && this.endDateTime){
                console.log(this.startDateTime);
                this.startHour = this.startDateTime.getHours();
                this.startMinute = this.startDateTime.getMinutes();
                this.endHour = this.endDateTime.getHours();
                this.endMinute = this.endDateTime.getMinutes();
            }
        }
    }

}