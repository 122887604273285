import { MasterDataService } from 'src/app/services/MasterData.service';
import { BaseMasterItems } from './BaseMasterItems';
import { Equipment } from './Equipment';

export class Equipments extends BaseMasterItems {
    equipmentToAdd: Equipment;
    selectedEquipment: Equipments;

    public initForm(masterDataService: MasterDataService, bookingEventEquipments?: Equipments){
        this.equipmentToAdd = new Equipment();
        this.selectedEquipment = new Equipments();

        this.initItemsList(masterDataService);
        this.initSelectedEquipment(bookingEventEquipments);
        this.filterFormDropdown();
    }

    private initItemsList(masterDataService){
        masterDataService.equipments.items.forEach((equipment: Equipment) => {
            this.items.push(equipment);
        });
    }

    private initSelectedEquipment(bookingEventEquipments?: Equipments){
        this.items.forEach((equipmentItem: Equipment) => {
            equipmentItem.hideInDropdown = false;
            
            // IF BOOKING EVENT EQUIPMENT CONTAINS THE EQUIPMENT ITEM
            // ADD THE ITEM TO THE SELECTED EQUIPMENT & SET ITEM DROPDOWN TO DISSABLED
            if(bookingEventEquipments){
                bookingEventEquipments.items.forEach((bookingEventEquipment: Equipment) => {
                    if(equipmentItem.equipmentId == bookingEventEquipment.equipmentId){
                        equipmentItem.quantity = bookingEventEquipment.quantity;
                        equipmentItem.quantityDropdown = bookingEventEquipment.quantityDropdown;
                        this.addEquipmentToSelectedEquipment(equipmentItem);
                        equipmentItem.hideInDropdown = true;
                    }
                });
            }
        });
    }
    
    public addEquipmentToSelectedEquipment(equipment: Equipment){
        this.selectedEquipment.items.push(equipment);
        this.triggerMaterialTableChangeDetection();
    }

    public resetEquipmentToAddForm(){
        this.equipmentToAdd = new Equipment();
        this.filterFormDropdown();
    }
    
    public removeEquipmentAtIndex(equipmentIndex: number){
        this.selectedEquipment.items[equipmentIndex].quantity = 0;
        this.selectedEquipment.items.splice(equipmentIndex, 1);
        this.filterFormDropdown();
    }

    public removeSelectedEquipmentByEquipmentId(equipmentId: number){ 
        console.log("remove seleceted equipment by equipment id");
        console.log(equipmentId);
        console.log("-")
        let index = 0;
        this.selectedEquipment.items.forEach((equipment: Equipment) => {
            console.log(equipment.equipmentId);
            if(equipment.equipmentId == equipmentId){
                this.selectedEquipment.items.splice(index, 1);
                return;
            }
            index++;
        })

        console.log(this.selectedEquipment);
    }
    
    public filterFormDropdown(){
        this.items.forEach((equipment: Equipment) => {
            let disableInDropdown = false;
            
            this.selectedEquipment.items.forEach((selectedEquipment: Equipment) => {
                if(equipment.equipmentId == selectedEquipment.equipmentId){
                    disableInDropdown = true;
                }
            });
            
            if(disableInDropdown){
                equipment.hideInDropdown = true;
            } else {
                equipment.hideInDropdown = false;
            }
        });
    }
    
    public setQuantityDropdowns(equipmentDisableData: Array<Equipment>){
        let equipmentItemCount: number = equipmentDisableData.length;
        for (let i = 0; i < equipmentItemCount; i++) {
            let equipmentToUpdate: Equipment = this.items[i];
            let newEquipmentData: Equipment = equipmentDisableData[i];
            equipmentToUpdate.quantityDropdown = newEquipmentData.quantityDropdown;
        }
    }

    public addItem(item: Equipment){
        this.items.push(item);
        this.triggerMaterialTableChangeDetection();
    }

    public updateItem(item: Equipment){
        console.log('update items');
        
        let itemToUpdate: Equipment = this.getById(item.id);
        console.log('item');
        console.log(item);
        console.log('item to update');
        console.log(itemToUpdate);

        itemToUpdate.loadFromEquipment(item);
        
        console.log(this.items);
    }

    public removeItemById(id: number){
        super.removeItemById(id);
        this.triggerMaterialTableChangeDetection();
    }

}