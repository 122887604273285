import { Component, OnInit } from '@angular/core';
import { BookingService } from 'src/app/services/booking.service';
import { InitializationService } from 'src/app/services/initialization.service';
import { SignalRService } from 'src/app/services/signalR.service';
import { Booking } from 'src/app/shared/models/booking';
import { BookingEvents } from 'src/app/shared/models/BookingEvents';
import { WaitingScreenService } from 'src/app/services/waitingScreen.service';
import { BookingForm } from '../../../shared/models/BookingForm';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {
  booking: Booking;
  bookingForm: BookingForm;
  
  //event: BookingEvent;
  events: BookingEvents;
  //bookingEventForm: BookingEventForm;

  startDate
  endDate;

  routeParam;

  constructor(
    public initializationService: InitializationService,
    private signalRService: SignalRService,
    private waitingScreenService: WaitingScreenService,
    private bookingService: BookingService,
  ) { }

  ngOnInit(): void {
    this.activateWaitingScreen();
    this.deactivateWaitingScreen();
    this.loadData();
    this.initBooking();

    this.setPlaceholderData();
    // this.initBookingEvent();
  }

  private activateWaitingScreen(){
    this.waitingScreenService.activate();  
  }
  
  private deactivateWaitingScreen(){
    let ws = this.waitingScreenService;
    setTimeout(function () {
      ws.deactivate();
    }, 500);
  }

  private initBooking(){
    this.bookingForm = new BookingForm();
    this.bookingForm.initForm();

  }

  private initBookingEvent(){

  }

  private setPlaceholderData(){
    this.startDate = new Date();
    this.startDate.setHours(9);
    this.startDate.setMinutes(15);

    this.endDate = new Date();
    this.endDate.setHours(10);
    this.endDate.setMinutes(30);
  }

  // private initBookingEvent(){

  // }

  // configureParam(){
  //   this.route.params.subscribe(params => {
  //     this.routeParam = params["id"];
  //     let param = params["id"];
  //     console.log(param);

  //     if(param == 'newEvent'){
  //       this.booking = null;
  //       // this.newEvent = null;
  //       this.stateItems.showBooking = true;
  //       this.stateItems.showEvent = true;
  //       this.stateItems.editBooking = false;
  //       this.stateItems.editEvent = true;
  //       this.stateItems.showAddEventButton = false;
  //     }
  //     else if(param == 'newBooking') {
  //       this.booking = null;
  //       // this.newEvent = null;
  //       this.stateItems.showBooking = true;
  //       this.stateItems.showEvent = false;
  //       this.stateItems.editBooking = true;
  //       this.stateItems.editEvent = false;
  //       this.stateItems.showAddEventButton = false;
  //     }
  //     else {
  //       this.stateItems.showBooking = true;
  //       this.stateItems.showEvent = true;
  //       this.stateItems.editBooking = false;
  //       this.stateItems.editEvent = false;
  //       this.stateItems.showAddEventButton = true;
  //     }
  //   });
  // }

  loadData(){
    // If the route param is a number get the booking that matched that bookingId
    if(!isNaN(this.routeParam)){
      this.getBooking(this.routeParam);
      this.getBookingEvents(this.routeParam);
    }
  }

  setupSignalR(){
    this.signalRService.startConnection();

    // Updates all clients when a new booking event is added to the database
    this.signalRService.hubConnection.on("broadcastBookingEventsData", (data) => {
      this.events.items.push(data);
    })

    // updates all clients when a booking event is deleted from the database
    this.signalRService.hubConnection.on("deleteBookingEvent", (bookingEvent) => {
      let index = this.events.items.map(function(el) {
        return el.id;
      }).indexOf(bookingEvent.id);

      this.events.items.splice(index, 1);
    })
  }

  private getBooking(bookingId: number){
    console.log("get booking - not implemented");
  }

  private getBookingEvents(bookingEventsId: number){
    console.log("get booking events - not implemented");
  }

  public deleteEvent(bookingEventId: number){
    console.log("delete event - not implemented")
  }
  
  public editEvent(bookingEventId: number){
    console.log("edit event - not implemented")
  }

}
