import { Injectable } from '@angular/core';
import { SqlService } from './Sql.service';
import { SharepointService } from './sharepoint.service';
import { Observable } from 'rxjs';
import { User } from '../shared/models/User/User';
import { AzureAdService } from './AzureAd.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private sqlService: SqlService,
    private spService: SharepointService,
    private azureAdService: AzureAdService
  ) { }

  sp: boolean = false;

  getItems(listName: string, functionName?: string, whereClause?: Array<string>, token?: string){
    return this.sqlService.getItems(listName, functionName, whereClause, token);
  }

  // getItems(
  //   listName: string,
  //   whereClause?: string[],
  //   columnsSelected?: string[],
  //   orderClause?: any,
  //   expandClause?: string[],
  //   restExtension?: string): Observable<any> {
  //   return (this.sp) ? this.spService.getItems(listName, whereClause, columnsSelected, orderClause, expandClause, restExtension) :
  //   this.sqlService.getItems(listName, whereClause, columnsSelected, orderClause, expandClause, restExtension);
  // }

  getItemById(listName: string, id: number, accessToken?: string): Observable<any> {
    console.log("no sp service");
    return this.sqlService.getItemById(listName, id);
  }
  
  getItemByIdString(controllerName: string, functionName:string, id: string, accessToken: string): Observable<any> {
    return this.sqlService.getItemByIdString(controllerName, functionName, id, accessToken);
  }

  getCurrentUsersFutureBookingEvents(userId: string): Observable<any>{
    console.log("no sp service");
    return (this.sp) ? this.sqlService.getCurrentUsersFutureBookingEvents(userId) : this.sqlService.getCurrentUsersFutureBookingEvents(userId);
  }

  getCurrentUsersFutureBookingEventsAttending(userId: string, todaysDate: Date): Observable<any>{
    console.log("no sp service");
    return (this.sp) ? 
    this.sqlService.getCurrentUsersFutureBookingEventsAttending(userId, todaysDate) : 
    this.sqlService.getCurrentUsersFutureBookingEventsAttending(userId, todaysDate);
  }

  public getItemsByDate(listName: string, date: Date): Observable<any> {
    return (this.sp) ? this.sqlService.getItemsByDate(listName, date) : this.sqlService.getItemsByDate(listName, date);
  }

  public getByDate(listName:string, methodName: string, date: Date): Observable<any> {
    return this.sqlService.getByDate(listName, methodName, date);
  }

  public getByDate_IdString(listName:string, methodName: string, date: Date, id: string): Observable<any> {
    return this.sqlService.getByDate_IdString(listName, methodName, date, id);
  }
  
  getBookingEventsByBookingId(bookingId: number): Observable<any> {
    return (this.sp) ? this.spService.getBookingEventsByBookingId(bookingId) : this.sqlService.getBookingEventsByBookingId(bookingId);
  }

  getBookingEventsByRoom(id: number): Observable<any>{
    return this.sqlService.getBookingEventsByRoom(id);
  }

  getAllBookingEvents(): Observable<any> {
    return (this.sp) ? this.spService.getFromCalendarData("BookingEvents") : this.sqlService.getItems("BookingEvents");
  }
  
  public getReportingMonth(date: Date): Observable<any> {
    return this.sqlService.getReportingMonth(date);
  }

  public getUserByAdAccountId(id: string): Observable<any> {
    return this.sqlService.getUserByAdAccountId(id);
  }

  // getCurrentUser(): Observable<any> {
  //   console.log("add sp service")
  //   return (this.sp) ? this.sqlService.getCurrentUser() : this.sqlService.getCurrentUser();
  // }

  // ------------------------------------- Bookings -----------------------------------

  getBookingsBySeriesId(id: number): Observable<any>{
    console.log("TODO: ADD SP SERVICE TO THIS CALL")
    return (this.sp) ? this.sqlService.getBookingsBySeriesId(id) : this.sqlService.getBookingsBySeriesId(id);
  }

  getBookingsByUserId(id: string): Observable<any>{
    console.log("TODO: ADD SP SERVICE TO THIS CALL")
    return (this.sp) ? this.sqlService.getBookingsByUserId(id) : this.sqlService.getBookingsByUserId(id);
  }

  getUserRole(user: User, userGroups: Array<string>){
    return this.azureAdService.getUserRole(user, userGroups);
  }








  createNewItem(listName: string, dataToPost: any): Observable<any> {
    return (this.sp) ? this.spService.createNewItem(listName, dataToPost) : this.sqlService.createNewItem(listName, dataToPost);
  }

  createNewItems(listName:string, dataToPost): Observable<any> {
    console.log("NO SHAREPOINT SERVICE");
    return (this.sp) ? this.sqlService.createNewItems(listName, dataToPost) : this.sqlService.createNewItems(listName, dataToPost);
  }
  
  
  
  
  updateItem(listName, item): Observable<any> {
    return (this.sp) ? this.spService.updateItem(listName, item) : this.sqlService.updateItem(listName, item);
  }
  
  public updateEventFormFilteredLists(start, end){
    return this.sqlService.updateEventFormFilteredLists(start, end);
  }
  
  
  
  
  
  deleteItem(listName, id, column?): Observable<any>{
    return (this.sp) ? this.spService.deleteItem(listName, id, column) : this.sqlService.deleteItem(listName, id,  column);
  }
  
  
  
  // ------------------------------------ BOOKING EVENT ATTENDANCE ------------------------------
  addAttendance(bookingEventId: number, userId: string): Observable<any> {
    console.log("NO SHAREPOINT SERVICE");
    return this.sqlService.addAttendance(bookingEventId, userId);
  }
  
  
  deleteAttendance(bookingEventId: number, userId: string): Observable<any> {
    console.log("no sp service");
    return this.sqlService.deleteAttendance(bookingEventId, userId);
  }


}
