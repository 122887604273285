<div *ngIf="waitingScreenService.showWaiting" style="position: absolute">
    <div class="po7_overlay">
    </div>
    <div class="po7_overoverlay d-flex">
        <div class="cssload-loader">

        </div>
    </div>
</div>

<app-navigation *ngIf="loginDisplay">
    <router-outlet class="expand-children"></router-outlet>
</app-navigation>

<div *ngIf="!loginDisplay">
    <div>
        <h3>you are not logged in.</h3>
    </div>
    <table>
        <tr>
            <td>
                <button mat-button (click)="login()">
                    SIGN IN
                  </button>
            </td>
        </tr>
        <!-- <tr>
            <td><button (click)="logMsal()">LOG MSAL</button></td>
        </tr> -->
    </table>
</div>