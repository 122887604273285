<div class="wrapper" *ngIf="catererJobs">
  <div style="padding-left: 10px; padding-top: 20px;">
    <mat-form-field appearance="fill">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" (ngModelChange)="change_selectedDate()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="grid-outer" *ngFor="let job of catererJobs.items">
    <mat-card class="grid-inner">
      <div class="grid-right">
        <div>
          {{'Start Time: ' + job.startTime | date:'h:mm a'}}
        </div>
        <div>
          {{job.startTime | date:'MMM d'}}
        </div>
      </div>
      <div class="grid-left">
        <div class="item">{{'Room:'}}</div>
        <div class="item">{{job.roomId}}</div>

        <div class="item">Catering:</div>
        <div class="item">
          <div *ngFor="let catering of job.caterings.items" style="padding-bottom: 5px">
            <div>{{catering.title + " x " + catering.quantity}}</div>
            <div>{{catering.notes}}</div>
          </div>
        </div>

        <!-- <div class="item" style="column-span: all;">
          <table style="width:100%">
            <tr>
              <th>Title</th>
              <th>Quantity</th>
              <th>notes</th>
            </tr>
            <tr *ngFor="let catering of job.caterings.items">
              <td>{{catering.title}}</td>
              <td>{{catering.quantity}}</td>
              <td>{{catering.notes}}</td>
            </tr>
          </table>
        </div> -->

      </div>
    </mat-card>
  </div>
</div>
