import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {  map } from 'rxjs/operators';
import { ReportingMonth } from '../shared/models/ReportingMonth';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(
    private dataService: DataService,

  ) { }

  // --------------------------------------- GET ------------------------------
  public getReportingMonth(date: Date): Observable<ReportingMonth>{
    return this.dataService.getReportingMonth(date).pipe(map(res => {
      console.log("reporting service - get reporting month - return res:");
      console.log(res);

      let reportingMonth = new ReportingMonth();
      reportingMonth.loadFromJson(res);
      
      return reportingMonth;
    }));
  }

}
