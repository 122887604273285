
export const environment = {
  production: true,
  sharepointurl: "http://sharepoint.packof7.com/sites/ems/",
  apiUrl: "https://api.roomable.cloud",
  redirectUrl: "https://roomable.cloud",
  clientId: "af5d2b81-1071-4186-b7f7-0e602f0c4e3b",
  tenantName: "0f5f6cc2-c1c2-4c75-afff-a0f27134ea18",
  hostUrl: "https://roomable.cloud",
  showDevTools: false
};