import { Room } from './room';
import { BookingStatus } from './bookingStatus';
import { EventType } from './eventType';
import { Seating } from './seating';
import { Person } from './person';
import { Break } from './break';
import { Equipment } from './Equipment';
import { Equipments } from './Equipments';
import { Department } from './department';
import { Breaks } from './breaks';
import { MasterDataService } from 'src/app/services/MasterData.service';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { BookingEventsService } from 'src/app/services/BookingEvents.service';
import { BookingEventForm } from './BookingEventForm';
import { Session } from './session';
import { BookingEventBasic } from './BookingEventBasic';
import { Catering } from './Catering/Catering';
import { Caterings } from './Catering/Caterings';

export class BookingEvent extends BookingEventBasic {
    
    startTime:Date;
    endTime:Date;
    maximumAttendees: number;
    hasCatering: boolean;

    editEvent: Boolean = false;
    bookingEventForm: BookingEventForm;
    numberOfAttendees: number;

    department: Department;
    room: Room;
    eventDate: Date;
    startHour: number = null;
    startMinute: number = null;
    endHour: number = null;
    endMinute: number = null;

    location:string;
    description:string;
    category:string;
    people:number;
    setupTimeMinutes:number;
    packupTimeMinutes:number
    seriesGUID:number;
    participantsPicker:Person;
    
    seating: Seating;
    equipment: Equipments;
    catering: Caterings;
    breaks: Breaks;


    bookingStatus:BookingStatus;
    eventType:EventType
    
    dayOfWeek: {dayIndex: number, day: string};
    creatorAdId: string;

    userRelations: {
        created: boolean,
        attending: boolean
    } = {
        created: false,
        attending: false
    }

    itemIds: {
        seatingId:number;
        roomId:number;
        bookingStatusId: number;
        eventTypeId:number;
        bookingId:number;
    } = {
        seatingId: null,
        roomId: null,
        bookingStatusId: null,
        eventTypeId: null,
        bookingId: null
    }

    width:number;
    left:number;
    displayColor:string;

    // uiOptions: {
    //     editing: boolean,
    // } = {
    //     editing: false
    // }
    
    loadFromJson(jsonItem){
        //console.log(jsonItem);

        super.loadFromJson(jsonItem);
        this.startTime = new Date(jsonItem.startTime);
        this.endTime = new Date(jsonItem.endTime);
        this.description = jsonItem.description;
        this.category = jsonItem.category;
        this.people = jsonItem.people;
        this.setupTimeMinutes = jsonItem.setupTimeMinutes;
        this.packupTimeMinutes = jsonItem.packupTimeMinutes;
        this.seriesGUID = jsonItem.seriesGuid;
        this.numberOfAttendees = jsonItem.numberOfAttendees;
        
        this.setBooking(jsonItem);
        this.setRoom(jsonItem);
        this.setDepartment(jsonItem);

        if(jsonItem.seatings){
            this.seating = new Seating();
            this.seating.loadFromJson(jsonItem.seatings);
        }

        if(jsonItem.catering){
            this.catering = new Caterings();
            this.catering.loadFromJson(Catering, jsonItem.catering.items);
        }

        if(jsonItem.equipment){
            if(jsonItem.equipment.items){
                if(jsonItem.equipment.items.Count != 0){
                    this.equipment = new Equipments();
                    this.equipment.loadFromJson(Equipment, jsonItem.equipment.items);
                } 
            }
        }

        if(jsonItem.breaks){
            this.breaks = new Breaks();
            this.breaks.loadFromJson(Break, jsonItem.breaks.items);
        }

        if(jsonItem.bookingId){
            this.itemIds.bookingId = jsonItem.bookingId;
        }

        if(jsonItem.seatingId){
            this.itemIds.seatingId = jsonItem.seatingId;
        }
        
        if(jsonItem.roomId){
            this.itemIds.roomId = jsonItem.roomId;
        };
        
        if(jsonItem.bookingStatusId){
            this.itemIds.bookingStatusId = jsonItem.bookingStatusId;
        };
        
        if(jsonItem.eventTypeId){
            this.itemIds.eventTypeId = jsonItem.eventTypeId;
        };

        if(jsonItem.itemIds){
            if(jsonItem.itemIds.bookingId){
                this.itemIds.bookingId = jsonItem.itemIds.bookingId;
            }
    
            if(jsonItem.itemIds.seatingId){
                this.itemIds.seatingId = jsonItem.itemIds.seatingId;
            }
            
            if(jsonItem.itemIds.roomId){
                this.itemIds.roomId = jsonItem.itemIds.roomId;
            };
            
            if(jsonItem.itemIds.bookingStatusId){
                this.itemIds.bookingStatusId = jsonItem.itemIds.bookingStatusId;
            };
            
            if(jsonItem.itemIds.eventTypeId){
                this.itemIds.eventTypeId = jsonItem.itemIds.eventTypeId;
            };
        }

    }

    public setRoom(jsonItem){
        if(jsonItem.room){
            this.itemIds.roomId = jsonItem.room.id;
            this.room = jsonItem.room;
        }
    }

    public setDepartment(jsonItem){
        if(jsonItem.department){
            this.department = new Department();
            this.department.loadFromJson(jsonItem.department);
        }
    }

    private setBooking(jsonItem){
        if(jsonItem.booking){
            this.itemIds.bookingId = jsonItem.booking.id;
            this.creatorAdId = jsonItem.booking.adAccountId;
        } else {
            //this.itemIds.bookingId = jsonItem.itemIds.bookingId;
        }
    }

    public setDayOfWeek(daysOfWeek: Array<string>){
      let bookingEventDayIndex = this.startTime.getDay();
      if(bookingEventDayIndex > 0){
          bookingEventDayIndex = bookingEventDayIndex -1;
      } else {
          bookingEventDayIndex = 6
      }

      this.dayOfWeek = {dayIndex: bookingEventDayIndex, day: daysOfWeek[bookingEventDayIndex]}
    }

    // ----------------------- BOOKING EVENT UI ACTIONS --------------------------------------
    public edit(masterDataService: MasterDataService){
        this.bookingEventForm = new BookingEventForm();
        
        if(this.id != -1){
            this.initBookingEvent(masterDataService);
        } else {
            this.initNewBookingEventForm(masterDataService);
        }
    }
    
    public initBookingEvent(masterDataService: MasterDataService){
        console.log('init booking event - edit existing event');
        this.bookingEventForm.init(masterDataService, this.room, this.department, this.equipment, this.breaks, this.startTime, this.endTime);
        // this.bookingEventForm.id = this.id;
        // this.bookingEventForm.title = this.title;
        // this.bookingEventForm.maximumAttendees = this.maximumAttendees;
        // this.bookingEventForm.hasCatering = this.hasCatering;
        // this.bookingEventForm.hasVideoConferencing = this.hasVideoConferencing;
        // this.bookingEventForm.setupTimeMinutes = this.setupTimeMinutes;
        // this.bookingEventForm.packupTimeMinutes = this.packupTimeMinutes;
    }
    
    public initNewBookingEventForm(masterDataService: MasterDataService){
        console.log('init booking event - create new event');
        this.bookingEventForm.initNew(masterDataService);
        // this.bookingEventForm.hasCatering = false;
        // this.bookingEventForm.hasVideoConferencing = false;
        // this.bookingEventForm.setupTimeMinutes = 0;
        // this.bookingEventForm.packupTimeMinutes = 0;
    }

    // SETS THE BOOKING EVENT START/ END DATE TIME FROM FORM INPUTS
    public setTimesFromBookingEventForm(session: Session){
      this.startTime = session.eventDate;
      this.startTime.setHours(session.startHour);
      this.startTime.setMinutes(session.startMinute);
      
      this.endTime = new Date(session.eventDate.getTime());
      this.endTime.setHours(session.startHour + session.durationHour);
      this.endTime.setMinutes(session.startMinute + session.durationMinute);
    }

    public delete(bookingEventsService: BookingEventsService){
        console.log("delete: " + this.title);
        bookingEventsService.deleteBookingEventById(this.id);
    }

    public addAttendance(bookingEventsService: BookingEventsService, msalService:MsalService){
        console.log("addAttendance: " + this.title); 
        let userId = msalService.instance.getActiveAccount().localAccountId;

        return bookingEventsService.addAttendance(this.id, userId);
    }

    public deleteAttendance(bookingEventsService: BookingEventsService, msalService: MsalService){
        console.log("Delete Attendance: " + this.title); 
        let userId = msalService.instance.getActiveAccount().localAccountId;

        return bookingEventsService.deleteAttendance(this.id, userId);
    }

    public sendInvitation(msalService: MsalService){
        console.log("sendInvitation" + this.title);
    }

    public navigateTo(router: Router){
        console.log(this.id);
        
        router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        router.navigate([ 'bookingEvent', {id: this.id, edit: false} ]));
    }


}
