import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BookingEvents } from '../shared/models/BookingEvents';
import { BookingEventsService } from './BookingEvents.service';
import { CatererJobs } from '../shared/models/Catering/CatererJobs';

@Injectable({
  providedIn: 'root'
})
export class CatererService {

constructor(
  private bookingEventsService: BookingEventsService,

) { }

  public getJobsByDate(date: Date){

  }

  public getCatererJobsByDate(date: Date, userId: string){
    return this.bookingEventsService.getCaterersBookingEventsByDate(userId, date).pipe(map((bookingEvents: BookingEvents) => {
      console.log("Get Caterer Jobs By Date");
      console.log(bookingEvents);

      let catererJobs = new CatererJobs();
      catererJobs.loadFromBookingEvents(bookingEvents);

      console.log(catererJobs);

      return catererJobs;
    }));
  }


}
