<div class="calendar-wrapper">
  <!-- LHS Labels -->
  <div class="clabels">

    <!-- EMPTY CELL -->
    <div class="cheaders"></div>

    <!-- Data Labels -->
    <div class="crows">
      <div class="crow" *ngFor="let row of data">{{ row.label }}</div>
    </div>
  </div>
  <div class="calendar">

    <!-- Headers -->
    <div class="cheaders" [ngStyle]="{ 'min-width.px': minWidth }">
      <div class="cblock" *ngFor="let block of blocks | ngRepeat">
        <span> {{ (start.hours + block - 1) | toHour }} </span>
      </div>
      <div class="cLastBlock" *ngIf="lastHeader">
        <span> {{ (start.hours + blocks) | toHour }} </span>
      </div>
    </div>

    <!-- Core -->
    <div class="core" [ngStyle]="{ 'min-width.px': minWidth }">
      <!-- Special Time Thingo -->
      <div class="ctime"></div>

      <!-- Data -->
      <div class="crows">
        <div class="crow" *ngFor="let row of data">
          <div class="crow-inner">
            <div class="centry" *ngFor="let entry of row.entries" [ngStyle]="{'left': getRelativePosition(entry) + '%', 'width': getRelativeWidth(entry) + '%'}">
              <div class="centry-inner"  
                    [ngStyle]="{'padding-left': 'calc(' + getRelativePreBlock(entry) + '% - 5px)', 'padding-right': 'calc(' + getRelativePostBlock(entry) + '% - 5px)', 'background-color': blockingColor}">
                <div class="centry-content" 
                        [ngClass]="{'width-block-nl': isPositionedStart(entry), 'width-block-nr': isPositionedEnd(entry), 'width-block-n': isPostionedAllDay(entry)}" 
                        [ngStyle]="{ 'background-color': entry.color.background, 'color': entry.color.foreground}">
                  <span matTooltip="{{ entry.title }}">{{ entry.title }}</span>
                </div>
              </div>
            </div>
            <div class="crow-sector-wrapper" *ngIf="changingTime">
                <div class="crow-sector" *ngFor="let sector of totalSectors | ngRepeat"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Main Grid (Looks pretty) -->
      <div class="cblock" *ngFor="let block of blocks | ngRepeat">
        <div class="csector" *ngFor="let sector of blockSectors | ngRepeat"></div>
      </div>
    </div>
  </div>
</div>
