import { Time } from '@angular/common';
import { AfterViewInit, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { LegendEntryComponent } from '@swimlane/ngx-charts';
import * as moment from 'moment';

@Component({
  selector: 'app-event-calendar-overview',
  templateUrl: './event-calendar-overview.component.html',
  styleUrls: ['./event-calendar-overview.component.scss']
})
export class EventCalendarOverviewComponent implements OnInit {
  @Input() minWidth: number = 1200;
  @Input() blockingPriorTime: number = 15;
  @Input() blockingPostTime: number = 15;
  @Input() blockingColor: string = 'rgba(0, 0, 0, 0.2)'
  @Input() start: Time = { hours: 8, minutes: 0 };
  @Input() end: Time = { hours: 18, minutes: 0 };
  @Input() minuteBlock: number = 15;
  @Input() lastHeader: boolean = true;
  @Input() colorScheme: Color[] = [
    { background: '#513f72', foreground: '#fff' },
    { background: '#2EE024', foreground: '#000' },
    { background: '#FCF628', foreground: '#000' },
    { background: '#A402AB', foreground: '#fff' },
    { background: '#AB131E', foreground: '#fff' },
    { background: '#6635AB', foreground: '#fff' },
    { background: '#4DBCF7', foreground: '#000' },
    { background: '#3F7819', foreground: '#fff' }
  ];
  @Input() items: CalendarRow[] = [];

  @Input() selected: CalendarRow = null;
  @Output() selectedChanged: EventEmitter<CalendarRow>;

  // Data about the time spans
  private isStartMidnight: boolean = true;
  private isEndMidnight: boolean = true;
  private isStartEndEqual: boolean = false;
  private isStartBeforeEnd: boolean = true;

  // Block / Sector Data
  public isBlockingEnabled: boolean = false;
  public blockingPriorSectors: number = 0;
  public blockingPostSectors: number = 0;
  public blocks: number = 9;
  public blockSectors: number = 4;
  public totalSectors: number = 0;
  public changingTime: boolean = true;

  // Render Data
  public data: InternalRow[];

  constructor() { }

  ngOnInit(): void {
    this.validateInputs();
    this.generateRequiredInformation();
    this.render();
    this.data = this.generateEntries(this.items);
  }

  public getRelativePreBlock(entity: InternalEntry): number {
    if (this.blockingPriorSectors <= 0) {
      return 0;
    }

    // Determine the starting point
    let start = entity.pos - this.blockingPriorSectors;
    const temp = start;
    if (start < 0) start = 0; 

    // Determine the end point
    let end = temp + entity.width + this.blockingPriorSectors + this.blockingPostSectors;
    if (end > this.totalSectors) end = this.totalSectors;

    if (temp < 0) {
      const smallBlock = temp + this.blockingPriorSectors;
      return (smallBlock <= 0) ? 0 : (smallBlock / (end - start)) * 100 
    }

    return (this.blockingPriorSectors / (end - start)) * 100;
  }

  public getRelativePostBlock(entity: InternalEntry): number {
    if (this.blockingPostSectors <= 0) {
      return 0;
    }

    // Determine the starting point
    let start = entity.pos - this.blockingPriorSectors;
    if (start < 0) start = 0;

    // Determine the end point
    let end = start + entity.width + this.blockingPriorSectors + this.blockingPostSectors;
    if (end > this.totalSectors) {
      const smallBlock = (this.totalSectors - end) + this.blockingPostSectors;
      return (smallBlock <= 0) ? 0 : (smallBlock / (this.totalSectors - start)) * 100 
    }

    return (this.blockingPostSectors / (end - start)) * 100;
  }

  public getRelativeWidth(entity: InternalEntry): number {
    // Determine the starting point
    let start = entity.pos - this.blockingPriorSectors;
    const actualStart = start;
    if (start < 0) start = 0;

    // Determine the end point
    let end = actualStart + entity.width + this.blockingPriorSectors + this.blockingPostSectors;
    const actualEnd = end;
    if (end > this.totalSectors) end = this.totalSectors;

    // Calculate width
    return ((end - start) / this.totalSectors) * 100
  }

  public getRelativePosition(entity: InternalEntry): number {
    const x = entity.pos - this.blockingPriorSectors;
    return (x <= 0) ? 0 : (x / this.totalSectors) * 100;
  }

  public isPositionedStart(entity: InternalEntry): boolean {
    return (this.blockingPriorSectors > 0) ? entity.pos <= 0 && !(entity.pos + entity.width >= this.totalSectors) : true;
  }

  public isPositionedEnd(entity: InternalEntry): boolean {
    return (this.blockingPostSectors > 0) ?  entity.pos + entity.width >= this.totalSectors && !(entity.pos <= 0) : true;
  }

  public isPostionedAllDay(entity: InternalEntry): boolean {
    if (this.blockingPriorSectors > 0 && this.blockingPostSectors > 0) {
      return entity.pos <= 0 && entity.pos + entity.width >= this.totalSectors;
    } else if (this.blockingPriorSectors > 0) {
      return entity.pos <= 0;
    } else if (this.blockingPostSectors > 0) {
      return entity.pos + entity.width >= this.totalSectors;
    } else {
      return true;
    }
  }

  /**
   *
   */
  private render(): void {
    if (this.isStartBeforeEnd) {
      this.blocks = this.end.hours - this.start.hours;
    } else {
      this.blocks = 24 + this.end.hours - this.start.hours;
    }

    const temp: number = this.minuteBlock % 60;
    this.blockSectors = (temp == 0) ? 1 : Math.floor(60 / temp);
    this.totalSectors = this.blocks * this.blockSectors;

    // Pre/Post Booking
    this.blockingPriorSectors = (this.blockingPriorTime > 0) ? Math.round(this.blockingPriorTime / this.minuteBlock) : 0;
    this.blockingPostSectors = (this.blockingPostTime > 0) ? Math.round(this.blockingPostTime / this.minuteBlock) : 0;
    this.blockingPostTime = this.blockingPostSectors * this.minuteBlock;
    this.blockingPriorTime = this.blockingPriorSectors * this.minuteBlock;

    if (this.blockingPostSectors > 0 || this.blockingPriorSectors > 0) {
      this.isBlockingEnabled = true;
    }

    console.log(this.blockingPriorSectors);
    console.log(this.blockingPostSectors);
  }

  private generateRandomColor(): Color {
    const n = this.generateNumber(0, this.colorScheme.length - 1);
    return this.colorScheme[n];
  }

  private generateNumber(min: number = 0, max: number): number {
    const ceiling: number = max + 1;
    const num: number = Math.floor(Math.random() * ( ceiling - min ) + min);
    return num % ceiling;
  }

  private generateRequiredInformation(): void {
    // Determine if the start is Midnight
    if (this.start.hours == 0 && this.start.minutes == 0) {
      this.isStartMidnight = true;
    } else this.isStartMidnight = false;

    // Determine if the end is midnight
    if (this.end.hours == 0 && this.end.minutes == 0) {
      this.isEndMidnight = true;
    } else this.isEndMidnight = false;

    // Determine if the start is before
    if (this.start.hours == this.end.hours && this.start.minutes == this.end.minutes) {
      this.isStartBeforeEnd = true;
      this.isStartEndEqual = true;
    } else if (this.start.hours < this.end.hours || (this.start.hours == this.end.hours && this.start.minutes < this.end.minutes)) {
      this.isStartBeforeEnd = true;
    } else this.isStartBeforeEnd = false;
  }

  private validateInputs(): void {
    if (this.minWidth < 0 ||
        this.minWidth == null) {
      throw new Error('minWidth must be 0 or greater');
    } else if (this.start == null ||
        this.start.hours > 23 || this.start.hours < 0 ||
        this.start.minutes > 59 || this.start.minutes < 0) {
      throw new Error('Start must be: 0 >= Hours <= 23 and 0 >= Minutes <= 59');
    } else if (this.end == null ||
        this.end.hours > 23 || this.end.hours < 0 ||
        this.end.minutes > 59 || this.end.minutes < 0) {
      throw new Error('End must be: 0 >= Hours <= 23 and 0 >= Minutes <= 59');
    } else if (this.minuteBlock <= 0 || this.minuteBlock > 60) {
      throw new Error('minuteBlock must be > 0 and <= 60')
    } else if (this.items == null) {
      throw new Error('Items can not be null.');
    }
  }

  private generateEntries(allRows: CalendarRow[]): InternalRow[] {
    const newData: InternalRow[] = []
    for (let index = 0; index < allRows.length; index++) {
      const current: CalendarRow = allRows[index];
      const internalEntries: InternalEntry[] = [];

      for (var entryIndex = 0; entryIndex < current.entries.length; entryIndex++) {
        const newEntry = this.convertCalendarEntryToInternal(current.entries[entryIndex]);
        if (newEntry != null) {
          internalEntries.push(newEntry);
        }
      }

      newData.push({
        label: current.label,
        entries: [...internalEntries]
      });
    }

    return newData;
  }

  private convertCalendarEntryToInternal(entry: CalendarEntry): InternalEntry {
    const start: Time = { hours: entry.start.getHours(), minutes: entry.start.getMinutes() }
    const end: Time = { hours: entry.end.getHours(), minutes: entry.end.getMinutes() }

    if (this.isStartEndPairAllowed(start, end)) {
      const pos: number = this.getSectorDifference(this.start, start); // Sector position
      const width: number = this.getSectorDifference(end, start); // Sector length

      if (pos < this.totalSectors) {
        return {
          title: entry.title,
          color: this.generateRandomColor(),
          pos,
          width
        };
      }
    }

    return null;
  }

  private getSectorDifference(t1: Time, t2: Time): number {
    let minutes: number = t1.minutes - t2.minutes;
    let hours: number = t1.hours - t2.hours;
    if (minutes < 0) {
      hours -= 1;
      minutes = 60 + minutes;
    }

    let result = hours * this.blockSectors + Math.round(minutes / this.minuteBlock);
    return (result >= 0) ? result : result * -1;
  }

  private isStartEndPairAllowed(start: Time, end: Time): boolean {
    if (this.timeSpanAllowed(start) && this.timeSpanAllowed(end)) {
      if (this.isStartEndEqual) {
        return true;
      } else if (this.isStartBeforeEnd) {
        return (start.hours < end.hours || (start.hours == end.hours && start.minutes <= end.minutes));
      } else {
        (end.hours < start.hours || (start.hours == end.hours && end.minutes <= start.minutes));
      }
    }

    return false;
  }

  /**
   * Check to ensure a given Time sits between the allowed time-frame
   * @param time Time to check
   * @returns If the Time sites within the allowed parameters
   */
  private timeSpanAllowed(time: Time): boolean {
    if (time.hours < 0 || time.hours > 23 || time.minutes < 0 || time.minutes > 59) {
      return false;
    } else if (this.isStartEndEqual) {
      return true;
    } else if (this.isStartBeforeEnd) {
      return (time.hours > this.start.hours || (time.hours == this.start.hours && time.minutes >= this.start.minutes)) &&
          (time.hours < this.end.hours || (time.hours == this.end.hours && time.minutes <= this.end.minutes));
    }

    const tempTime: Time = { hours: 24 + time.hours, minutes: time.minutes };
    const tempEnd: Time = { hours: 24 + this.end.hours, minutes: time.minutes };
    return (tempTime.hours > this.start.hours || (tempTime.hours == this.start.hours && tempTime.minutes >= this.start.minutes)) &&
        (tempTime.hours < tempEnd.hours || (tempTime.hours == tempEnd.hours && tempTime.minutes <= tempEnd.minutes));
  }
}

export class CalendarRow {
  label: string;
  entries: CalendarEntry[]

  constructor(label: string = '', entries: CalendarEntry[] = []) {
    this.label = '[No Label]',
    this.entries = []
  }
}

export class CalendarEntry {
  title: string
  start: Date;
  end: Date;

  constructor(title: string, start: Date, end: Date) {
    this.title = title;
    this.start = start;
    this.end = end;
  }
}

export interface Color {
  background: string;
  foreground: string;
}

export interface InternalRow {
  label: string;
  entries: InternalEntry[]
}

export interface InternalEntry {
  title: string;
  color: Color;
  pos: number;
  width: number;
}
