import { BaseMasterItem } from "../BaseMasterItem";

export class UserGroup extends BaseMasterItem {

    name: string;
    description: string;

    public loadFromJson(jsonData){
        super.loadFromJson(jsonData);
        this.name = jsonData.name;
        this.description = jsonData.description;
    }

    // Id
    // Name { get; set; }
    // Description { get; set; }
}
