import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { InitializationService } from './initialization.service';
import { MasterDataService } from './MasterData.service';
import { Session } from '../shared/models/session';
import { EventFormFilterReturnData } from '../shared/models/eventFormFilterReturnData';
import { environment } from 'src/environments/environment';
import { Breaks } from '../shared/models/breaks';
import { Equipment } from '../shared/models/Equipment';
import { Equipments } from '../shared/models/Equipments';

@Injectable({
  providedIn: 'root'
})
export class EventFormFilterService {

  url = environment.apiUrl;

  session: Session = new Session();

  // EventForm input field values
  eventFormInputs:
    {
      departmentId: number,
      roomId: number,
      sessions: {numberOfSessions: number, session: Array<Session>},

    } = {
      departmentId: 0,
      roomId: 0,
      sessions: {numberOfSessions: 1, session: [this.session]},
    }

  // Dropdown items
  rooms: Array<{title: string, id: number, disabled: boolean}> = [];
  departments: Array<{id:number, title: string, disabled: boolean}> = [];

  constructor(
    private initializationService: InitializationService,
    private masterDataService: MasterDataService,
    private httpClient: HttpClient,
  ) {
    this.initData();
  }

  departmentChanged(eventForm){
    this.eventFormInputs.departmentId = eventForm.departmentId;
    this.filterEventFormData();
  }

  public roomChanged(eventForm){
    // Update the form Input
    this.eventFormInputs.roomId = eventForm.roomId;

    // Get the new data from the server
    this.filterEventFormData();

    // return the new data to the component
  }

  public sessionChanged(){
    this.filterEventFormData();
  }

  public equipmentChanged(){
    this.filterEventFormData();
  }


  public filterEventFormData(){

    console.log(this.eventFormInputs);

    let filterData = {
      departmentId: this.eventFormInputs.departmentId,
      roomId: this.eventFormInputs.roomId,
      sessions: this.eventFormInputs.sessions.session
    }

    console.log(filterData);

    // Return the data to set disabled
    return this.httpClient.post(this.url+"/bookingEvents/updateEventFormFilteredLists", filterData)
      .pipe(map((data: EventFormFilterReturnData) => {

        console.log("------------------------ data returned from filter service ---------------------------");
        console.log(data);
        this.setDisabledRooms(data);
        this.setSessionsEquipment(data);
        this.setTimeDropdowns(data);
        this.setDisabledDates(data);

        console.log(this.eventFormInputs);

      })).subscribe();

  }

  private setDisabledRooms(data){
    this.rooms.forEach(r => {
      if(data.rooms.includes(r.id)){
        r.disabled = true;
      } else {
        r.disabled = false;
      }
    });
  }

  private setSessionsEquipment(data){
    let sessionCount = data.sessions.length;

    console.log(data);

    for (let i = 0; i < sessionCount; i++) {
      for (let e = 0; e <  data.sessions[i].equipment.length; e++){
        if(this.eventFormInputs.sessions.session[i]){
          this.eventFormInputs.sessions.session[i].equipments[e].quantityDropdown = data.sessions[i].equipment[e].quantityDropdown;
        }
      }
    }
  }

  // Sets the input dropdowns for each session
  private setTimeDropdowns(data){
    // console.log("----------------- SET TIME DROWDOWNS --------------------------------");

    for (let i = 0; i < data.sessions.length; i++) {
      // console.log(data.sessions[i]);
      // console.log(this.eventFormInputs);

      let session = this.eventFormInputs.sessions.session[i];
      // Duration dropdown hours
      session.durationHours.forEach(d => {
        if(data.sessions[i].disabledDurationHours.includes(d.value)){
          d.disabled = true;
        } else {
          d.disabled = false;
        }
      });
      // Duration dropdown minutes
      session.durationMinutes.forEach(d => {
        if(data.sessions[i].disabledDurationMinutes.includes(d.value)){
          d.disabled = true;
        } else {
          d.disabled = false;
        }
      });
      // Start Time dropdown hours
      session.startHours.forEach(d => {
        if(data.sessions[i].disabledStartHours.includes(d.value)){
          d.disabled = true;
        } else {
          d.disabled = false;
        }
      });
      // Start Time dropdown minutes
      session.startMinutes.forEach(d => {
        if(data.sessions[i].disabledStartMinutes.includes(d.value)){
          d.disabled = true;
        } else {
          d.disabled = false;
        }
      })
    }
  }

  setDisabledDates(d: EventFormFilterReturnData){
    for (let i = 0; i < d.sessions.length; i++) {
      let dates = []
      d.sessions[i].disabledDates.forEach(dd => {
        dates.push(new Date(dd));
      })
      this.eventFormInputs.sessions.session[i].disabledDates = dates;
    }
  }

  // --------------------------------- Helper functions -----------------------------------
  public getEquipment(){
    let equip = []
    
    console.log(this.masterDataService.equipments);
    this.masterDataService.equipments.items.forEach(e => {
      equip.push({ id: e.id, title: e.title, disabled: false});
    })

    return equip;
  }

  resetEventFormInputs(event?){

    let session: Session = new Session();
    session.id = 1;

    this.eventFormInputs = {
      departmentId: 0,
      roomId: 0,
      sessions: {numberOfSessions: 1, session: [session]},
    }

    this.addSessionDropdowns(0);

    if(event != 'newEvent'){
      this.newEvent(event);
      this.setFormDetails(event);
    }

    this.filterEventFormData();
  }

  addSessionDropdowns(n){
    for (let i = 0; i < 18; i++) {
      let h = {value: Number(i+6), disabled: false};
      this.eventFormInputs.sessions.session[n].startHours.push(h);
    }

    for(let i = 0; i < 4; i++){
      let m = {value: Number(i * 15), disabled: false};
      this.eventFormInputs.sessions.session[n].startMinutes.push(m);
    }

    for (let i = 0; i < 19; i++) {
      let hour = {value: Number(i), disabled: false};
      this.eventFormInputs.sessions.session[n].durationHours.push(hour);
    }

    for(let i = 0; i < 4; i++){
      let m = {value: Number(i * 15), disabled: false};
      this.eventFormInputs.sessions.session[n].durationMinutes.push(m);
    }
  }

  setFormDetails(e){
    console.log("--------------------------------- SET FORM DETAILS --------------------------------")
    console.log(e);
    console.log(this.eventFormInputs);

    this.eventFormInputs.roomId = e.room.id;
    this.eventFormInputs.departmentId = e.department.id;

    // this.eventFormInputs.endTime


    for (let i = 0; i < e.equipment.items.length; i++) {
      let equip = e.equipment.items[i];
      let equipmentItem = {
        id: 0,
        equipmentId: equip.id,
        title: equip.title,
        selectedQuantity: equip.quantity,
        quantityDropdown: [],
        optionsDropdown: this.getEquipment()
      }

      if(equip.quantity > 0){
        this.eventFormInputs.sessions.session[0].equipments.items.push(equipmentItem)
      }
    }
  }

  newEvent(e){
    if(e === undefined || e.id === null)
      return;

    let eventEndMinutes = e.endTime.getHours() * 60 + e.endTime.getMinutes();
    let eventStartMinutes = e.startTime.getHours() * 60 + e.startTime.getMinutes();
    let minutesDifference = eventEndMinutes - eventStartMinutes;
    let durationHour = 0;
    let durationMinute = minutesDifference;
    let session = this.eventFormInputs.sessions.session[0];

    for (let i = 60; i <= minutesDifference; i+= 60) {
      durationHour ++;
    }

    durationMinute = durationMinute - durationHour * 60;

    session.startTime = e.startTime;
    session.endTime = e.endTime;
    session.eventDate = e.startTime;
    session.startHours = e.startTime.getHours();
    session.startMinutes = e.startTime.getMinutes();
    session.durationHour = durationHour;
    session.durationMinute = durationMinute;
    session.bookingEventId = e.id;

    for (let i = 0; i < e.breaks.items.length; i++) {
      let b = e.breaks.items[i];
      let id = b.id;
      let startHour = b.breakStart.getHours();
      let startMinute = b.breakStart.getMinutes();
      let endHour = b.breakEnd.getHours();
      let endMinute = b.breakEnd.getMinutes();
      let start = b.breakStart;
      let end = b.breakEnd;

      let startDropdownHours = [];
      let startDropdownMinutes = [];
      let endDropdownHours = [];
      let endDropdownMinutes = [];

      for (let i = 0; i < 18; i++) {
        startDropdownHours.push({hour: 6+i, disabled: false});
      }
      for (let i = 0; i < 4; i++) {
        startDropdownMinutes.push({minute: 15*i, disabled: false});
      }

      for (let i = 0; i < 19; i++) {
        endDropdownHours.push({hour: 6+i, disabled: false});
      }
      for (let i = 0; i < 4; i++) {
        endDropdownMinutes.push({minute: 15*i, disabled: false});
      }

      session.breaks.items.push({
        id: id,
        startHour: startHour, 
        startMinute: startMinute, 
        endHour: endHour, 
        endMinute: endMinute, 
        start: start, 
        end: end,
        startDropdownHours: startDropdownHours,
        startDropdownMinutes: startDropdownMinutes,
        endDropdownHours: endDropdownHours,
        endDropdownMinutes: endDropdownMinutes
      });
    }

    // console.log("-------------------------------------------------------------------------------------------------")
    // console.log(this.eventFormInputs);
    this.filterBreakDropdowns(session);
  }

  public filterBreakDropdowns(session){

    let startMinutes = session.startTime.getMinutes() + session.startTime.getHours() * 60;
    let endMinutes = session.endTime.getMinutes() + session.endTime.getHours() * 60;
    let breakLength = session.breaks.length;

    for (let i = 0; i < breakLength; i++) {
      let b  = session.breaks[i];
      this.filterBreakDropdownHours(b, startMinutes, endMinutes);
      this.filterBreakDropdownMinutes(b, startMinutes, endMinutes);
      this.filterBreakEndDropdownHours(b, startMinutes, endMinutes);
      this.filterBreakEndDropdownMinutes(b, startMinutes, endMinutes);
    }
  }

  private filterBreakDropdownHours(b, startMinutes, endMinutes){
    let ddhl = b.startDropdownHours.length;

    for (let j = 0; j < ddhl; j++) {
      let ddh = b.startDropdownHours[j];
      let ddml = b.startDropdownMinutes.length;
      
      ddh.disabled = false;

      for (let k = 0; k < ddml; k++) {
        let ddm = b.startDropdownMinutes[k];
        let timeToCheck = ddh.hour * 60 + ddm.minute;

        if(!this.isBetween(timeToCheck, startMinutes, endMinutes)){
          ddh.disabled = true;
        } else {
          ddh.disabled = false;
          break;
        }
      }
    }
  }

  private filterBreakDropdownMinutes(b, startMinutes, endMinutes){
    let ddml = b.startDropdownMinutes.length;

    for (let j = 0; j < ddml; j++) {
      let ddm = b.startDropdownMinutes[j];
      let ddhl = b.startDropdownHours.length;

      ddm.disabled = false;

      for (let k = 0; k < ddhl; k++) {
        let ddh = b.startDropdownHours[k];
        let timeToCheck = ddh.hour * 60 + ddm.minute;
        
        if(!this.isBetween(timeToCheck, startMinutes, endMinutes)){
          ddm.disabled = true;
        } else {
          ddm.disabled = false;
          break;
        }
      }
    }
  }

  private filterBreakEndDropdownHours(b, startMinutes, endMinutes){
    let ddhl = b.endDropdownHours.length;

    for (let j = 0; j < ddhl; j++) {
      let ddh = b.endDropdownHours[j];
      let ddml = b.endDropdownMinutes.length;

      for (let k = 0; k < ddml; k++) {
        let ddm = b.endDropdownMinutes[k];
        let timeToCheck = ddh.hour * 60 + ddm.minute;

        if(!this.isBetweenOrEqLast(timeToCheck, startMinutes, endMinutes)){
          ddh.disabled = true;
        } else {
          ddh.disabled = false;
          break;
        }
      }
    }
  }

  private filterBreakEndDropdownMinutes(b, startMinutes, endMinutes){
    let ddml = b.endDropdownMinutes.length;

    for (let j = 0; j < ddml; j++) {
      let ddm = b.endDropdownMinutes[j];
      let ddhl = b.endDropdownHours.length;

      for (let k = 0; k < ddhl; k++) {
        let ddh = b.endDropdownHours[k];
        let timeToCheck = ddh.hour * 60 + ddm.minute;

        if(!this.isBetween(timeToCheck, startMinutes, endMinutes)){
          ddm.disabled = true;
        } else {
          ddm.disabled = false;
          break;
        }
      }
    }
  }

  private isBetween(n, s, e){
    if(n >= s && n < e){
      return true;
    } else {
      return false;
    }
  }

  private isBetweenOrEqLast(n, s, e){
    if(n >= s && n <= e){
      return true;
    } else {
      return false;
    }
  }
  
  // --------------------------------- SET INITIAL DATA ------------------------------------
  private initData(){
    this.initializationService.initializationComplete.subscribe((initComplete) => {
      if(initComplete == true) {
        this.initRooms();
        //this.initEquipment();
        this.initDepartments();
      }
    });
  }

  private initRooms(){
    this.masterDataService.rooms.items.forEach(r => {
      this.rooms.push({title: r.title, id: r.id, disabled: false})
    });
  }


  private initDepartments(){
    this.masterDataService.departments.items.forEach(e => {
      this.departments.push({id: e.id, title: e.title, disabled: false});
    })
  }
}
