import { BaseMasterItem } from './BaseMasterItem';
import { Rooms } from './rooms';
import { Room } from './room';
import { DepartmentForm } from './departmentForm';
import { DepartmentBasic } from './DepartmentBasic';

export class Department extends DepartmentBasic {

  rooms: Rooms;
  
  companyPricePerMonthOfAllRooms: number;
  minutesUsedLastMonth: number;
  minutesAvailableLastMonth: number;

  allRoomsTimeAvailable: number;
  allRoomsTimeUtilized: number;

  // UI
  ui: {
    displayEdit: boolean,
    displayRoom: boolean,
    displayDepartmentIntelligence: boolean
  } = {
    displayEdit: false,
    displayRoom: true,
    displayDepartmentIntelligence: false
  }

  // FORMS
  departmentForm: DepartmentForm;
  
  loadFromJson(jsonItem){
    super.loadFromJson(jsonItem);
    
    if(this.id == 1){
      //console.log(jsonItem);
    }

    if(jsonItem.roomsDto){
      this.rooms = new Rooms();
      this.rooms.loadFromJson(Room, jsonItem.roomsDto.items)
    }

    if(jsonItem.allRoomsTimeAvailable){
      this.allRoomsTimeAvailable = jsonItem.allRoomsTimeAvailable;
    }

    if(jsonItem.allRoomsTimeUtilized){
      this.allRoomsTimeUtilized = jsonItem.allRoomsTimeUtilized;
    }
  }

  // ---------------------------------------------------- LOADING DATA ----------------------------------------
  loadFromForm(departmentForm: DepartmentForm){
    if(departmentForm.id){
      this.id = departmentForm.id;
    } else {
      this.id = -1;
    }
    this.title = departmentForm.title;
  }

  update(departmentData){
    this.id = departmentData.id;
    this.title = departmentData.title;
  }

  // ----------------------------------------------------- UI START -----------------------------------------------------------
  public uiToggle(value: string){
    console.log(value);
    
    if(value == "displayEdit"){
      this.ui.displayEdit = true;
      this.ui.displayRoom = false;
      this.ui.displayDepartmentIntelligence = false;
    }
    
    if(value == "displayRooms"){
      this.ui.displayEdit = false;
      this.ui.displayRoom = true;
      this.ui.displayDepartmentIntelligence = false;
    }
    
    if(value == "displayDepartmentIntelligence"){
      this.ui.displayEdit = false;
      this.ui.displayRoom = false;
      this.ui.displayDepartmentIntelligence = true;
    }
    
    //console.log(this.ui);
    
  }

  public edit(){
    this.uiToggle('displayEdit');
    this.departmentForm = new DepartmentForm();
    this.departmentForm.init(this);
  }
  // ----------------------------------------------------- UI END -----------------------------------------------------------
  
  // ----------------------------------------------------- HELPER START ----------------------------------------------------
  public logThis(){
    console.log(this);
  }
  
}

