<div class="administrator-window">
  <div class="administrator-window-inner">

    <div style="font-size: 30px;" *ngIf="allDepartments">
      <div style="display: flex;">
        <mat-toolbar style="background: #0a94d6;color: white;">
          <span *ngIf="selectedDepartment">
            <span *ngIf="selectedDepartment.title && uiDisplay.departmentRooms">
              {{selectedDepartment.title}}
              <button mat-button [matMenuTriggerFor]="departmentsDropdownMenu">
                <mat-icon>
                  arrow_drop_down
                </mat-icon>
              </button>
              <mat-menu #departmentsDropdownMenu="matMenu">
                <button mat-menu-item *ngFor="let department of allDepartments.items"  (click)="click_toggleDepartment(department)">
                  {{department.title}}
                </button>
              </mat-menu>
            </span>

            <span *ngIf="!selectedDepartment.title">
              New Department
            </span>
          </span>
          <span *ngIf="uiDisplay.departments">
            Departments
          </span>
          <span *ngIf="uiDisplay.equipment">
            Equipment
          </span>
          <span *ngIf="uiDisplay.manageAdUserGroupUserRole">
            Manage User Groups
          </span>
          <span *ngIf="uiDisplay.catering">
            Catering
          </span>
          <span *ngIf="uiDisplay.roomForm">
            Edit/ Create Room
          </span>
          <span class="spacer"></span>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" style="align-self: center;">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">            
            <button mat-menu-item (click)="click_showDepartments()">
              <span>Departments</span>
            </button>
            <button mat-menu-item (click)="click_showEquipment()">
              <span>Equipment</span>
            </button>
            <button mat-menu-item (click)="click_manageAdUserGroupUserRole()">
              <span>Manage User Groups</span>
            </button>
            <button mat-menu-item (click)="click_showCatering()">
              <span>Catering</span>
            </button>
          </mat-menu>
        </mat-toolbar>
      </div>
    </div>

    <!-- -------------------------------------------------- MAIN CONTENT - START --------------------------------------------------- -->
    <div class="main-content">

      <div *ngIf="uiDisplay.departmentRooms">

        <div style="display: grid; grid-template-columns: 1fr 50% 1fr; padding-top: 20px;">    
          <mat-form-field appearance="fill" style="grid-column-start: 2;">
            <mat-label>Search Rooms</mat-label>
            <input matInput>
          </mat-form-field>
          <div style="margin-right: 5px; text-align-last: end; margin-top: 24px;">
            <!-- <button mat-stroked-button (click)="click_logRooms()">Log Rooms</button>
            <button mat-stroked-button (click)="click_logRoomForm()">Log Room Form</button> -->
            <button mat-stroked-button (click)="click_createNewRoom()">Create New Room</button>
          </div>
        </div>
        <!-- ------------------------------------------ Department rooms grid View ----------------------------------------------- -->
        <div class="cards-outer" *ngIf="selectedDepartment.rooms">
          <div class="cards-inner">
            <mat-card class="card-outer" *ngFor="let room of selectedDepartment.rooms.items; let i = index;">
              <div class="card-inner">
                <!-- ROOM CARD HEADER -->
                <div class="card-header">
                  <h3>{{room.title}}</h3>
                  <span class="spacer"></span>
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" style="align-self: center;">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="click_editRoom(room)" [disabled]="room.formIsPosting">
                      <span>Edit</span>
                    </button>
                    <button mat-menu-item (click)="click_deleteRoom(room)" [disabled]="room.formIsPosting">
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </div>
                <!-- DISPLAY CARD BODY -->
                <div class="card-body">
                  <div class="room-item">
                    <span style="width: 50%">Video Conferencing</span>
                    <span style="width: 50%">{{room.hasVideoConferencing}}</span>
                  </div>
                  <div class="room-item">
                    <span style="width: 50%">Catering</span>
                    <span style="width: 50%">{{room.hasCatering}}</span>
                  </div>
                  <div class="room-item">
                    <span style="width: 50%">Department Price/ hour</span>
                    <span style="width: 50%">{{room.departmentPricePerHour}}</span>
                  </div>
                  <div class="room-item">
                    <span style="width: 50%">External Price/ hour</span>
                    <span style="width: 50%">{{room.externalPricePerHour}}</span>
                  </div>
                  <div class="room-item">
                    <span style="width: 50%">Company Price/ month</span>
                    <span style="width: 50%">{{room.companyPricePerMonth}}</span>
                  </div>
                  <div class="room-item">
                    <span style="width: 50%">equipment</span>
                    <span style="width: 50%">
                      <div *ngFor="let equipment of room.equipments.items">
                        {{equipment.title}} - {{equipment.quantity}}
                      </div>
                    </span>
                  </div>
                  <div class="room-item" *ngIf="room.masterRoom">
                    <span style="width: 50%">Master Room</span>
                    <span style="width: 50%">{{room.masterRoom.title}}</span>
                  </div>
                  <div class="room-item" *ngIf="room.subRooms">
                    <span style="width: 50%">Sub Rooms</span>
                    <span style="width: 50%">
                      <div *ngFor="let subRoom of room.subRooms">{{subRoom.title}}</div>
                    </span>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>  
        </div> 
      </div>
      <!-- Room Form -->
      <div class="card-inner" *ngIf="uiDisplay.roomForm == true">
        <mat-form-field appearance="fill">
          <mat-label>Title</mat-label>
          <input matInput [(ngModel)]="roomForm.title">
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Department</mat-label>
          <mat-select [(ngModel)]="roomForm.department">
            <mat-option *ngFor="let department of roomForm.departments.items" [value]="department">
              {{department.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        
        <mat-slide-toggle [(ngModel)]="roomForm.hasVideoConferencing" (change)="roomForm.videoConferencingToggle()">Has Video Conferencing</mat-slide-toggle>
        
        <mat-slide-toggle [(ngModel)]="roomForm.hasCatering" (change)="roomForm.cateringToggle()">Has Catering</mat-slide-toggle>
        
        <mat-form-field appearance="fill">
          <mat-label>Department Price/ Hour</mat-label>
          <input matInput [(ngModel)]="roomForm.departmentPricePerHour">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>External Price/ hour</mat-label>
          <input matInput [(ngModel)]="roomForm.externalPricePerHour">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Company Price/ month</mat-label>
          <input matInput [(ngModel)]="roomForm.companyPricePerMonth">
        </mat-form-field>

        <!-- EQUIPMENT START -->
        <table class="table table-striped" style="margin-bottom: 0px;">
          <thead>
            <tr>
              <th scope="col" style="text-align: start;">Equipment</th>
              <th scope="col" style="text-align: start;">Quantity</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody *ngIf="roomForm.equipment.items.length > 0">
            <!-- LIST OF EQUIPMENT -->
            <tr *ngFor="let e of roomForm.equipment.selectedEquipment.items; let equipmentIndex = index">
              <td style="padding-right: 0px;">{{e.title}}</td>
              <!-- Dropdown for the quantity -->
              <td style="padding-right: 0px; width: 1%;">
                <div *ngIf="e.editQuantity == false;">
                  {{e.quantity}}
                </div>
              </td>
              <td style="width: 10%; text-align: end;">
                <div>
                  <button mat-button (click)="roomForm.click_editEquipment(e)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-button (click)="roomForm.equipment.removeEquipmentAtIndex(equipmentIndex)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- ADD EQUIPMENT FORM ROW -->
        <div>
          <div style="margin-top: 15px; margin-bottom: 10px;">
            Add Equipent
          </div>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Select</mat-label>
            <mat-select [(ngModel)]="roomForm.equipment.equipmentToAdd">
              <mat-option *ngFor="let equipment of roomForm.equipment.items" [hidden]="equipment.disabled" [value]="equipment">
                {{equipment.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div style="display: grid; grid-template-columns: 1fr auto">
            <mat-form-field appearance="fill">
              <mat-label>Quantity</mat-label>
              <input matInput [(ngModel)]="roomForm.equipment.equipmentToAdd.quantity">
            </mat-form-field>
            <button mat-button class="form-add-item-button" (click)="roomForm.click_addEquipment(roomForm.equipment.equipmentToAdd)">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <!-- EQUIPMENT END -->
        
        <!-- MASTER ROOM START -->
        <h5>Master Room</h5>
        <table class="table table-striped" style="margin-bottom: 0px;">
          <tbody>
            <!-- MASTER ROOM DISPLAY -->
            <tr *ngIf="roomForm.masterRoom.room">
              <td style="padding-right: 0px;">
                {{roomForm.masterRoom.room.title}}
              </td>
              <td style="width: 1%">
                <button mat-button (click)="roomForm.deleteMasterRoom()">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
            <!-- MASTER ROOM INPUT -->
            <tr *ngIf="!roomForm.masterRoom.room">
              <td style="padding-right: 0px;">
                <mat-form-field appearance="fill" style="width: 100%">
                  <mat-label>Master Room</mat-label>
                  <mat-select [(ngModel)]="roomForm.masterRoom.selectedRoom">
                    <mat-option *ngFor="let room of roomForm.masterRoom.roomsDropdown" [value]="room">
                      {{room.title}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td style="width: 1%">
                <button mat-button class="form-add-item-button" (click)="roomForm.addMasterRoom()">
                  <mat-icon>add</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- ----------------------------------------------- SUB ROOM START ------------------------------------------------- -->
        <div>
          <h5>Sub Rooms</h5>
          <div *ngIf="roomForm.subRooms.roomsDropdown">
            <table class="table table-striped" style="margin-bottom: 0px;">
              <tbody>
                <!-- LIST OF ROOM ITEMS -->
                <tr *ngFor="let r of roomForm.subRooms.items">
                  <td style="padding-right: 0px;">
                    {{r.title}}
                  </td>
                  <td style="width: 1%">
                    <button mat-button (click)="roomForm.deleteSubRoom(r.id)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
                <!-- ROOM ADD DROPDOWN -->
                <tr>
                  <td style="padding-right: 0px;">
                    <mat-form-field appearance="fill" style="width: 100%">
                      <mat-label>Sub Room</mat-label>
                      <mat-select [(ngModel)]="roomForm.subRooms.selected">
                        <mat-option *ngFor="let room of roomForm.subRooms.roomsDropdown" [value]="room">
                          {{room.title}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td style="width: 1%">
                    <button mat-button class="form-add-item-button" (click)="roomForm.addSubRoom()">
                      <mat-icon>add</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer" style="padding-right: 10px; padding-bottom:10px; text-align:end">
          <span>
            <!-- <button mat-button (click)="logRoom(room)">Log Room</button> -->
            <button mat-button [disabled]="roomForm.formIsPosting" (click)="click_cancelRoomForm()">Cancel</button>
            <button mat-button [disabled]="roomForm.formIsPosting" (click)="click_submitRoomForm()">Save</button>
          </span>
        </div>
      </div>
      <!-- ----------------------------------------------------- Equipments View --------------------------------------------------- -->
      <div *ngIf="uiDisplay.equipment">
        <div style="display: grid; grid-template-columns: 1fr 50% 1fr; padding-top: 20px;">    
          <mat-form-field appearance="fill" style="grid-column-start: 2;">
            <mat-label>Search Equipment</mat-label>
            <input matInput>
          </mat-form-field>
          <div style="margin-right: 5px; text-align-last: end; margin-top: 24px;">
            <button mat-stroked-button (click)="click_toggleCreateEquipment()">Create Equipment</button>
          </div>
        </div>
        <div class="create-event-form" *ngIf="uiDisplay.equipmentCreationForm">
          Create Equipment Form
          <mat-form-field appearance="fill" style="width: 100%; padding-top: 20px;">
            <mat-label>Title</mat-label>
            <input matInput [(ngModel)]="equipmentForm.title">
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%; padding-top: 20px;">
            <mat-label>Description</mat-label>
            <input matInput [(ngModel)]="equipmentForm.description">
          </mat-form-field>
          <div style="margin-right: 5px; text-align-last: end; margin-top: 24px;">
            <button mat-stroked-button (click)="click_cancelCreateEquipment()">Cancel</button>
            <button mat-stroked-button (click)="click_submitCreateEquipment()">Submit</button>
          </div>
        </div>
        <table *ngIf="allEquipment" mat-table [dataSource]="allEquipment.items" class="mat-elevation-z8" style="width: 100%;">
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Equipment </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Quantity </th>
            <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" style="text-align: end;">
              <button type="button" mat-icon-button color="warn" matTooltip="Edit Equipment" (click)="click_editEquipment(element)"><mat-icon>edit</mat-icon></button>
              <button type="button" mat-icon-button color="warn" matTooltip="Delete Equipment" (click)="click_deleteEquipment(element)"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="equipmentsTableColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: equipmentsTableColumns;"></tr>
        </table>
      </div>
      <!-- ----------------------------------------------------- Catering View --------------------------------------------------- -->
      <div *ngIf="uiDisplay.catering">
        <div style="display: grid; grid-template-columns: 1fr 50% 1fr; padding-top: 20px;">    
          <mat-form-field appearance="fill" style="grid-column-start: 2;">
            <mat-label>Search Catering</mat-label>
            <input matInput>
          </mat-form-field>
          <div style="margin-right: 5px; text-align-last: end; margin-top: 24px;">
            <button mat-stroked-button (click)="click_toggleCreateCatering()">Create Catering</button>
          </div>
        </div>
        <div class="create-event-form" *ngIf="uiDisplay.cateringCreationForm">
          Create Catering Form
          <mat-form-field appearance="fill" style="width: 100%; padding-top: 20px;">
            <mat-label>Title</mat-label>
            <input matInput [(ngModel)]="cateringForm.title">
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%; padding-top: 20px;">
            <mat-label>Price</mat-label>
            <input matInput [(ngModel)]="cateringForm.price">
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%; padding-top: 20px;">
            <mat-label>Description</mat-label>
            <input matInput [(ngModel)]="cateringForm.description">
          </mat-form-field>
          <div style="margin-right: 5px; text-align-last: end; margin-top: 24px;">
            <button mat-stroked-button (click)="click_cancelCreateCatering()">Cancel</button>
            <button mat-stroked-button (click)="click_submitCreateCatering()">Submit</button>
          </div>
        </div>
        <table *ngIf="allCatering" mat-table [dataSource]="allCatering.items" class="mat-elevation-z8" style="width: 100%;">
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Catering </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Price </th>
            <td mat-cell *matCellDef="let element"> {{element.price}} </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" style="text-align: end;">
              <button type="button" mat-icon-button color="warn" matTooltip="Delete Catering" (click)="click_deleteCatering(element)"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="cateringTableColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: cateringTableColumns;"></tr>
        </table>
      </div>
      <!-- ----------------------------------------------------- Departments View --------------------------------------------------- -->
      <div *ngIf="uiDisplay.departments">
        <div style="display: grid; grid-template-columns: 1fr 50% 1fr; padding-top: 20px;">    
          <mat-form-field appearance="fill" style="grid-column-start: 2;">
            <mat-label>Search Departments</mat-label>
            <input matInput>
          </mat-form-field>
          <div style="margin-right: 5px; text-align-last: end; margin-top: 24px;">
            <button mat-stroked-button (click)="click_toggleCreateDepartment()">Create Department</button>
          </div>
        </div>
        
        <div class="create-event-form" *ngIf="uiDisplay.departmentCreationForm">
          Create Department Form
          
          <mat-form-field appearance="fill" style="width: 100%; padding-top: 20px;">
            <mat-label>Title</mat-label>
            <input matInput [(ngModel)]="departmentForm.title">
          </mat-form-field>
          

          <div style="margin-right: 5px; text-align-last: end; margin-top: 24px;">
            <button mat-stroked-button (click)="click_cancelCreateDepartment()">Cancel</button>
            <button mat-stroked-button (click)="click_submitCreateDepartment()">Submit</button>
          </div>
        </div>

        <table *ngIf="allDepartments" mat-table [dataSource]="allDepartments.items" class="mat-elevation-z8" style="width: 100%;">
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Department </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" style="text-align: end;">
              <button type="button" mat-icon-button color="warn" matTooltip="View Department" (click)="click_openDepartment(element)">
                <mat-icon> open_in_new</mat-icon>
              </button>
              <button type="button" mat-icon-button color="warn" matTooltip="Delete Department" (click)="click_deleteDepartment(element)"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="departmentsTableColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: departmentsTableColumns;"></tr>
        </table>
      </div>
      <!-- ----------------------------------------------------- Users View --------------------------------------------------- -->
      <div *ngIf="uiDisplay.manageAdUserGroupUserRole" style="padding-top: 20px;">
        <table mat-table [dataSource]="allAdUserGroupUserRoles" class="mat-elevation-z8" style="width: 100%;">
          <ng-container matColumnDef="adUserGroup">
            <th mat-header-cell *matHeaderCellDef> Active Directory User Group </th>
            <td mat-cell *matCellDef="let element"> 
              <div *ngIf="!element.isEditing">
                {{element.adUserGroup}} 
              </div>
              <div *ngIf="element.isEditing">
                <mat-form-field style="padding-top: 20px;">
                  <mat-label>User Group</mat-label>
                  <input matInput [(ngModel)]="element.adUserGroupUserRoleForm.adUserGroup">
                </mat-form-field>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="userRole">
            <th mat-header-cell *matHeaderCellDef> User Role </th>
            <td mat-cell *matCellDef="let element"> 
              <div>
                {{element.userRole}} 
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" style="text-align: end;">
              <button *ngIf="!element.isEditing" type="button" mat-icon-button color="warn" matTooltip="Edit" (click)="click_adUserGroupUserRoleEdit(element)"><mat-icon>edit</mat-icon></button>
              <button *ngIf="element.isEditing" type="button" mat-icon-button color="warn" matTooltip="Close" (click)="click_adUserGroupUserRoleEditCancel(element)"><mat-icon>close</mat-icon></button>
              <button *ngIf="element.isEditing" type="button" mat-icon-button color="warn" matTooltip="Submit" (click)="click_adUserGroupUserRoleSubmit(element)"><mat-icon>done</mat-icon></button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="allAdUserGroupUserRolesTableColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: allAdUserGroupUserRolesTableColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
</div>