import { BaseMasterItem } from './BaseMasterItem';
import { Room } from './room';
import { BookingStatus } from './bookingStatus';
import { EventType } from './eventType';
import { Seating } from './seating';
import { Person } from './person';
import { Break } from './break';
import { Equipment } from './Equipment';
import { Equipments } from './Equipments';
import { Department } from './department';
import { Breaks } from './breaks';
import { SeriesFilterReturnData } from './SeriesFilterReturnData';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { BookingEventForm } from './BookingEventForm';
import { Session } from './session';
import { BookingEventsService } from '../../services/BookingEvents.service'
import { MasterDataService } from '../../services/MasterData.service';
import { DepartmentBasic } from './DepartmentBasic';
import { RoomBasic } from './roomBasic';

export class BookingEventBasic extends BaseMasterItem {
    
    startTime:Date;
    endTime:Date;
    maximumAttendees: number;
    hasCatering: boolean;
    hasVideoConferencing: boolean;

    // Form inputs ----------------------------------------------------------------------------
    editEvent: Boolean = false;
    //bookingEventForm: BookingEventForm;

    department: DepartmentBasic;
    room: RoomBasic;

    breaks: Breaks;
    equipment: Equipments;
    eventDate: Date;
    startHour: number = null;
    startMinute: number = null;
    endHour: number = null;
    endMinute: number = null;

    location:string;
    description:string;
    category:string;
    people:number;
    setupTimePost:number;
    setupTimePre:number
    seriesGUID:number;
    participantsPicker:Person;
    
    seating:Seating;
    bookingStatus:BookingStatus;
    eventType:EventType
    
    dayOfWeek: {dayIndex: number, day: string};
    creatorAdId: string;

    userRelations: {
        created: boolean,
        attending: boolean
    } = {
        created: false,
        attending: false
    }

    seatingId:number;
    roomId:number;
    departmentId: number;
    bookingStatusId: number;
    eventTypeId:number;
    bookingId:number;

    width:number;
    left:number;
    displayColor:string;
    
    loadFromJson(jsonItem){
        //console.log(jsonItem);

        super.loadFromJson(jsonItem);
        this.location = jsonItem.location;
        this.startTime = new Date(jsonItem.startTime);
        this.endTime = new Date(jsonItem.endTime);
        this.description = jsonItem.description;
        this.category = jsonItem.category;
        this.people = jsonItem.people;
        this.setupTimePost = jsonItem.setupTimePost;
        this.setupTimePre = jsonItem.setupTimePre;
        this.seriesGUID = jsonItem.seriesGuid;
        this.participantsPicker = jsonItem.participantsPickerId;

        
        //this.setBooking(jsonItem);

        if(jsonItem.equipment){
            if(jsonItem.equipment.items){
                if(jsonItem.equipment.items.Count != 0){
                    this.equipment = new Equipments();
                    this.equipment.loadFromJson(Equipment, jsonItem.equipment.items);
                } 
            }
        }

        if(jsonItem.breaks){
            this.breaks = new Breaks();
            this.breaks.loadFromJson(Break, jsonItem.breaks.items);
        }

        if(jsonItem.bookingId){
            this.bookingId = jsonItem.bookingId;
        }

        if(jsonItem.seatingId){
            this.seatingId = jsonItem.seatingId;
        }
        
        if(jsonItem.roomId){
            this.roomId = jsonItem.roomId;
        };
        
        if(jsonItem.bookingStatusId){
            this.bookingStatusId = jsonItem.bookingStatusId;
        };
        
        if(jsonItem.eventTypeId){
            this.eventTypeId = jsonItem.eventTypeId;
        };

        if(jsonItem.itemIds){
            if(jsonItem.itemIds.bookingId){
                this.bookingId = jsonItem.itemIds.bookingId;
            }
    
            if(jsonItem.itemIds.seatingId){
                this.seatingId = jsonItem.itemIds.seatingId;
            }
            
            if(jsonItem.itemIds.roomId){
                this.roomId = jsonItem.itemIds.roomId;
            };
            
            if(jsonItem.itemIds.bookingStatusId){
                this.bookingStatusId = jsonItem.itemIds.bookingStatusId;
            };
            
            if(jsonItem.itemIds.eventTypeId){
                this.eventTypeId = jsonItem.itemIds.eventTypeId;
            };
        }

    }

    // private setBooking(jsonItem){
    //     if(jsonItem.booking){
    //         this.bookingId = jsonItem.booking.id;
    //         this.creatorAdId = jsonItem.booking.adAccountId;
    //     } else {
    //         //this.itemIds.bookingId = jsonItem.itemIds.bookingId;
    //     }
    // }

    public setDayOfWeek(daysOfWeek: Array<string>){
      let bookingEventDayIndex = this.startTime.getDay();
      if(bookingEventDayIndex > 0){
          bookingEventDayIndex = bookingEventDayIndex -1;
      } else {
          bookingEventDayIndex = 6
      }

      this.dayOfWeek = {dayIndex: bookingEventDayIndex, day: daysOfWeek[bookingEventDayIndex]}
    }

    // SETS THE BOOKING EVENT START/ END DATE TIME FROM FORM INPUTS
    public setTimesFromBookingEventForm(session: Session){
      this.startTime = session.eventDate;
      this.startTime.setHours(session.startHour);
      this.startTime.setMinutes(session.startMinute);
      
      this.endTime = new Date(session.eventDate.getTime());
      this.endTime.setHours(session.startHour + session.durationHour);
      this.endTime.setMinutes(session.startMinute + session.durationMinute);
    }

    public delete(bookingEventsService: BookingEventsService){
        console.log("delete: " + this.title);
        bookingEventsService.deleteBookingEventById(this.id);
    }

    public addAttendance(bookingEventsService: BookingEventsService, msalService:MsalService){
        console.log("addAttendance: " + this.title); 
        let userId = msalService.instance.getActiveAccount().localAccountId;

        return bookingEventsService.addAttendance(this.id, userId);
    }

    public deleteAttendance(bookingEventsService: BookingEventsService, msalService: MsalService){
        console.log("Delete Attendance: " + this.title); 
        let userId = msalService.instance.getActiveAccount().localAccountId;

        return bookingEventsService.deleteAttendance(this.id, userId);
    }

    public sendInvitation(msalService: MsalService){
        console.log("sendInvitation" + this.title);
    }

    public navigateTo(router: Router){
        console.log(this.id);
        
        router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        router.navigate([ 'bookingEvent', {id: this.id, edit: false} ]));
    }

}
