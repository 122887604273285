import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTimeString'
})
export class DateTimeStringPipe implements PipeTransform {
  transform(value: Date): string {
    const temp: moment.Moment = moment(value);
    return temp.format('DD/MM/YYYY h:mma');
  }
}
