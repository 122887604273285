import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDataService } from '../shared/interfaces/IDataService';
import { environment } from 'src/environments/environment';
import { Room } from '../shared/models/room';
import { Users } from '../shared/models/User/Users';

@Injectable({
  providedIn: 'root'
})
export class SqlService {

  constructor(
    private http: HttpClient
    ) { }

  url = environment.apiUrl;

  // ----------------------------------Get----------------------------------
  public getItems(listName: string, functionName?: string, whereClause?: Array<string>, token?: string){
    if(listName && functionName && whereClause && token){
      return this.http.get(`${this.url}/${listName}/${functionName}/${whereClause}/${token}`);
    } else
    if(listName && functionName && token){
      return this.http.get(`${this.url}/${listName}/${functionName}/${token}`);
    } else
    if(listName && functionName && whereClause){
      return this.http.get(`${this.url}/${listName}/${functionName}/${whereClause}`);
    } else 
    if(listName && functionName){
      return this.http.get(`${this.url}/${listName}/${functionName}`);
    } else 
    if(listName){
      return this.http.get(`${this.url}/${listName}`);
    }
  } 

  public getItemById(listName: string, id: number): Observable<any>{
    return this.http.get(`${this.url}/${listName}/getItemById/${id}`);
  }

  public getItemByIdString(controllerName: string, functionName: string, id: string, accessToken: string){
    return this.http.get(`${this.url}/${controllerName}/${functionName}/${id}/${accessToken}`);
  }

  public getItemsByDate(listName:string, date: Date): Observable<any> {
    let isoDate = this.getIsoDateTime(date);
    return this.http.get(`${this.url}/${listName}/getItemsByDate/${isoDate}`);
  }
  
  public getByDate(listName:string, methodName: string, date: Date): Observable<any> {
    let isoDate = this.getIsoDateTime(date);
    return this.http.get(`${this.url}/${listName}/${methodName}/${isoDate}`);
  }

  public getByDate_IdString(listName:string, methodName: string, date: Date, id: string): Observable<any> {
    let isoDate = this.getIsoDateTime(date);
    return this.http.get(`${this.url}/${listName}/${methodName}/${isoDate}/${id}`);
  }

  private getIsoDateTime(date: Date): string{
    return new Date(date.getTime()).toISOString();
  }

  // public getBookingEventsByDay(date: Date): Observable<any> {
  //   date.setHours(0);
  //   date.setMinutes(0)
  //   date.setSeconds(0);
  //   date.setMilliseconds(0);
  //   let isoDateTime = new Date(date.getTime()).toISOString();

  //   return this.http.get(`${this.url}/bookingEvents/GetBookingEventsByDate/${isoDateTime}`)
  // } 

  public getBookingEventsByBookingId(Id): Observable<any>{
    return this.http.get(`${this.url}/bookingEvents/GetBookingEventsByBookingId/${Id}`)
  }

  public getBookingEventsByRoom(id: number): Observable<any>{
    console.log('gettingBookingEventsByRoomId: ' + id);
    return this.http.get(`${this.url}/bookingEvents/getBookingEventsByRoom/${id}`);
  }

  public getCurrentUser(): Observable<any>{
    return this.http.get(`${this.url}/user/current/`);
  }

  public getCurrentUsersFutureBookingEventsAttending(userId: string, todaysDate: Date): Observable<any>{
    todaysDate.setHours(6);
    todaysDate.setMinutes(0)
    todaysDate.setSeconds(0);
    todaysDate.setMilliseconds(0);
    let isoDateTime = new Date(todaysDate.getTime()).toISOString();
    
    return this.http.get(`${this.url}/bookingEvents/getCurrentUsersFutureBookingEventsAttending/${userId}/${isoDateTime}`);
  }
  
  public getCurrentUsersFutureBookingEvents(userId: string): Observable<any>{
    let todaysDate = new Date()
    todaysDate.setHours(6);
    todaysDate.setMinutes(0)
    todaysDate.setSeconds(0);
    todaysDate.setMilliseconds(0);
    let isoDateTime = new Date(todaysDate.getTime()).toISOString();
    
    return this.http.get(`${this.url}/BookingEvents/getCurrentUsersFutureBookingEvents/${userId}/${isoDateTime}`);
  }
  
  public getAllRoomsWithBookingEventsByDate(selectedDate: Date){
    selectedDate.setHours(6);
    selectedDate.setMinutes(0)
    selectedDate.setSeconds(0);
    selectedDate.setMilliseconds(0);
    let isoDateTime = new Date(selectedDate.getTime()).toISOString();
    
    return this.http.get(`${this.url}/rooms/getAllRoomsWithBookingEventsByDate/${isoDateTime}`);
  }
  
  public GetAllMonthRoomUtilization(){   
    let todayIsoDate = this.GetTodayIsoDateTime();
    return this.http.get(`${this.url}/rooms/GetAllMonthRoomUtilization/${todayIsoDate}`);
  }
  
  public getReportingMonth(date: Date){
    let todayIsoDate = this.GetTodayIsoDateTime();
    console.log(date);
    console.log(todayIsoDate);

    return this.http.get(`${this.url}/reporting/getMonth/${todayIsoDate}`);
  }

  public getUserByAdAccountId(id: string): Observable<any> {
    return this.http.get(`${this.url}/user/getUserByAdAccountId/${id}`);
  }

  // --------------------------------   BOOKINGS -------------------------------------------

  public getBookingsBySeriesId(id: number): Observable<any>{
    return this.http.get(`${this.url}/Bookings/seriesId/${id}`);
  }

  public getBookingsByUserId(id: string): Observable<any>{
    return this.http.get(`${this.url}/Bookings/uniqueUserId/${id}`);
  }

  // ----------------------------------Create----------------------------------

  public createNewItem(listName: string, dataToPost: any): Observable<any>{
    console.log(listName);
    console.log(dataToPost);
    
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) }

    return this.http.post(`${this.url}/${listName}/createNewItem`, dataToPost, httpOptions);
  }

  public createNewItems(listName: string, dataToPost: any): Observable<any>{
    console.log(listName);
    console.log(dataToPost);
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'})}

    return this.http.post(`${this.url}/${listName}/createItems`, dataToPost, httpOptions);
  }

  public create(listName: string, methodName, dataToPost){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) }

    return this.http.post(`${environment.apiUrl}/${listName}/${methodName}`, dataToPost, httpOptions);
  }


  // ----------------------------------Update----------------------------------

  public updateEventFormFilteredLists(start, end){
    return this.http.get(`${this.url}/bookingEvents/updateEventFormFilteredLists/${start}/${end}`);
  }

  public updateItem(listName, item): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) }

    return this.http.put(`${this.url}/${listName}/updateItem`, item, httpOptions);
  }

  update(listName: string, methodName, dataToPost){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) }

    return this.http.post(`${environment.apiUrl}/${listName}/${methodName}`, dataToPost, httpOptions);
  }
  
  public updateRoomDepartment(room: Room): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) }
    
    return this.http.put(`${environment.apiUrl}/rooms/updateDepartment`, room, httpOptions);
  }
  
  
  
  
  
  // ----------------------------------Delete----------------------------------
  
  public deleteItem(listName, id, column?): Observable<any>{
    
    if(column == undefined){
      return this.http.delete(`${this.url}/${listName}/deleteItem/${id}`);
    }
    
    return this.http.delete(`${this.url}/${listName}/deleteItem/${id}/${column}`);
  }

  // public deleteById(listName: string, methodName: string, id:number){
  //   return this.http.delete(`${environment.apiUrl}/${listName}/${methodName}/${id}`);
  // }
  
  public deleteRoom(room: Room){
    console.log(room.id);
    return this.http.delete(`${this.url}/Rooms/DeleteRoom/${room.id}`);
  }


  // ------------------------------------ BOOKING EVENT ATTENDANCE ------------------------------
  public addAttendance(bookingEventId: number, userId: string): Observable<any> {
    console.log("NO SHAREPOINT SERVICE");
    return this.http.post(`${this.url}/bookingEventsAttendees/addAttendee/${bookingEventId}/${userId}`, null);
  }

  public deleteAttendance(bookingEventId: number, userId: string): Observable<any> {
    return this.http.delete(`${this.url}/bookingEventsAttendees/deleteAttendee/${bookingEventId}/${userId}`);
  }

  // ------------------------------------ FILTER -------------------------------------------------
  public filter(controllerName: string, methodName: string, dataTofilter){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) }
    return this.http.post(`${environment.apiUrl}/${controllerName}/${methodName}`, dataTofilter, httpOptions);
  }
  
  private GetTodayIsoDateTime(){
    let todaysDate = new Date()
    todaysDate.setHours(6);
    todaysDate.setMinutes(0)
    todaysDate.setSeconds(0);
    todaysDate.setMilliseconds(0);
    let isoDateTime = new Date(todaysDate.getTime()).toISOString();
    return isoDateTime;
  }
  
  public updateDbUsersData(userData: Users){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) }
    return this.http.post(`${environment.apiUrl}/user/updateUserData`, userData, httpOptions)
  }

} 