import { AdUserGroupUserRoleService } from "src/app/services/AdUserGroupUserRole.service";
import { AdUserGroupUserRoleForm } from "./AdUserGroupUserRoleForm";

export class AdUserGroupUserRole {
    id: number;
    adUserGroup: string;
    userRole: string;

    isEditing: boolean;
    adUserGroupUserRoleForm: AdUserGroupUserRoleForm;

    public loadFromJson(jsonItem: any){
        this.id = jsonItem.id;
        this.adUserGroup = jsonItem.adUserGroup;
        this.userRole = jsonItem.userRole;
    }

    public edit(){
        this.isEditing = true;
        this.initForm();
    }

    public initForm(){
        this.adUserGroupUserRoleForm = new AdUserGroupUserRoleForm();
        this.adUserGroupUserRoleForm.id = this.id;
        this.adUserGroupUserRoleForm.adUserGroup = this.adUserGroup;
        this.adUserGroupUserRoleForm.userRole = this.userRole;
    }

    public editCancel(){
        this.isEditing = false;
        this.adUserGroupUserRoleForm = null;
    }

    public submitForm(adUserGroupUserRoleService: AdUserGroupUserRoleService){
        adUserGroupUserRoleService.update(this.adUserGroupUserRoleForm).subscribe((adUserGroupUserRole: AdUserGroupUserRole) => {
            this.loadFromJson(adUserGroupUserRole);
            this.editCancel();
        })
    }

}
