<div class="d-flex flex-column wrapper" style="max-height: 100%;">
    <mat-toolbar class="normalize align-items-center" color="primary">
      <div class="flex-inner">
        <div class="row h-100 justify-content-between">
          <div class="col-auto xs-only">
            <div class="wrapper d-flex justify-content-start align-items-center">
              <h1>Calendar for <strong>{{ department }}</strong></h1>
            </div>
          </div>
          <div class="col-auto md-only">
            <div class="wrapper d-flex justify-content-center align-items-center">
              <h1>Select Date: <strong>{{ date | longDateString }}</strong></h1>
            </div>
          </div>
          <div class="col-auto">
            <div class="wrapper d-flex justify-content-end align-items-center">
              <button mat-icon-button type="button" (click)="filter = !filter"><mat-icon>filter_alt</mat-icon></button>
              <button mat-icon-button type="button"><mat-icon>tune</mat-icon></button>
            </div>
          </div>
        </div>
      </div>
    </mat-toolbar>

    <div class="filter-settings" *ngIf="filter">
      <div class="row justify-content-center">
        <div class="col-auto">
          <mat-form-field color="accent" appearance="outline" class="nopad-bottom">
            <mat-label>Select Department</mat-label>
            <mat-select [(ngModel)]="department">
              <mat-option value="Department 1">Department 1</mat-option>
              <mat-option value="Department 2">Department 2</mat-option>
              <mat-option value="Department 3">Department 3</mat-option>
              <mat-option value="Department 4">Department 4</mat-option>
              <mat-option value="Department 5">Department 5</mat-option>
              <mat-option value="Department 6">Department 6</mat-option>
              <mat-option value="Department 7">Department 7</mat-option>
              <mat-option value="Department 8">Department 8</mat-option>
              <mat-option value="Department 9">Department 9</mat-option>
              <mat-option value="Department 10">Department 10</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-auto">
          <mat-form-field color="accent" appearance="outline" class="nopad-bottom">
            <mat-label>Selected Rooms</mat-label>
            <mat-select [formControl]="roomSelection" multiple>
              <mat-select-trigger>
                {{roomSelection.value ? roomSelection.value[0] : ''}}
                <span *ngIf="roomSelection.value?.length > 1" class="additional-selection">
                  (+{{roomSelection.value.length - 1}} {{roomSelection.value?.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let room of data" [value]="room.label">{{ room.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-auto">
          <mat-form-field appearance="outline" class="nopad-bottom">
            <mat-label>Choose a date</mat-label>
            <input (ngModelChange)="loadData()" matInput [(ngModel)]="date" [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- <div class="filter-settings" *ngIf="filter">
      <div class="cnt">
        <mat-form-field color="accent" appearance="outline" class="nopad-bottom">
          <mat-label>Select Department</mat-label>
          <mat-select [(ngModel)]="department">
            <mat-option value="Department 1">Department 1</mat-option>
            <mat-option value="Department 2">Department 2</mat-option>
            <mat-option value="Department 3">Department 3</mat-option>
            <mat-option value="Department 4">Department 4</mat-option>
            <mat-option value="Department 5">Department 5</mat-option>
            <mat-option value="Department 6">Department 6</mat-option>
            <mat-option value="Department 7">Department 7</mat-option>
            <mat-option value="Department 8">Department 8</mat-option>
            <mat-option value="Department 9">Department 9</mat-option>
            <mat-option value="Department 10">Department 10</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="cnt">
        <mat-form-field color="accent" appearance="outline" class="nopad-bottom">
          <mat-label>Selected Rooms</mat-label>
          <mat-select [formControl]="roomSelection" multiple>
            <mat-select-trigger>
              {{roomSelection.value ? roomSelection.value[0] : ''}}
              <span *ngIf="roomSelection.value?.length > 1" class="additional-selection">
                (+{{roomSelection.value.length - 1}} {{roomSelection.value?.length === 2 ? 'other' : 'others'}})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let room of data" [value]="room.label">{{ room.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="cnt">
        <mat-form-field appearance="outline" class="nopad-bottom">
          <mat-label>Choose a date</mat-label>
          <input (ngModelChange)="loadData()" matInput [(ngModel)]="date" [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div> -->

    <div class="calendar-main p-1">
      <mat-card class="h-100">
            <app-event-calendar-overview [items]="data" [start]="{hours: 6, minutes: 0}" [end]="{hours: 18, minutes: 0}"></app-event-calendar-overview>
      </mat-card>
    </div>
</div>
