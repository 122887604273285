import { BaseMasterItem } from './BaseMasterItem';
import { MasterDataService } from 'src/app/services/MasterData.service';

export class RoomRelation extends BaseMasterItem {

    blockingSource: Array<BaseMasterItem>;
    blockingTarget: Array<BaseMasterItem>;

    masterRoomId: number;
    subRoomId: number;

    loadFromJson(jsonItem){
        super.loadFromJson(jsonItem);

        this.masterRoomId = jsonItem.masterRoomId;
        this.subRoomId = jsonItem.subRoomId;
    }

    // loadExtraData(rooms){
    //     this.blockingSource = rooms.getById(this.blockingSourceId);
    //     this.blockingTarget = rooms.getById(this.blockingTargetId); 
    // }

}
