import { Component, OnInit, ɵConsole } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { defaultIfEmpty, map, switchMap, takeUntil } from 'rxjs/operators';
import { BookingService } from 'src/app/services/booking.service';
import { SeriesFilterReturnData } from 'src/app/shared/models/SeriesFilterReturnData';
import { Booking } from 'src/app/shared/models/booking';
import { Bookings } from 'src/app/shared/models/bookings';
import { BookingEvent } from 'src/app/shared/models/BookingEvent'
import { BookingEvents } from 'src/app/shared/models/BookingEvents';
import { HttpClient } from '@angular/common/http';
import { Equipment } from 'src/app/shared/models/Equipment';
import { MasterDataService } from 'src/app/services/MasterData.service';
import { Break } from 'src/app/shared/models/break';
import { Subject } from 'rxjs';
import { WaitingScreenService } from 'src/app/services/waitingScreen.service';

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss']
})
export class SeriesComponent implements OnInit {



  bookings: Bookings;

  protoBookings: Bookings = new Bookings;
  minDate: Date = new Date();

  seriesForm : {
    repeatType: number,
    repeatInterval,
    repeatUntil: Date
  } = {
    repeatType: 0,
    repeatInterval: 1,
    repeatUntil: null
  }
  
  constructor(
    private bookingService: BookingService,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private masterDataService: MasterDataService,
    private router: Router,
    private waitingScreenService: WaitingScreenService,
    //private seriesFilterService: SeriesFilterService,
  ) { }

  // ------------------------- Initiation stuff -------------------------
  ngOnInit() {

    let ws = this.waitingScreenService;
    this.waitingScreenService.activate();
    setTimeout(function () {
      ws.deactivate();
    }, 500);
    
    this.getSeriesId().subscribe(seriesId => {
      console.log(seriesId);
      if(seriesId != undefined){
        this.getBookingsBySeriesId(seriesId);
      } else {
        alert('something went wrong, add re-routing functionhere');
      }
    });
  }

  getSeriesId(){
    return this.route.params.pipe(map(params => {
      let param = params["id"];
      console.log(param);
      return param;
    }));
  }

  getBookingsBySeriesId(id: number){
    console.log("GETTING ALL SERIES BOOKINGS FROM DB - SERIES ID: " + id);
    this.bookingService.getBookingsBySeriesId(id).subscribe((bookings: Bookings) => {
      console.log(bookings);
      
      this.bookings = bookings;
    })
  }

  // ----------------------------- FORM STUFF ----------------------------------
  formChanged(){
    console.log("----------Form Changed--------------");
    console.log(this.seriesForm);

    // CHECK IF FORM IS POPULATED BEFORECREATING PROTO BOOKINGS
    if(this.formNotPopulated()){
      console.log("FORM NOT POPULATED");
      return;
    }
    console.log("FORM IS POPULATED");

    //this.cancelFilter();
    this.createProtoBookings();
  }

  createProtoBookings(){
    console.log("CREATING PROTO BOOKINGS");

    let lastBooking:Booking = this.bookings.items[this.bookings.items.length-1];
    console.log(lastBooking);

    let protoBookings = new Bookings();

    let kill = 0;
    let repeatInterval = this.seriesForm.repeatInterval;

    // create new bookings until the items are over the end date
    for (let i = 1; i > 0;) {

      if(kill > 100){
        console.log("KILLING THE INFINATE LOOP");

        console.log("PROTOBOOKINGS: ");
        console.log(protoBookings);
        this.protoBookings = protoBookings;

        return;
      }

      console.log("--------------- PROTO BOOKING START -------------");
      lastBooking = this.setLastBooking(lastBooking, protoBookings);
      console.log(protoBookings);

      
      let protoBooking = this.createNewProtoBooking(lastBooking);
      let bookingEventIndex = 0;

      lastBooking.bookingEvents.items.forEach(be => {
      console.log("------------- PROTO BOOKING EVENT START -----------");
        // 1
        let protoBookingEvent:BookingEvent = this.createNewProtoBookingEvent(be);

        // 2
        this.changeProtoBookingEventDate(protoBookingEvent, lastBooking, bookingEventIndex);

        // 3
        //protoBookingEvent.setFormInputs();

        // 4
        bookingEventIndex++;

        // 5
        if(protoBookingEvent.startTime > this.seriesForm.repeatUntil){
          console.log("END THE LOOP NOW");
          //this.addEventToProtoBooking(protoBooking, new BookingEvent());
          i = -1;
          return;
        } else {
          this.addEventToProtoBooking(protoBooking, protoBookingEvent);
        }
        console.log("--------- PROTO BOOKING EVENT END ------------");
      })

      // ADD BOOKING TO LIST OF BOOKINGS
      if(i > 0){
        protoBookings.items.push(protoBooking);
      }
      
      console.log("---------- PROTO BOOKING END ---------");

      kill = kill + 1;
    }
    this.protoBookings = protoBookings;
    console.log("PROTO BOOKING");
    console.log(this.protoBookings);

    this.checkProtoBookingEventConfilcts();
  }

  // ------------------------------------------------------------------------------
  formNotPopulated(){    
    if(this.seriesForm.repeatType == 0){
      console.log("FORM NOT POPULATED - 1")
      return true;
    }
    if(this.seriesForm.repeatInterval == 0){
      console.log("FORM NOT POPULATED - 2")
      return true;
    }
    if(this.seriesForm.repeatUntil == null){
      console.log("FORM NOT POPULATED - 3")
      return true;
    }
  }

  // --------------------------------------------------------------------------------
  setLastBooking(lastBooking: Booking, protoBookings:Bookings){
    console.log("SET LAST BOOKING");

    if(protoBookings.items.length > 0){
      // SET LAST BOOKING TO THE LAST BOOKING
      lastBooking = protoBookings.items[protoBookings.items.length-1];
      console.log(lastBooking)
    }

    return lastBooking;
  }

  // --------------------------------------------------------------------------------
  createNewProtoBooking(lastBooking: Booking){
    let newProtoBooking = new Booking();
    newProtoBooking.loadFromJson(lastBooking);
    newProtoBooking.id = undefined;
    return newProtoBooking;
  }

  // --------------------------------------------------------------------------------
  createNewProtoBookingEvent(lastBookingEvent: BookingEvent){
    let newProtoBookingEvent = new BookingEvent();
    newProtoBookingEvent.loadFromJson(lastBookingEvent);
    newProtoBookingEvent.id = undefined;
    //newProtoBookingEvent.populateFormData(this.masterDataService);
    return newProtoBookingEvent;
  }

  // ------------------------------------------------------------------------------
  changeProtoBookingEventDate(protoBookingEvent: BookingEvent, lastBooking: Booking, bookingEventIndex: number){
    console.log("CHANGE BOOKING EVENT DATE");
    console.log(protoBookingEvent);
    console.log(lastBooking);
    console.log(bookingEventIndex);

    let lastBookingEvent = lastBooking.bookingEvents.items[bookingEventIndex];
    console.log(lastBookingEvent);
    
    // Day
    if(this.seriesForm.repeatType == 1){
      protoBookingEvent.startTime.setDate(lastBookingEvent.startTime.getDate()+this.seriesForm.repeatInterval);
      protoBookingEvent.endTime.setDate(lastBookingEvent.endTime.getDate()+this.seriesForm.repeatInterval);
    }

    // Week
    if(this.seriesForm.repeatType == 2){
      protoBookingEvent.startTime.setDate(lastBookingEvent.startTime.getDate()+this.seriesForm.repeatInterval*7);
      protoBookingEvent.endTime.setDate(lastBookingEvent.endTime.getDate()+this.seriesForm.repeatInterval*7);
    }

    // Month
    if(this.seriesForm.repeatType == 3){
      protoBookingEvent.startTime.setMonth(lastBookingEvent.startTime.getMonth()+this.seriesForm.repeatInterval);
      protoBookingEvent.endTime.setMonth(lastBookingEvent.endTime.getMonth()+this.seriesForm.repeatInterval);
    }

    // Year
    if(this.seriesForm.repeatType == 4){
      protoBookingEvent.startTime.setFullYear(lastBookingEvent.startTime.setFullYear()+this.seriesForm.repeatInterval);
      protoBookingEvent.endTime.setFullYear(lastBookingEvent.endTime.setFullYear()+this.seriesForm.repeatInterval);
    }
  }

  // ------------------------------------------------------------------------------
  addEventToProtoBooking(booking: Booking, bookingEvent: BookingEvent){
    console.log("ADD EVENT TO PROTO BOOKING");
    if(booking.bookingEvents == undefined){
      booking.bookingEvents = new BookingEvents();
    }
    booking.bookingEvents.items.push(bookingEvent);
  }
  
  // ------------------------------------------------------------------------------
  destroy$: Subject<boolean> = new Subject<boolean>();

  checkProtoBookingEventConfilcts(){
    this.cancelFilter();

    console.log("CHECKING PROTO BOOKING EVENT CONFILCTS");
    this.protoBookings.updateTimesFromInputs();

    this.httpClient.post("http://localhost:5000/bookings/checkProtoBookingEventConfilcts", this.protoBookings)
      .pipe(map((data: Array<SeriesFilterReturnData>) => {

        console.log("---------------------------------------------------------");
        console.log(this.protoBookings)
        console.log(data);
        console.log("---------------------------------------------------------");

        let bookingEventIndex = 0;
        let bookingIndex = 0;

        data.forEach(returnData => {
          if(this.protoBookings.items[bookingIndex].bookingEvents.items.length == bookingEventIndex){
            bookingEventIndex = 0;
            bookingIndex++;
          }

          let protoBookingEvent = this.protoBookings.items[bookingIndex].bookingEvents.items[bookingEventIndex];

          protoBookingEvent.setDisabledData(returnData);
          protoBookingEvent.setFormConflicts();
          
          bookingEventIndex++;
        });

      }),takeUntil(this.destroy$)).subscribe();
  }

  cancelFilter() {
    console.log("cancel filter request");
    this.destroy$.next(true);
    this.destroy$.next(false);
  }

  // ----------------------------------------- FORM STUFF --------------------------------------------------

  // OPEN/ CLOSE BOOKING
  eventClick(e: BookingEvent){
    console.log(e);
    if(e.editEvent == true){
      e.editEvent = false;
    } else {
      e.editEvent = true;
    }
  }

  // CALLED WHEN EVENT INPUT IS CHANGED
  eventChanged(e){
    console.log("EVENT CHANGED");
    console.log(e);

    this.checkProtoBookingEventConfilcts();    
  }

  // BOOKING REDIRECT
  editBooking(id){
    this.router.navigate(['/booking',{'id': id}]);
  }

  // DELETE BOOKING BUTTON PRESS
  deleteBookingButtonPress(b){
    console.log(b);
    this.deleteBooking(b.id);
  }

  // ADD BOOKINGS/ BOOKING EVENTS TO DB
  addBookingsButtonPress(){
    // CHECK ALL ARE VALID
    let bookingsValid = true;

    console.log(this.protoBookings);

    this.protoBookings.items.forEach(b => {
      b.bookingEvents.items.forEach(be => {
        if(be.formData.valid.isValid != true){
          console.log("ERROR IN BOOKING EVENT, DO NOT POST");
          bookingsValid = false;
        }
      })
    })

    if(bookingsValid == true){
      this.addBookings();
    } else {
      console.log("not hit");
    }
  }

  // ------------------------------------------- EQUIPMENT STUFF --------------------------------------------------
  addEquipment(be: BookingEvent){
    //be.equipment.addNewEquipmentToItems();
  }
  
  deleteEquipment(be: BookingEvent, e: Equipment){
    //be.equipment.removeEquipmentFromItems(e);
    this.checkProtoBookingEventConfilcts();
  }

  equipmentChanged(be:BookingEvent, e: Equipment){
    //be.equipment.equipmentChanged(e);
    this.checkProtoBookingEventConfilcts();
  }

  // -------------------------------------------- BREAK STUFF -------------------------------------------------------
  addBreak(be: BookingEvent){ 
    console.log("ADD BREAK - not implemented");
    //be.breaks.AddBreakToItems();
  }
  
  deleteBreak(be: BookingEvent, b: Break){
    
    console.log("DELETE BREAK - not implemented");
    //be.breaks.DeleteBreakFromItems(b);
  }

  breakChanged(be: BookingEvent){
    console.log("BREAK CHANGED - not implemented");
    //be.breaks.breakChanged();
  }

  // -------------------------------------------- BACKEND CALLS -------------------------------------------------------

  addBookings(){
    this.protoBookings.updateTimesFromInputs();

    this.bookingService.addBookings(this.protoBookings).subscribe(d => {
      this.protoBookings = new Bookings();

      this.getSeriesId().subscribe(seriesId => {
        if(seriesId != undefined){
          this.getBookingsBySeriesId(seriesId);
        } else {
          alert('something went wrong, add re-routing function here');
        }
      });
    });
  }

  deleteBooking(id: number){
    this.bookingService.deleteBooking(id).subscribe(data => {
      console.log("BOOKING SUCCESFULLY DELETED");
      this.bookings.removeItemById(id);
    })
  }


}
