import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';
import { AdUserGroupUserRole } from '../shared/models/AdUserGroupUserRole';
import { AdUserGroupUserRoleForm } from '../shared/models/AdUserGroupUserRoleForm';

@Injectable({
  providedIn: 'root'
})
export class AdUserGroupUserRoleService {

  constructor(
    private dataService: DataService,
  ) { }

  public getAll() : Observable<any> {
    return this.dataService.getItems('AdUserGroup_UserRole', 'getAll').pipe(map((res: Array<any>) => {
      let adUserGroupUserRoles = new Array<AdUserGroupUserRole>();

      res.forEach(item => {
        let adUserGroupUserRole = new AdUserGroupUserRole();
        adUserGroupUserRole.loadFromJson(item);
        adUserGroupUserRoles.push(adUserGroupUserRole);
      })
      
      return adUserGroupUserRoles;
    }))
  }
  
  public getAllAdUserGroups(): Observable<Array<string>> {
    return this.dataService.getItems('AdUserGroup_UserRole', 'getAll').pipe(map((res: Array<any>) => {
      let adUserGroups = new Array<string>();
  
      res.forEach(item => {
        adUserGroups.push(item.adUserGroup);
      })
      
      return adUserGroups;
    }))
  }
  
  public update(adUserGroupUserRoleForm: AdUserGroupUserRoleForm): Observable<AdUserGroupUserRole>{
    return this.dataService.updateItem('AdUserGroup_UserRole', adUserGroupUserRoleForm).pipe(map(res => {
      let adUserGroupUserRole = new AdUserGroupUserRole();
      adUserGroupUserRole.loadFromJson(res);

      return adUserGroupUserRole;
    }));
  }
  
}
