import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  public bookingData;
  public broadcastedData;

  public hubConnection: signalR.HubConnection;

  public startConnection = () => {
    console.log(`starting signalR connection on: ${environment.apiUrl}/bookingshub`);
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}/bookingshub`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();

    this.hubConnection
      .start()
      .then(() => console.log("signalR service connection started"))
      .catch(err => console.log("error while starting signalR connection: " + err))
  }
 
  public broadcastBookingsData(data: any) {
     this.hubConnection.invoke('broadcastbookingsdata', data)
      .catch(err => console.log(err));
  }

  public broadcastBookingEventsData(data: any){
    this.hubConnection.invoke('broadcastBookingEventsData', data)
      .catch(err => console.log(err));
  }

  public deleteBookingEvent(data: any){
    this.hubConnection.invoke('deleteBookingEvent', data)
      .catch(err => console.log(err));
  }
  
  public createBookingEventBreak(data: any){
    this.hubConnection.invoke('createBookingEventBreak', data)
      .catch(err => console.log(err));
  }

}
