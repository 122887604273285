import { BaseMasterItem } from './BaseMasterItem';

export class Person extends BaseMasterItem {
    firstName: string;
    lastName: string;

    loadFromJson(jsonItem){
        super.loadFromJson(jsonItem);
        this.firstName = jsonItem.firstName;
        this.lastName = jsonItem.lastName;
    }
}
