import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';
import { Catering } from '../shared/models/Catering/Catering';
import { Caterings } from '../shared/models/Catering/Caterings';

@Injectable({
  providedIn: 'root'
})
export class CateringService {

  constructor(
    private dataService: DataService,
  ) { }

  public getAllCatering(): Observable<Caterings>{
    return this.dataService.getItems('catering', 'getAllCatering').pipe(map((res: Array<any>) => {
      let allCatering: Caterings = new Caterings();
      allCatering.loadFromJson(Catering, res);

      return allCatering;
    }));
  }

  public createCatering(catering: Catering): Observable<Catering> {
    return this.dataService.createNewItem('catering', catering).pipe(map((cateringData) => {
      let catering: Catering = new Catering();
      catering.loadFromJson(cateringData);

      return catering;
    }))
  }

  public deleteCatering(id: number): Observable<Catering> {
    return this.dataService.deleteItem('catering', id);
  }

}
