import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { cwd } from 'node:process';
import { MasterDataService } from 'src/app/services/MasterData.service';
import { BaseMasterItems } from './BaseMasterItems';
import { BookingEvent } from './BookingEvent';
// import { BookingEvents } from './BookingEvents';
import { Department } from './department';
import { Room } from './room';
import { RoomBasic } from './roomBasic';
import { RoomRelation } from './roomRelation';
import { RoomRelations } from './roomRelations';

export class Rooms extends BaseMasterItems {

    selectedRoom: Room;
    filteredRooms: Array<Room>;

    public loadFromMasterDataService(masterDataService: MasterDataService){
        masterDataService.rooms.items.forEach((item: Room) => {
            this.items.push(item);
        });
    }

    public initSelectedRoom(room?: Room){
        this.selectedRoom = new Room();

        if(room){
            this.items.forEach((r: Room) => {
                if(r.id == room.id){
                    this.selectedRoom = r;
                }
            });
        }
    }
    
    public loadExtraData(departments, articles, Monitors, eventTypes){
        this.items.forEach(room => {
            room.loadExtraData(departments, articles, Monitors, eventTypes);
        });
    }
    
    // can get department or string
    filterByDepartment(department){
        if (department == 'All') {
            this.filteredRooms = this.items;
          } else {
            this.filteredRooms = this.items.filter(
              (room) => room.department.id == department.id
            );
          }
        console.log("--------------------------------filtered rooms by department----------------------------");
    }

    public deleteRoom(roomId: number){
        for (let i = 0; i < this.items.length; i++) {
            if(this.items[i].id == roomId){
                this.items.splice(i, 1);
            }
        }
    }

    public removeRoomAtIndex(index: number){
        this.items.splice(index, 1);
    }

    public addRoomToItems(room: Room){
        this.items.unshift(room);
    }


}
