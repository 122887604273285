import { MasterDataService } from 'src/app/services/MasterData.service';

export class BaseMasterItem {

    id: any = -1;
    title: string = "";
    Sorting: number = -1;

    constructor() {
        
    }

    public loadFromJson(json: any): void {
        //json.id = undefined ? this.id = undefined : this.id = json.id;
        this.id = json.id;
        this.title = json.title;
        this.Sorting = json.sorting;
    }
}
