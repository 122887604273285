import { ChangeDetectorRef, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaitingScreenService {

  constructor() { }

  // public _showWaiting:Subject<boolean> = new BehaviorSubject(false);

  private _showWaiting : boolean = false;

  public get showWaiting() : boolean {
    return this._showWaiting;
  }
  public set showWaiting(v : boolean) {
    this._showWaiting = v;
  }

  public deactivate(){
    //console.log("DEACTIVATING THE WAITING SCREEN DISPLAY");

    setTimeout(() => this._showWaiting = false);
  }

  public activate(){
    //console.log("ACTIVATING THE WAITING SCREEN DISPLAY");

    setTimeout(() => this._showWaiting = true);
  }

}
