import { AppComponent } from './app.component';
import { CalendarComponentOld } from './core/components/calendar-old/calendar-old.component';
import { DateTimeSelectComponent } from './core/components/utilities/dateTimeSelect/dateTimeSelect.component';
import { RoomDisplayComponent } from './core/components/roomDisplay/roomDisplay.component';
import { FieldErrorDisplayComponent } from './core/components/utilities/field-error-display/field-error-display.component';
import { ListBookingsComponent } from './core/components/list-bookings/list-bookings.component';
import { EditEventComponent } from './core/components/edit-event/edit-event.component';
import { BookingComponent } from './core/components/booking/booking.component';
import { EventComponent } from './core/components/booking/event/event.component';
import { SeriesComponent } from './core/components/series/series.component';
import { FailedComponent } from './core/components/failed/failed.component';
import { DashboardOldComponent } from './core/components/dashboard-old/dashboard-old.component';
import { DashboardComponent } from './core/components/dashboard/dashboard.component';
import { NavigationComponent } from './core/components/navigation/navigation.component';
import { AdministratorComponent } from './core/components/administrator/administrator.component';
import { ReportingComponent } from './core/components/reporting/reporting.component';
import { NearbyEventsComponent } from './core/components/nearby-events/nearby-events.component';
import { JanitorComponent } from './core/components/janitor/janitor.component';
import { CateringComponent } from './core/components/catering/catering.component';
import { PeoplePickerComponent } from './core/components/people-picker/people-picker.component';

import { MasterDataService } from './services/MasterData.service';
import { SharepointService } from './services/sharepoint.service';
import { SignalRService } from './services/signalR.service';
import { InitializationService } from './services/initialization.service';
import { BookingEventsService } from './services/BookingEvents.service';
import { BookingService } from './services/booking.service';
import { DataService } from './services/data.service';
import { SqlService } from './services/Sql.service';
import { EventFormFilterService } from './services/eventFormFilter.service';
import { ConfigurationService } from './services/Configuration.service';
import { AllServicesService } from './services/AllServices.service';
import { WaitingScreenService } from './services/waitingScreen.service'
import { EmailService } from './services/Email.service'
import { AzureAdService } from './services/AzureAd.service'
import { RoomService } from './services/Room.service'
import { DepartmentService } from './services/Department.service';
import { ReportingService } from './services/Reporting.service';
import { RootService } from './services/Root.service';
import { UserService } from './services/User.service';
import { EquipmentService } from './services/Equipment.service';
import { CateringService } from './services/Catering.service';
import { AdUserGroupUserRoleService } from './services/AdUserGroupUserRole.service';
import { JanitorService } from './services/Janitor.service';
import { CatererService } from './services/Caterer.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material/material.module'
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { NgModule } from '@angular/core';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalModule, MsalService, MSAL_INSTANCE, MsalInterceptorConfiguration, MsalGuardConfiguration, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor  } from "@azure/msal-angular";
import { environment } from 'src/environments/environment';

import { appRoutes } from './routes'
import { DateStringPipe } from './shared/pipes/date-string.pipe';
import { TimeStringPipe } from './shared/pipes/time-string.pipe';
import { DateTimeStringPipe } from './shared/pipes/date-time-string.pipe';
import { BoolToYesNoPipe } from './shared/pipes/bool-to-yes-no.pipe';
import { CalendarComponent } from './core/components/calendar/calendar.component';
import { InteractiveCalendarComponent } from './core/components/interactive-calendar/interactive-calendar.component';
import { NgRepeatPipe } from './shared/pipes/ng-repeat.pipe';
import { EventCalendarOverviewComponent } from './core/components/event-calendar-overview/event-calendar-overview.component';
import { ToHourPipe } from './shared/pipes/to-hour.pipe';
import { LongDateStringPipe } from './shared/pipes/long-date-string.pipe';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: `https://login.microsoftonline.com/${environment.tenantName}`,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl, [`api://af5d2b81-1071-4186-b7f7-0e602f0c4e3b/user_impersonation`, "user.readbasic.all", 'SMTP.Send']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [`api://af5d2b81-1071-4186-b7f7-0e602f0c4e3b/user_impersonation`, "user.readbasic.all", 'SMTP.Send']
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    CalendarComponentOld,
    EditEventComponent,
    ListBookingsComponent,
    DateTimeSelectComponent,
    FieldErrorDisplayComponent,
    RoomDisplayComponent,
    BookingComponent,
    EventComponent,
    SeriesComponent,
    FailedComponent,
    DashboardOldComponent,
    AdministratorComponent,
    NavigationComponent,
    DashboardComponent,
    ReportingComponent,
    DateStringPipe,
    TimeStringPipe,
    DateTimeStringPipe,
    BoolToYesNoPipe,
    CalendarComponent,
    InteractiveCalendarComponent,
    NgRepeatPipe,
    EventCalendarOverviewComponent,
    ToHourPipe,
    LongDateStringPipe,
    NearbyEventsComponent,
    JanitorComponent,
    CateringComponent,
    PeoplePickerComponent,
   ],
  imports: [
    NgxChartsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { useHash: true, onSameUrlNavigation: 'reload' }),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MsalModule,
    MaterialModule,
    // GoogleMapsModule
  ],
  providers: [
    AllServicesService,
    BookingService,
    BookingEventsService,
    EmailService,
    DataService,
    InitializationService,
    MasterDataService,
    SharepointService,
    SignalRService,
    SqlService,
    EventFormFilterService,
    ConfigurationService,
    WaitingScreenService,
    AzureAdService,
    RoomService,
    DepartmentService,
    ReportingService,
    RootService,
    UserService,
    EquipmentService,
    CateringService,
    AdUserGroupUserRoleService,
    JanitorService,
    CatererService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }