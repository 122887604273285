import { Injectable } from '@angular/core';
import { Break } from '../shared/models/break';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {

    data: Array<{id:number, value: string, title: string}>;
    
    constructor(private dataService: DataService){

    }

    public loadConfigurationData(){
        //console.log("LOAD ADMIN DATA");

        this.dataService.getItems('configuration').subscribe((d: Array<any>) => {
            this.data = d;
        });
    }

    public get(t: string): {id:number, value: string, title: string}{
        let adminItemToReturn = undefined
        console.log(t);
        
        this.data.forEach(d => {
            if(d.title == t){
                adminItemToReturn = d;
            }
        })
        
        return adminItemToReturn;
    }
    
    public getAll(): Array<{id:number, value: string, title: string}>{
        return this.data;
    }

}
