import { HttpClient, HttpHeaders } from '@angular/common/http';
import { stringify } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { concat, Observable } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BookingEvent } from '../shared/models/BookingEvent';
import { Email } from '../shared/models/email';
import { User } from '../shared/models/User/User';
import { Users } from '../shared/models/User/Users';
import { AzureAdService } from './AzureAd.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

    constructor(
        private http: HttpClient,
        private msalService: MsalService,
        private azureAdService: AzureAdService
    ) { }

    url: string = environment.apiUrl;

    public sendUsersBookingEventInvitations(recievingUsers: Users, bookingEvent: BookingEvent):Observable<any>{
        console.log("send user booking event invitation");
        console.log("THIS DOESNT USE DATA SERVICE OR SQL SERVICE. CALLS SERVER DIRECTLY.");
        
        let user = new User();
        user.loadFromGraphAdAccount(this.msalService.instance.getActiveAccount());

        // filter the recieving users
        recievingUsers.removeUsersWhereSendInviteIsFalse(); 
        
        return this.msalService.acquireTokenSilent({
            scopes: ['https://outlook.office.com/SMTP.Send']
        }).pipe(mergeMap(res => {
            let email: Email = new Email();
            let subject = `Invitation to: ` + bookingEvent.title;
            let message = `you have been invited to a new booking event`
            email.setProperties(user, recievingUsers, subject, message, res);

            console.log(email);
            return this.http.post(`${this.url}/mail/sendUsersBookingEventInvitations`, email).pipe(map(e => {
                console.log(e);
            }));
        }))
    }

    // sender user, reciever user, booking event, 
    public requestInvitationForBookingEvent(bookingEvent: BookingEvent): Observable<any> {
        // creating  the email
        // user, recievingUsers, bookingEvent, res
        let email: Email = new Email();
        
        // get the current user 
        let user = new User();
        user.loadFromGraphAdAccount(this.msalService.instance.getActiveAccount());
        
        // get the recieving users
        let recievingUsers = new Users();
        // this should be changed to get the booking events  

        // let c1 = this.azureAdService.getUserByUserAdId(bookingEvent.creatorAdId).pipe(map(res => {
        //     recievingUsers.items.push(user);
        //     recievingUsers.setFilteredUsers();
        // }));

        let c1 = this.azureAdService.getCurrentUser().pipe(map(res => {
            console.log("update for production - should use the method above to get booking event created user");
            recievingUsers.items.push(res);
            recievingUsers.setFilteredUsers();
        }))

        // get use email auth token
        let authenticationResult: AuthenticationResult;
        let c2 = this.msalService.acquireTokenSilent({
            scopes: ["https://outlook.office.com/SMTP.send"]
        }).pipe(map(res => {
            authenticationResult = res;
            let subject = `Invitation Request for: ` + bookingEvent.title;
            let message = `${user.firstName} is requesting and invitation to attend booking event: ${bookingEvent.title} ${environment.hostUrl}#/bookingEvent;id=11;edit=false`

            // create email
            email.setProperties(user, recievingUsers, subject, message, authenticationResult)
            console.log(email);
        }))
        
        // send email
        let c3 = this.http.post(`${this.url}/mail/sendUserBookingEventRequest`, email).pipe(map(e => {

        }))
        
        // concat
        return concat( c1, c2, c3 );
    }
    
}
