import { BaseMasterItems } from './BaseMasterItems';
import { BookingEvent } from './BookingEvent';
import { BookingEventBasic } from './BookingEventBasic';
import { BookingEventForm } from './BookingEventForm';
import { Session } from './session';

export class BookingEvents extends BaseMasterItems {

    loadBookings(bookings){
        this.items.forEach(i => i.loadBooking(bookings));
    }

    loadExtraData(masterData){
        this.items.forEach(article => {
            article.loadExtraData(masterData)
        });
    }

    loadFromBookingEventForm(form: BookingEventForm){
        console.log("-------------- LOAD FROM BOOKING EVENT FORM --------------");
        console.log(form);

        form.sessions.items.forEach((session: Session) => {
            let bookingEvent = new BookingEventBasic();

            bookingEvent.id = form.id;
            bookingEvent.title = form.title;
            bookingEvent.department = form.departments.selectedDepartment;
            bookingEvent.room = form.rooms.selectedRoom;
            bookingEvent.maximumAttendees = form.maximumAttendees;
            bookingEvent.breaks = session.breaks;
            bookingEvent.equipment = session.equipments.selectedEquipment;

            bookingEvent.setTimesFromBookingEventForm(session);

            this.items.push(bookingEvent);
        });

        console.log(this.items);
    }

    calculateBookingEventsDisplay() {
        let colorIndexCounter = 0;

        let colors = [
            '#005c9ed}',
            '#78c039d8',
            '#009e02d8',
            '#9e007fd8',
            '#007d9ed8'
        ]

        this.items.forEach((bookingEvent: BookingEvent) => {

            // calculate color
            if(colorIndexCounter < 4){
                colorIndexCounter = colorIndexCounter +1
            } else {
                colorIndexCounter = 0;
            }
            bookingEvent.displayColor = colors[colorIndexCounter];

            // calculate length/ width
            let s1 = bookingEvent.startTime.getHours();
            let s2 = bookingEvent.startTime.getMinutes();
            let s3 = (s1 * 60 + s2)
    
            let e1 = bookingEvent.endTime.getHours();
            let e2 = bookingEvent.endTime.getMinutes();
            let e3 = (e1 * 60 + e2);
    
            let w1 = e3 - s3;
            let l1 = s3 - 360;
    
            let totalWidth = 1080;
    
            bookingEvent.width = (100 * w1) / totalWidth;
            bookingEvent.left = (100 * l1) / totalWidth;

        })
    }

    setUserRelations(relation: string){
        this.items.forEach((bookingEvent: BookingEvent) => {
            if(relation == "created"){
                bookingEvent.userRelations.created = true;
            }
            if(relation == "attending"){
                bookingEvent.userRelations.attending = true;
            }
        })
    }

    public getByRoomId(roomId: number): BookingEvents{
        let bookingEvents = new BookingEvents();

        this.items.forEach((b: BookingEvent) => {
            if(b.room.id  == roomId){
                bookingEvents.items.push(b);
            }
        });
        
        return bookingEvents;
    }
    
    public getByMonth(month: number): BookingEvents{
        let bookingEvents = new BookingEvents();
        
        this.items.forEach((b: BookingEvent) => {
            if(b.eventDate.getMonth() == month){
                bookingEvents.items.push(b);
            }
        });
        
        return bookingEvents;
    }

    public orderByStartTimeAscending(){
        console.log("ORDER BY START TIME ASCENDING");
        let orderedBookingEvents = new Array<BookingEvent>();
        let bookingEventCount = this.items.length;

        for (let i = 0; i < bookingEventCount; i++) {
            let eventToAdd = this.items[i];

            if(i == 0){
                orderedBookingEvents.push(eventToAdd);
                continue;
            }

            let orderedBookingEventsCount = orderedBookingEvents.length;

            for (let j = 0; j < orderedBookingEventsCount; j++) {
                // insert before first higher dateTime
                if(orderedBookingEvents[j].startTime > eventToAdd.startTime){
                    orderedBookingEvents.splice(j, 0, eventToAdd);
                    break;
                }
                // insert if it is last element
                if(j == orderedBookingEventsCount -1){
                    orderedBookingEvents.push(eventToAdd);
                    break;
                }
            }
            
        }

        this.items = orderedBookingEvents;
    }


}

