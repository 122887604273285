import { BaseMasterItem } from './BaseMasterItem';
import { MasterDataService } from 'src/app/services/MasterData.service';

export class Monitor extends BaseMasterItem {
    
    url:string;
    preview:string;

    loadFromJson(jsonItem){
        super.loadFromJson(jsonItem);
        this.url = jsonItem.url,
        this.preview = jsonItem.preview
    }
}
