import { Injectable, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { map } from 'rxjs/operators';
import { BookingEvents } from '../shared/models/BookingEvents';
import { BookingEvent } from '../shared/models/BookingEvent';
import { Observable } from 'rxjs';
import { MasterDataService } from './MasterData.service';
import { DataService } from './data.service';
import { BookingService } from './booking.service';
import { AllServicesService } from './AllServices.service';
import { InitializationService } from './initialization.service';
import {User} from '../shared/models/User/User';
import { Email } from '../shared/models/email';
import { BookingEventForm } from '../shared/models/BookingEventForm';
import { SqlService } from './Sql.service';
import { BookingEventFormFilterReturn } from '../shared/models/BookingEventFormFilterReturn';

@Injectable({
  providedIn: 'root'
})
export class BookingEventsService {

  constructor(
    private dataService: DataService,
    private bookingService: BookingService,
    private sqlService: SqlService,
    ) { }
    
  // ---------------------------------- GET BOOKING EVENTS ------------------------------------------
  public getAllBookingEvents(): Observable<BookingEvents>{
    return this.dataService.getAllBookingEvents().pipe(map(data => {
      let bookingEvents = new BookingEvents();
      bookingEvents.loadFromJson(BookingEvent, data);
      
      return bookingEvents;
    }));
  }
  public getBookingEventById(id: number): Observable<BookingEvent>{
    return this.dataService.getItemById("BookingEvents", id).pipe(map(data => {
      
      console.log("------------- get booking event by id -----------------------")
      
      let bookingEvent = new BookingEvent();
      console.log(data);
      bookingEvent.loadFromJson(data);
      return bookingEvent;
    }))
  }
  
  public getFormBookingEventById(id: number): Observable<BookingEvent>{
    return this.dataService.getItemById("BookingEvents", id).pipe(map(data => {
      
      console.log("------------- get form booking event by id -----------------------")
      
      let bookingEvent = new BookingEvent();
      console.log(data);
      bookingEvent.loadFromJson(data);
      return bookingEvent;
    }))
  }
  
  public getCurrentUsersFutureBookingEvents(userId: string): Observable<BookingEvents>{
    return this.dataService.getCurrentUsersFutureBookingEvents(userId).pipe(map(data => {
      let bookingEvents = new BookingEvents();
      bookingEvents.loadFromJson(BookingEvent, data);

      // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
      // console.log(data);
      // console.log(bookingEvents);
      // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")

      return bookingEvents;
    }))
  }

  public getCurrentUsersFutureBookingEventsAttending(userId: string, todaysDate: Date): Observable<BookingEvents>{
    return this.dataService.getCurrentUsersFutureBookingEventsAttending(userId, todaysDate).pipe(map(data => {
      let bookingEvents = new BookingEvents();
      
      bookingEvents.loadFromJson(BookingEvent, data);
      
      return bookingEvents;
    }))
  }
    
  public getBookingEventsByDay(date: Date): Observable<BookingEvents>{
    return this.dataService.getItemsByDate("bookingEvents", date).pipe(map((data) => {
      let bookingEvents = new BookingEvents();

      bookingEvents.loadFromJson(BookingEvent, data);


      return bookingEvents;
    }));
  }

  public getJanitorsBookingEventsByDate(userId: string, date: Date): Observable<BookingEvents>{
    return this.dataService.getByDate_IdString('bookingEvents', 'getJanitors', date, userId).pipe(map((bookingEventData: any) => {
      let bookingEvents = new BookingEvents();
      bookingEvents.loadFromJson(BookingEvent, bookingEventData.bookingEvents);
      
      return bookingEvents;
    }))
  }
  
  public getCaterersBookingEventsByDate(userId: string, date: Date): Observable<BookingEvents>{
    return this.dataService.getByDate_IdString('bookingEvents', 'getCaterers', date, userId).pipe(map((bookingEventData: any) => {
      let bookingEvents = new BookingEvents();
      bookingEvents.loadFromJson(BookingEvent, bookingEventData.bookingEvents);

      return bookingEvents;
    }))
  }
    
  public getBookingEventsByBookingId(id){
    return this.dataService.getBookingEventsByBookingId(id).pipe(map((data: any) => {
      let bookingEvents = new BookingEvents();
      console.log(data);

      bookingEvents.loadFromJson(BookingEvent, data);
      
      return bookingEvents;
    }))
  }

  public getBookingEventsByRoom(id: number){
    return this.dataService.getBookingEventsByRoom(id).pipe(map((data: any) => {
      let bookingEvents = new BookingEvents();
      console.log(data);

      bookingEvents.loadFromJson(BookingEvent, data);
      
      return bookingEvents;
    }))
  }

  // ----------------------------- CREATE BOOKING EVENT ---------------------------------------------
  public createNewEvent(event): Observable<BookingEvents>{
    return this.dataService.createNewItem('BookingEvents', event).pipe(map(eventData => {
      let bookingEvents = new BookingEvents();
      bookingEvents.loadFromJson(BookingEvent, eventData)

      return bookingEvents;
    }))

  }

  // ----------------------------- UPDATE BOOKING EVENT ------------------------------------------
  public updateBookingEventById(event): Observable<Event>{
    return this.dataService.updateItem('BookingEvents', event);
  }

  // ----------------------------- DELETE BOOKING EVENT -------------------------------------
  public deleteBookingEventByRoomId(id): Observable<any> {
    return this.dataService.deleteItem("BookingEvents", id, "BookingId");
  }

  public deleteBookingEventById(id) {
    return this.dataService.deleteItem('BookingEvents', id);
  }

  // --------------------------- BOOKING EVENT ATTENDANCE -------------------------------------
  public addAttendance(bookingEventId: number, userId: string): Observable<any> {
    return this.dataService.addAttendance(bookingEventId, userId);
  }

  public deleteAttendance(bookingEventId: number, userId: string): Observable<any> {
    return this.dataService.deleteAttendance(bookingEventId, userId);
  }


  // ---------------------------- HELPER METHODS ----------------------------------------------
  private getUniqueBookingIds(data){
    let uniqueBookingIds = []
    data.forEach(d => {
        if(uniqueBookingIds.includes(d.BookingId))
          return
          uniqueBookingIds.push(d.BookingId);
    });
    return uniqueBookingIds;
  }

  // --------------------------- FILTER BOOKING EVENT FORM --------------------------------------
  public filterBookingEventForm(bookingEventForm: BookingEventForm): Observable<BookingEventFormFilterReturn>{
    console.log("--- booking event form ---")
    console.log(bookingEventForm)
    console.log("--- booking event form ---")
    return this.sqlService.filter("BookingEvents", "filterBookingEventFormData", bookingEventForm).pipe(map((filterData: BookingEventFormFilterReturn) => {
      return filterData;
    }))
  }

}
