<div class="flex-inner">
  <div class="d-flex flex-column wrapper">
    <mat-toolbar color="primary" class="d-flex">
      <h1 class="mat-h1">Welcome to your Dashboard</h1>
      <span class="flex-spacer"></span>
      <button mat-icon-button type="button"><mat-icon></mat-icon></button>
    </mat-toolbar>
    <div class="grid-outter">
      <div class="grid">
        <div class="grid-item grid-top">
          <mat-card class="overflow-auto">
            <mat-card-header>
              <mat-card-title>Your Events</mat-card-title>
              <mat-card-subtitle>Events you have created that are upcoming</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div class="d-block p-relative overflowy-auto w-100 maxh-100">

                <table mat-table [dataSource]="data" class="w-100" style="min-width: 1000px;">
    
                  <!-- Date Column -->
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let element">{{ element.date | dateTimeString }}</td>
                  </ng-container>
    
                  <!-- Title Column -->
                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>Title</th>
                    <td mat-cell *matCellDef="let element">{{ element.title }}</td>
                  </ng-container>
    
                  <!-- Location Column -->
                  <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef>Location</th>
                    <td mat-cell *matCellDef="let element">{{ element.location }}</td>
                  </ng-container>
    
                  <!-- Attendees Column -->
                  <ng-container matColumnDef="attendees">
                    <th mat-header-cell *matHeaderCellDef>Estimated Attendees</th>
                    <td mat-cell *matCellDef="let element">{{ element.attendees }}</td>
                  </ng-container>
  
                  <!-- Catering Column -->
                  <ng-container matColumnDef="catering">
                    <th mat-header-cell *matHeaderCellDef>Catering</th>
                    <td mat-cell *matCellDef="let element">{{ element.catering | boolToYesNo }}</td>
                  </ng-container>
  
                  <!-- Video Column -->
                  <ng-container matColumnDef="video">
                    <th mat-header-cell *matHeaderCellDef>Conferencing</th>
                    <td mat-cell *matCellDef="let element">{{ element.video | boolToYesNo }}</td>
                  </ng-container>
    
                  <ng-container matColumnDef="options">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                      <button type="button" mat-icon-button color="accent" matTooltip="View Event"><mat-icon>launch</mat-icon></button>
                      <button type="button" mat-icon-button color="warn" matTooltip="Cancel Event"><mat-icon>cancel</mat-icon></button>
                    </td>
                  </ng-container>
    
                  <tr mat-header-row *matHeaderRowDef="['date', 'title', 'location', 'attendees', 'catering', 'video', 'options']"></tr>
                  <tr mat-row *matRowDef="let row; columns: ['date', 'title', 'location', 'attendees', 'catering', 'video', 'options'];"></tr>
    
                </table>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
  
        <div class="grid-item grid-bottom-left">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Event Categories</mat-card-title>
              <mat-card-subtitle>Categories of events you have created/attended over 12 months</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div #pieWrapper class="wrapper">
                <ngx-charts-pie-chart class="chart-item" [view]="pieChartDimensions" [results]="pieData" [gradient]="false"
                  [legend]="false" [legendPosition]="'below'" [labels]="true" [doughnut]="true" [scheme]="colorScheme">
                </ngx-charts-pie-chart>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="grid-item grid-bottom-right">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Events per Week</mat-card-title>
              <mat-card-subtitle>Your event hours for the past 10 weeks</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div #barWrapper class="wrapper">
                <ngx-charts-bar-vertical
                  class="chart-item"
                  [view]="barChartDimensions"
                  [scheme]="colorScheme"
                  [results]="barData"
                  [gradient]="false"
                  [xAxis]="true"
                  [yAxis]="true"
                  [legend]="false"
                  [showXAxisLabel]="true"
                  [showYAxisLabel]="true"
                  [xAxisLabel]="'Past 10 weeks'"
                  [yAxisLabel]="'Events per Week'">
                </ngx-charts-bar-vertical>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
  
        <div class="grid-item grid-right">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Upcoming Events</mat-card-title>
              <mat-card-subtitle class="m-0">Your attending events over the next 7 days</mat-card-subtitle>
            </mat-card-header>
            <!-- <mat-divider></mat-divider> -->
            <mat-card-content>
              <mat-list>
                <div mat-subheader>Wednesday (<strong>Today</strong>)</div>
                <div class="text-center w-100">
                  <p class="text-muted"><small>You have no events.</small></p>
                </div>
                <div mat-subheader>Thursday (<strong>Tomorrow</strong>)</div>
                <mat-list-item style="color: #666">
                  <mat-icon mat-list-icon>meeting_room</mat-icon>
                  <div mat-line>Project Meeting (DMT)</div>
                  <div mat-line>11:00am - Room 2A</div>
                  <button type="button" mat-icon-button color="primary" matTooltip="View Event"><mat-icon>launch</mat-icon></button>
                  <button type="button" mat-icon-button color="warn" matTooltip="Cancel Event"><mat-icon>cancel</mat-icon></button>
                </mat-list-item>
                <div mat-subheader>Friday</div>
                <div class="text-center w-100">
                  <p class="text-muted"><small>You have no events.</small></p>
                </div>
                <div mat-subheader>Saturday</div>
                <div class="text-center w-100">
                  <p class="text-muted"><small>You have no events.</small></p>
                </div>
                <div mat-subheader>Sunday</div>
                <div class="text-center w-100">
                  <p class="text-muted"><small>You have no events.</small></p>
                </div>
                <div mat-subheader>Monday</div>
                <mat-list-item style="color: #666">
                  <mat-icon mat-list-icon>savings</mat-icon>
                  <div mat-line>Fundraising Event</div>
                  <div mat-line>12:00pm - Room 6A & 6B</div>
                  <button type="button" mat-icon-button color="primary" matTooltip="View Event"><mat-icon>launch</mat-icon></button>
                  <button type="button" mat-icon-button class="green" matTooltip="RSVP"><mat-icon>free_cancellation</mat-icon></button>
                </mat-list-item>
                <mat-list-item style="color: #666">
                  <mat-icon mat-list-icon>meeting_room</mat-icon>
                  <div mat-line>Project Meeting (DMT)</div>
                  <div mat-line>2:00pm - Room 2A</div>
                  <button type="button" mat-icon-button color="primary" matTooltip="View Event"><mat-icon>launch</mat-icon></button>
                  <button type="button" mat-icon-button color="warn" matTooltip="Cancel Event"><mat-icon>cancel</mat-icon></button>
                </mat-list-item>
                <div mat-subheader>Tuesday</div>
                <div class="text-center w-100">
                  <p class="text-muted"><small>You have no events.</small></p>
                </div>
              </mat-list>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>


