import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserService } from 'src/app/services/User.service';
import { Users } from 'src/app/shared/models/User/Users';

@Component({
  selector: 'app-people-picker',
  templateUrl: './people-picker.component.html',
  styleUrls: ['./people-picker.component.scss']
})
export class PeoplePickerComponent implements OnInit {

  peoplePickerInput: string;
  users: Users;
  groupsPickerInput: string;
  groups;

  constructor(
    private userService: UserService,
    private msalService: MsalService,
  ) { }

  ngOnInit() {
    
  }

  public changed_peoplePickerInput(){
    if(this.peoplePickerInput == ""){
      this.peoplePickerInput == null;
    }

    this.userService.getAllUsersStartsWith(this.peoplePickerInput).subscribe((res) => {
      this.users = res;
    });
  }

  public click_getAllCurrentUsersDetails(){
    let userId = this.msalService.instance.getActiveAccount().localAccountId;
    this.userService.getUserById(userId).subscribe(res => {
      console.log(res);
    })
  }

  public click_getAllAdGroups(){
    console.log ("click_getAllAdGroups")
    
    this.userService.getAllUserGroups().subscribe(res => {
      console.log(res);
    });
  }


}
