<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #drawer id="side-nav" class="sidenav" [fixedInViewport]="false"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar class="d-flex justify-content-end">
      <button mat-icon-button type="button" *ngIf="(isHandset$ | async)" (click)="drawer.close()"><mat-icon>close</mat-icon></button>
      <button mat-icon-button type="button" *ngIf="!(isHandset$ | async) && expanded" (click)="toggleExpand(false)"><mat-icon>chevron_left</mat-icon></button>
      <button mat-icon-button type="button" *ngIf="!(isHandset$ | async) && !expanded" (click)="toggleExpand(true)"><mat-icon>chevron_right</mat-icon></button>
    </mat-toolbar>
    <mat-nav-list [ngClass]="{'center-children hide-spans': !(isHandset$ | async) && !expanded}">
      <a mat-list-item *ngIf="allowItemOfType('User')" [routerLink]="['/dashboard']" matTooltipPosition="right" matTooltipShowDelay="1000" matTooltip="Dashboard">
        <mat-icon class="accent-dark">dashboard</mat-icon>
        <span class="ml-2">Dashboard</span>
      </a>
      <a mat-list-item *ngIf="allowItemOfType('User')" [routerLink]="['/calendar']" matTooltipPosition="right" matTooltipShowDelay="1000" matTooltip="Calendar">
        <mat-icon class="accent-dark">event</mat-icon>
        <span class="ml-2">Calendar</span>
      </a>
      <a mat-list-item *ngIf="allowItemOfType('User')" [routerLink]="['/booking']" matTooltipPosition="right" matTooltipShowDelay="1000" matTooltip="Create Event/Booking">
        <mat-icon class="accent-dark">edit_calendar</mat-icon>
        <span class="ml-2">Create Event/Booking</span>
      </a>
      <a mat-list-item *ngIf="allowItemOfType('User')" [routerLink]="['/events']" matTooltipPosition="right" matTooltipShowDelay="1000" matTooltip="Events Nearby">
        <mat-icon class="accent-dark">format_list_bulleted</mat-icon>
        <span class="ml-2">Events Nearby</span>
      </a>
      <a mat-list-item *ngIf="allowItemOfType('TennantAdministrator')" [routerLink]="['/reporting']" matTooltipPosition="right" matTooltipShowDelay="1000" matTooltip="Financial Reporting">
        <mat-icon class="accent-dark">account_balance</mat-icon>
        <span class="ml-2">Financial Reporting</span>
      </a>
      <a mat-list-item [routerLink]="['/roomDisplay']" matTooltipPosition="right" matTooltipShowDelay="1000" matTooltip="Room Display">
        <mat-icon class="accent-dark">monitor</mat-icon>
        <span class="ml-2">Room Display</span>
      </a>
      <a mat-list-item *ngIf="allowItemOfType('TennantAdministrator')" [routerLink]="['/administrator']" matTooltipPosition="right" matTooltipShowDelay="1000" matTooltip="Administration">
        <mat-icon class="accent-dark">admin_panel_settings</mat-icon>
        <span class="ml-2">Administration</span>
      </a>
      <a mat-list-item *ngIf="allowItemOfType('Caterer')" [routerLink]="['/catering']" matTooltipPosition="right" matTooltipShowDelay="1000" matTooltip="Catering">
        <mat-icon class="accent-dark">lunch_dining</mat-icon>
        <span class="ml-2">Catering</span>
      </a>
      <a mat-list-item *ngIf="allowItemOfType('Janitor')" [routerLink]="['/janitor']" matTooltipPosition="right" matTooltipShowDelay="1000" matTooltip="Janitor">
        <mat-icon class="accent-dark">cleaning_services</mat-icon>
        <span class="ml-2">Janitor</span>
      </a>
    </mat-nav-list>
    <div *ngIf="(isHandset$ | async) || expanded" class="p-absolute bottom-0 text-muted w-100 text-center">
      <p class="m-0">Roomable</p>
      <p class="m-0"><small>v4.1.7</small></p>
      <p class="mt-0"><small>Pack of 7</small></p>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="d-flex flex-column">
    <!-- Navigation -->
    <mat-toolbar class="main-navigation" class="d-flex bg-white shadow-xsmall">
      <button
        class="mr-3"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <img src="./assets/images/roomable_logo.png" alt="Logo" style="height: 60%;" />
      <span class="flex-spacer" style="width: 100%"></span>

      <div *ngIf="currentUser!=null">
        <small *ngIf="!(isHandset$ | async)">{{currentUser.firstName}}</small>
      </div>

      <button type="menu" mat-icon-button color="accent" [matMenuTriggerFor]="userMenu"><mat-icon>account_circle</mat-icon></button>
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item><mat-icon class="mr-1">manage_accounts</mat-icon> Edit Profile</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()"><mat-icon class="mr-1">logout</mat-icon> Logout</button>
      </mat-menu>
    </mat-toolbar>
    <ng-content class="flex-grow"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
