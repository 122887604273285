import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BookingEventsService } from './BookingEvents.service';
import { DataService } from './data.service';
import { JanitorJobs } from '../shared/models/JanitorJobs';
import { map } from 'rxjs/operators';
import { BookingEvents } from '../shared/models/BookingEvents';

@Injectable({
  providedIn: 'root'
})
export class JanitorService {

  constructor(
    private dataService: DataService,
    private bookingEventsService: BookingEventsService,
  ) { }

  public getJanitorJobsByDate(userId: string, day: Date): Observable<JanitorJobs>{
    return this.bookingEventsService.getJanitorsBookingEventsByDate(userId, day).pipe(map((bookingEvents: BookingEvents) => {
        let janitorJobs = new JanitorJobs();
        janitorJobs.loadFromBookingEvents(bookingEvents.items);

        return janitorJobs;
      }));
  }

}
