<div clas="row" style="display: flex">
  <div class="col-md-6 pr-1 pl-0">
    <!-- Start Date -->
    <div id="start-box">
      <div *ngIf="showStartError">Start Date must be before end date</div>
      <div>
        <label class="control-label requried">Start Date:</label>
        <input
          autocomplete="off"
          id="startDate-picker"
          placeholder="Datepicker"
          class="form-control mb-3"
          #dp="bsDatepicker"
          placement="bottom left"
          bsDatepicker
          [(ngModel)]="startDate"
          (ngModelChange)="dateChanged()"
        />
      </div>

      <!-- Start Time -->
      <div>
        <label class="required">Start Time:</label>
        <div class="input-group mb-3">
          <select
            class="custom-select time-input-dropdown"
            aria-labelledby="dropdownMenuButton"
            [(ngModel)]="startTime.hours"
            (ngModelChange)="dateChanged()"
          >
            <option
              *ngFor="let hour of startDropDownHours"
              [disabled]="!hour.valid"
            >
              {{ hour.hour }}
            </option>
          </select>
          <select
            class="custom-select time-input-dropdown"
            [(ngModel)]="startTime.minutes"
            (ngModelChange)="dateChanged()"
          >
            <option *ngFor="let minute of minutes">{{ minute }}</option>
          </select>

          <div class="btn btn-warning ml-auto" (click)="refreshStartDateTime()">
            Refresh
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- End Date -->
  <div class="col-md-6 pr-0 pl-1">
    <div id="end-box">
      <div>
        <label for="endDate" class="control-label required">End Date:</label>
        <input
          value=""
          autocomplete="off"
          id="endDate-picker"
          placeholder="Datepicker"
          class="form-control mb-3"
          #dp="bsDatepicker"
          placement="bottom left"
          bsDatepicker
          [(ngModel)]="endDate"
          (ngModelChange)="dateChanged()"
        />
      </div>

      <!-- End Time -->
      <div>
        <label class="required">End Time:</label>
        <div class="input-group mb-3">
          <select
            class="custom-select time-input-dropdown"
            [(ngModel)]="endTime.hours"
            (ngModelChange)="dateChanged()"
          >
            <option *ngFor="let hour of hours">{{ hour }}</option>
          </select>
          <select
            class="custom-select time-input-dropdown"
            [(ngModel)]="endTime.minutes"
            (ngModelChange)="dateChanged()"
          >
            <option *ngFor="let minute of minutes">{{ minute }}</option>
          </select>

          <div class="btn btn-warning ml-auto" (click)="refreshEndDateTime()">
            Refresh
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-md-12 p-0">
  <div class="btn btn-danger mr-2" (click)="submitForm(0)">Cancel</div>
  <div class="btn btn-primary" (click)="submitForm(1)">Save</div>
</div>
