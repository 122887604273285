import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { url } from 'node:inspector';
import { cwd } from 'node:process';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppModule } from './app.module';
import { InitializationService } from './services/initialization.service';
import { UserService } from './services/User.service';
import { WaitingScreenService } from './services/waitingScreen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'ems';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
      @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
      private msalService: MsalService,
      public waitingScreenService: WaitingScreenService,
      private initializationService: InitializationService,
      private userService: UserService,
    )  {
  }

  ngOnInit(): void {
    console.log('ng on init is called');
    
    // IF USER IS LOGGED IN UPDATE 
    this.setLoginDisplay();
    this.checkAndSetActiveAccount();

    //console.log("login display: " + this.loginDisplay)

    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.msalService.instance.handleRedirectPromise().then(res => {
      if (res != null && res.account != null) {
        this.msalService.instance.setActiveAccount(res.account)
        this.loginDisplay = true;
        this.initializationService.init();
      }
    })

    this.waitingScreenService.deactivate();
  }


  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount(){
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      this.initializationService.init();
    }
  }

  login() {
    environment.redirectUrl = window.location.href;
    console.log("redirect url: " + environment.redirectUrl);
    this.msalService.loginRedirect();
  }

  logout() {
    //AppModule.redirectUrl = window.location.href;
    this.msalService.logout();
  }

  logMsal(){
    console.log(this.msalService);
  }

  ngOnDestroy(): void {
    console.log("--")
    console.log("destroying");
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
