<div class="wrapper" *ngIf="todaysJanitorJobs">
  <div style="padding-left: 10px; padding-top: 20px;">
    <mat-form-field appearance="fill">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" (ngModelChange)="change_selectedDate()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="grid-outer" *ngFor="let job of todaysJanitorJobs.items">
    <mat-card class="grid-inner">
      <div class="grid-right">
        <div>
          {{job.startTime | date:'h:mm a'}}
        </div>
        <div>
          {{job.startTime | date:'MMM d'}}
        </div>
      </div>
      <div class="grid-left">
        <div class="item">{{'Room: '}}</div>
        <div class="item">{{job.roomId}}</div>
        
        <div class="item">{{'End Time: '}}</div>
        <div class="item">{{job.endTime | date:'h:mm a'}}</div>

        <div class="item">{{'Seating Configuration: '}}</div>
        <div class="item">{{job.seating.title}}</div>
        
        <div class="item">{{'Number of Attendees: '}}</div>
        <div class="item">{{job.numberOfAttendees}}</div>
        
        <div class="item" *ngIf="job.equipment">{{'Equipment: '}}</div>
        <div class="item" *ngIf="job.equipment && job.equipment.items.length > 0">
          <div *ngFor="let equipment of job.equipment.items" style="padding-bottom: 5px;">
            {{equipment.title + " x " + equipment.quantity}}
          </div>
        </div>

      </div>
    </mat-card>
  </div>
</div>