import { BaseMasterItem } from './BaseMasterItem';
import { Rooms } from './rooms';
import { Room } from './room';
import { DepartmentForm } from './departmentForm';

export class DepartmentBasic extends BaseMasterItem{

  // UI
  ui: {
    displayEdit: boolean,
    displayRoom: boolean,
    displayDepartmentIntelligence: boolean
  } = {
    displayEdit: false,
    displayRoom: true,
    displayDepartmentIntelligence: false
  }

  // FORMS
  departmentForm: DepartmentForm;
  
  loadFromJson(jsonItem){
    super.loadFromJson(jsonItem);
  }

  // ---------------------------------------------------- LOADING DATA ----------------------------------------
  loadFromForm(departmentForm: DepartmentForm){
    this.id = departmentForm.id;
    this.title = departmentForm.title;
  }

  update(departmentData){
    this.id = departmentData.id;
    this.title = departmentData.title;
  }

  // ----------------------------------------------------- UI START -----------------------------------------------------------
//   public uiToggle(value: string){
//     console.log(value);
    
//     if(value == "displayEdit"){
//       this.ui.displayEdit = true;
//       this.ui.displayRoom = false;
//       this.ui.displayDepartmentIntelligence = false;
//     }
    
//     if(value == "displayRooms"){
//       this.ui.displayEdit = false;
//       this.ui.displayRoom = true;
//       this.ui.displayDepartmentIntelligence = false;
//     }
    
//     if(value == "displayDepartmentIntelligence"){
//       this.ui.displayEdit = false;
//       this.ui.displayRoom = false;
//       this.ui.displayDepartmentIntelligence = true;
//     }
    
    //console.log(this.ui);
    
//   }

//   public edit(){
//     this.uiToggle('displayEdit');
//     this.departmentForm = new DepartmentForm();
//     this.departmentForm.init(this);
//   }
  // ----------------------------------------------------- UI END -----------------------------------------------------------

  
}

