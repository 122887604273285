import { Component, OnInit } from '@angular/core';
import { timeStamp } from 'node:console';
import { map } from 'rxjs/operators';
import { AdUserGroupUserRoleService } from 'src/app/services/AdUserGroupUserRole.service';
import { CateringService } from 'src/app/services/Catering.service';
import { DepartmentService } from 'src/app/services/Department.service';
import { EquipmentService } from 'src/app/services/Equipment.service';
import { InitializationService } from 'src/app/services/initialization.service';
import { MasterDataService } from 'src/app/services/MasterData.service';
import { RoomService } from 'src/app/services/Room.service';
import { UserService } from 'src/app/services/User.service';
import { AdUserGroupUserRole } from 'src/app/shared/models/AdUserGroupUserRole';
import { Catering } from 'src/app/shared/models/Catering/Catering';
import { Caterings } from 'src/app/shared/models/Catering/Caterings';
import { Department } from 'src/app/shared/models/department';
import { Departments } from 'src/app/shared/models/departments';
import { Equipment } from 'src/app/shared/models/Equipment';
import { Equipments } from 'src/app/shared/models/Equipments';
import { Room } from 'src/app/shared/models/room';
import { RoomForm } from 'src/app/shared/models/roomForm';
import { User } from 'src/app/shared/models/User/User';
import { Users } from 'src/app/shared/models/User/Users';

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.scss']
})
export class AdministratorComponent implements OnInit {

  allDepartments: Departments;
  selectedDepartment: Department;
  departmentForm: Department;

  allEquipment: Equipments;
  equipmentForm: Equipment;

  allUsers: Users;
  allAdUserGroupUserRoles: Array<AdUserGroupUserRole>;

  allCatering: Caterings;
  cateringForm: Catering;

  roomForm: RoomForm;

  displayedColumns: string[] = ['1, 2'];
  departmentsTableColumns: string[] = ['title', 'options'];
  allAdUserGroupUserRolesTableColumns: string[] = ['adUserGroup', 'userRole', 'options'];
  // allUsersTableColumns: string[] = ['firstName', 'type', 'options'];
  equipmentsTableColumns: string[] = ['title', 'quantity', 'description', 'options'];
  cateringTableColumns: string[] = ['title', 'price', 'description', 'options'];

  uiDisplay = {
    departmentRooms: false,
    roomForm: false,
    createDepartment: false,
    equipment: false,
    equipmentCreationForm: false,
    departments: true,
    departmentCreationForm: false,
    catering: false,
    cateringCreationForm: false,
    manageAdUserGroupUserRole: false,
  }

  constructor(
    private masterDataService: MasterDataService,
    private initializationService: InitializationService,
    private departmentService: DepartmentService,
    private roomService: RoomService,
    private userService: UserService,
    private equipmentService: EquipmentService,
    private cateringService: CateringService,
    private adUserGroupUserRoleService: AdUserGroupUserRoleService
  ) { }

  ngOnInit() {
    console.log("administrator component - ng on init");

    this.initializationService.initializationComplete.subscribe((initializationComplete) => {
      if (initializationComplete == true) {
        this.initializationServiceComplete();
      }
    });

    // delete this
    this.roomService.getRoom(1).subscribe((room) => {

    })
  }

  private initializationServiceComplete(){
    this.getAllDepartments().subscribe();
  }
  
  private getAllDepartments(){
    return this.departmentService.getDepartments().pipe(map((departments: Departments) => {
      this.allDepartments = departments;
      return null;
    }));
  }
  
  private getAllEquipment(){
    this.equipmentService.getAllEquipment().subscribe((equipment: Equipments) => {
      console.log(equipment);
      this.allEquipment = equipment;
    });
  }

  private getAllUsers(){
    this.userService.getAllUsers().subscribe((users: Users) => {
      this.allUsers = users;
    });
  }

  private getAllCatering(){
    this.cateringService.getAllCatering().subscribe((catering: Caterings) => {
      this.allCatering = catering;
    });
  }
  
  // -------------------------------------------- UI ACTIONS START --------------------------------------------  
  // ------------- Rooms -------------
  public click_editRoom(room: Room){
    console.log("Click - edit room");
    this.initRoomForm(room);
    this.setActiveUiDisplay('roomForm');
  }
  
  public click_createNewRoom(){
    this.initRoomForm();
    this.setActiveUiDisplay('roomForm');
  }
  
  private initRoomForm(room?: Room){
    this.roomForm = new RoomForm();
    
    if(room){
      this.roomForm.initEdit(this.masterDataService, room, this.selectedDepartment);
    } else {
      let newRoom = new Room();
      this.roomForm.initEdit(this.masterDataService, newRoom, this.selectedDepartment);
    }
  }

  public click_cancelRoomForm(){
    this.setActiveUiDisplay('departmentRooms');
    this.roomForm = null;
  }
  
  public click_submitRoomForm(){
    if(this.roomForm.formIsPosting == true)
      return;

    this.roomForm.formIsPosting = true;
    this.saveRoom(this.roomForm);
  }

  private saveRoom(roomForm: RoomForm){
    let roomToPost = new Room();
    roomToPost.loadFromRoomForm(roomForm);

    if(roomForm.isNewRoom()){
      this.createNewRoom(roomToPost);
    } else {
      this.updateRoom(roomToPost);
    }
  }
  
  private createNewRoom(room: Room){
    this.roomService.createRoom(room).subscribe((returnedRoom: Room) => {
      this.allDepartments.addRoom(returnedRoom);
      this.roomForm.formIsPosting = false;
      this.setActiveUiDisplay('departmentRooms')
    });
  }
  
  private updateRoom(room: Room){
    this.roomService.updateRoom(room).subscribe((returnedRoom: Room) => {
      this.allDepartments.updateRoom(returnedRoom);
      this.roomForm.formIsPosting = false;
      this.setActiveUiDisplay('departmentRooms')
    });
  }

  public click_deleteRoom(room: Room){
    console.log("click delete room not impletemnted");
  }

  public click_logRoomForm(){
    console.log(this.roomForm);
  }

  public click_logRooms(){
    console.log(this.selectedDepartment.rooms);
  }

  // ------------- Departments -------------
  public click_toggleDepartment(department: Department){
    this.toggleSelectedDepartment(department);
    this.setActiveUiDisplay('departmentRooms');
  }
  
  public click_openDepartment(department: Department){
    this.toggleSelectedDepartment(department);
    this.setActiveUiDisplay("departmentRooms");
  }
  
  private toggleSelectedDepartment(department: Department){
    this.selectedDepartment = department;
  }

  public click_showDepartments(){
    this.setActiveUiDisplay('departments');
  }
  
  public click_toggleCreateDepartment(){
    console.log(" click toggle create department");
    this.initDepartmentForm();
    this.setActiveUiDisplay('departmentCreationForm');
  }

  public click_cancelCreateDepartment(){
    console.log("cancel create department");
    this.setActiveUiDisplay('departments');
    this.departmentForm = null;
  }
  
  public click_submitCreateDepartment(){
    console.log("submit create department");
    console.log(this.departmentForm);
    this.departmentService.createDepartment(this.departmentForm).subscribe((department: Department) => {
      this.allDepartments.items.push(department);
      this.allDepartments.triggerMaterialTableChangeDetection();
      this.setActiveUiDisplay('departments');
    });
  }

  public click_deleteDepartment(department: Department){
    console.log("click delete department");
    this.departmentService.deleteDepartment(department.id).subscribe(() => {
      this.allDepartments.removeItemById(department.id);
      this.allDepartments.triggerMaterialTableChangeDetection();
    })
  }

  // ------------- Users -------------
  public click_manageUsers(){
    this.setActiveUiDisplay('users')
    this.getAllUsers();
  }

  public click_submitCreateUser(user: User){
    if(user.id == -1){
      // create new user
    } else {
      this.updateUser(user).subscribe();
    }
  }

  private updateUser(user: User){
    return this.userService.updateUser(user);
  }

  // ------------- ManageAdUserGroupUserRole -------------
  public click_manageAdUserGroupUserRole(){
    console.log("click_manageAdUserGroupUserRole");

    this.setActiveUiDisplay('manageAdUserGroupUserRole')
    this.getAllAdUserGroupUserRoles();
  }

  private getAllAdUserGroupUserRoles(){
    this.adUserGroupUserRoleService.getAll().subscribe((userGroupUserRoles: Array<AdUserGroupUserRole>) => {
      this.allAdUserGroupUserRoles = userGroupUserRoles;
      console.log(this.allAdUserGroupUserRoles);
    });
  }

  public click_adUserGroupUserRoleEdit(element: AdUserGroupUserRole){
    element.edit();
  }

  public click_adUserGroupUserRoleEditCancel(element: AdUserGroupUserRole){
    element.editCancel();
  }

  public click_adUserGroupUserRoleSubmit(element: AdUserGroupUserRole){
    element.submitForm(this.adUserGroupUserRoleService);
  }

  // ------------- Catering -------------
  public click_showCatering(){
    this.setActiveUiDisplay('catering');
    this.getAllCatering();
  }

  public click_toggleCreateCatering(){
    this.initCateringForm();
    this.setActiveUiDisplay('cateringCreationForm');
  }

  public click_cancelCreateCatering(){
    this.setActiveUiDisplay('catering');
    this.cateringForm = null;
  }

  public click_submitCreateCatering(){
    this.cateringService.createCatering(this.cateringForm).subscribe((newCatering: Catering) => {
      this.allCatering.items.push(newCatering);
      this.allCatering.triggerMaterialTableChangeDetection();
      this.setActiveUiDisplay('catering');
    });
  }

  public click_deleteCatering(element: Catering){
    this.cateringService.deleteCatering(element.id).subscribe(() => {
      this.allCatering.removeItemById(element.id);
      this.allCatering.triggerMaterialTableChangeDetection();
    });
  }
  
  // ------------- Equipment -------------
  public click_showEquipment(){
    this.getAllEquipment();
    this.setActiveUiDisplay('equipment');
  }

  public click_toggleCreateEquipment(){
    this.initEquipmentForm();
    this.setActiveUiDisplay('equipmentCreationForm');
  }
  
  public click_editEquipment(element: Equipment){
    this.initEquipmentForm(element);
    this.setActiveUiDisplay('equipmentCreationForm');
  }

  public click_submitCreateEquipment(){
    console.log("submit create equipment");
    if(this.equipmentForm.id == -1){
      this.createNewEquipment();
    } else {
      this.updateEquipment(this.equipmentForm);
    }
    this.setActiveUiDisplay('equipment');
  }
  
  private createNewEquipment(){
    this.equipmentService.createEquipment(this.equipmentForm).subscribe((newEquipment: Equipment) => {
      this.allEquipment.addItem(newEquipment);
    });
  }

  private updateEquipment(equipment: Equipment){
    this.equipmentService.updateEquipment(equipment).subscribe((updatedEquipment: Equipment) => {
      this.allEquipment.updateItem(updatedEquipment);
    });
  }

  public click_cancelCreateEquipment(){
    this.equipmentForm = null;
    this.setActiveUiDisplay('equipment');
  }

  public click_deleteEquipment(element: Equipment){
    this.equipmentService.deleteEquipment(element.id).subscribe(() => {
      this.allEquipment.removeItemById(element.id);
    });
  } 

  private setActiveUiDisplay(optionToDisplay: string){
    this.uiDisplay.createDepartment = false;
    this.uiDisplay.roomForm = false;
    this.uiDisplay.equipment = false;
    this.uiDisplay.departmentRooms = false;
    this.uiDisplay.departments = false;
    this.uiDisplay.manageAdUserGroupUserRole = false;
    this.uiDisplay.catering = false;
    this.uiDisplay.equipmentCreationForm = false;
    this.uiDisplay.cateringCreationForm = false;
    this.uiDisplay.departmentCreationForm = false;
    
    if(optionToDisplay == 'departmentRooms'){
      this.uiDisplay.departmentRooms = true;
    }
    else if(optionToDisplay == 'roomForm'){
      //this.uiDisplay.departmentRooms = true;
      this.uiDisplay.roomForm = true;
    }
    else if(optionToDisplay == 'createDepartment'){
      this.uiDisplay.createDepartment = true;
    }
    else if(optionToDisplay == 'equipment'){
      this.uiDisplay.equipment = true;
    }
    else if(optionToDisplay == 'equipmentCreationForm'){
      this.uiDisplay.equipmentCreationForm = true;
      this.uiDisplay.equipment = true;
    }
    else if(optionToDisplay == 'departments'){
      this.uiDisplay.departments = true;
    }
    else if(optionToDisplay == 'departmentCreationForm'){
      this.uiDisplay.departments = true;
      this.uiDisplay.departmentCreationForm = true;
    }
    else if(optionToDisplay == 'manageAdUserGroupUserRole'){
      this.uiDisplay.manageAdUserGroupUserRole = true;
    }
    else if(optionToDisplay == 'catering'){
      this.uiDisplay.catering = true;
    }
    else if(optionToDisplay == 'cateringCreationForm'){
      this.uiDisplay.catering = true;
      this.uiDisplay.cateringCreationForm = true;
    }

    // console.log(this.uiDisplay);
  }
  // --------------------------------------------- UI ACTIONS END --------------------------------------------

  // --------------------------------------------- Forms -----------------------------------------------------
  private initDepartmentForm(element?: Department){
    this.departmentForm = new Department();
  }
  
  private initEquipmentForm(element?: Equipment){
    if(element){
      this.equipmentForm = new Equipment();
      this.equipmentForm.loadFromEquipment(element);
      console.log(this.equipmentForm);
    } else {
      this.equipmentForm = new Equipment();
    }
  }

  private initCateringForm(){
    this.cateringForm = new Catering();
  }
  

  // --------------------------------------------------- HELPERS -------------------------------------------------------
  public logRoom(room: Room){
    console.log(room);
  }

  
}
