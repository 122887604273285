import { BaseMasterItems } from './BaseMasterItems';
import { Break } from './break';

export class Breaks extends BaseMasterItems {

    breakToAdd: Break;
    
    public initForm(breaks?: Breaks){
        console.log('----------------- init breaks form - not implemented ------------------');
        console.log(breaks);

        // INIT FORM DATA ON ALL ITEMS
        if(breaks){
            this.addBreaks(breaks);
        }
        
        // CREATE BREAK TO ADD
        this.instantiateBreakToAdd();
    }
    
    private addBreaks(breaks: Breaks){
        breaks.items.forEach((b : Break) => {
            let breakToAdd: Break = Object.create(b);
            breakToAdd.initForm(b);
            this.items.push(breakToAdd);
        })
    }

    public instantiateBreakToAdd(){
        this.breakToAdd = new Break();
        this.breakToAdd.initForm();
    }

    public removeByIndex(index: number){
        super.removeAtIndex(index);
        this.items = this.items.concat();
    }

    public addBreakFormSubmit(){
        this.breakToAdd.setDateTimes();
        this.items = this.items.concat(this.breakToAdd);
        this.resetBreakToAdd();
    }
    
    private resetBreakToAdd(){
        this.instantiateBreakToAdd();
    }
    
}