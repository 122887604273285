import { BaseMasterItems } from '../BaseMasterItems';
import { BookingEvent } from '../BookingEvent';
import { BookingEvents } from '../BookingEvents';
import { CatererJob } from './CatererJob';

export class CatererJobs extends BaseMasterItems {

    public loadFromBookingEvents(bookingEvents: BookingEvents){
        this.items = new Array<CatererJob>();

        bookingEvents.items.forEach((bookingEvent: BookingEvent) => {
            let catererJob = new CatererJob();
            catererJob.loadFromBookingEvent(bookingEvent);
            this.items.push(catererJob);
        })
    }

    public orderByStartTimeAscending(){
        let orderedJobs = new Array<CatererJob>();
        let jobsCount = this.items.length;

        for (let i = 0; i < jobsCount; i++) {
            let itemToAdd: CatererJob = this.items[i];

            if(i == 0){
                orderedJobs.push(itemToAdd);
                continue;
            }

            let orderedJobsCount = orderedJobs.length;

            for (let j = 0; j < orderedJobsCount; j++) {
                // insert before first higher dateTime
                if(orderedJobs[j].startTime > itemToAdd.startTime){
                    orderedJobs.splice(j, 0, itemToAdd);
                    break;
                }
                // insert if it is last element
                if(j == orderedJobsCount -1){
                    orderedJobs.push(itemToAdd);
                    break;
                }
            }
            
        }

        this.items = orderedJobs;
    }
}
