import { MasterDataService } from 'src/app/services/MasterData.service';
import { Room } from './room'
import { RoomBasic } from './roomBasic';

export class MasterRoom {
    
    room: RoomBasic;
    selectedRoom: RoomBasic;
    roomsDropdown: Array<RoomBasic>;

    public init(){
        this.roomsDropdown = new Array<RoomBasic>();
    }

    public setRoom(room: RoomBasic){
        this.room = room;
    }

    public setRoomsDropdown(masterDataService: MasterDataService){
        masterDataService.rooms.items.forEach((r: Room) => {
            let room = new RoomBasic();
            room.loadFromRoom(r);
    
            this.roomsDropdown.push(room);
        });
    }

    public addRoom(){
        console.log("add room")
        console.log(this.selectedRoom)
        this.room = this.selectedRoom;
        this.selectedRoom = new RoomBasic();
    }

    public deleteRoom(){
        //this.room = new RoomBasic();
        this.room = null;
    }

    public reset(){
        this.room = new RoomBasic();
        this.roomsDropdown = new Array<RoomBasic>();
        this.selectedRoom = undefined;
    }

}
