import { MsalService } from "@azure/msal-angular";
import { AdUsers } from "../adUsers";
import { BaseMasterItem } from "../BaseMasterItem";
import { UserGroups } from "./UserGroups";
import { UserGroup } from "./UserGroup";

export class User extends BaseMasterItem {
    
    role: string;

    firstName: string
    lastName: string;
    domain: string;
    email: string;
    groups: UserGroups;

    // public string Id { get; set; }
    // public bool IsTenantAdmin { get; set; }
    // public bool IsTenantManager { get; set; }
    // public GroupsDTO Groups { get; set; }
    // public string Role { get; set; }
    // public string FirstName { get; set; }
    // public string LastName { get; set; }
    // public string FullName { get; set; }
    // public string LoginName { get; set; }
    // public string Email { get; set; }

    sendInvite: boolean = false;

    public loadFromJson(data){
        super.loadFromJson(data);
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.role = data.role;

        this.loadGroups(data);
    }

    private loadGroups(data: any){
        if(data.groups){
            this.groups = new UserGroups();
            this.groups.loadFromJson(UserGroup, data.groups.items);
        }
    }

    toggleSendInvite(){
        this.sendInvite == true ? this.sendInvite = false : this.sendInvite = true;
    }

    public loadFromGraphAdAccount(account) {
        this.firstName = this.getFirstNameFromDisplayName(account.displayName);
        this.lastName = this.getLastNameFromDisplayName(account.displayName);
        this.id = account.id;
        this.email = account.mail;
    }

    private getFirstNameFromDisplayName(displayName){
        return displayName.split(" ")[0];
    }
    
    private getLastNameFromDisplayName(displayName){
        return displayName.split(" ")[1];
    }

    public loadFromGraphAdAccounts(accounts: AdUsers, msalService: MsalService){
        console.log("load from graph ad accounts - method not implemented");
    }
    
    public loadFromGraphAdAccountsByUserId(accounts: AdUsers, id: string){
        console.log("load from graph ad accounts by user id - method not implemented");
    }

}
