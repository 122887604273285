import { Department } from "./department";
import { Departments } from "./departments";
import { Room } from "./room";
import { Rooms } from "./rooms";
import { DepartmentRooms } from './DepartmentRooms';
import { BarChartItem } from './BarChartItem';

export class ReportingMonth {
    allRoomsTimeAvailable: number = 0;
    allRoomsTimeUtilized: number = 0;
    allRoomsTimeNotUtilized: number= 0;

    departments: Departments;
    departmentRoomsList: Array<DepartmentRooms>;

    averageTimeWasted: number = 84;

    // allRoomsPieChart = {
    //     data: [] = [
    //         {
    //           "name": "All Rooms Times Utilized (mintues)",
    //           "value": this.allRoomsTimeUtilized
    //         },
    //         {
    //           "name": "All Rooms Times Not Utilized (minutes)",
    //           "value": this.allRoomsTimeNotUtilized
    //         },
    //     ]
    // }
    allRoomsPieChart = {
        data: [] = [
            {
              "name": "All Rooms Times Utilized (mintues)",
              "value": 1580000
            },
            {
              "name": "All Rooms Times Not Utilized (minutes)",
              "value": 420000
            },
        ]
    }

    departmentsAllRoomBarChart = [
      {
        "name": "Dep 1",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 8000 },
          { "name": "Time wasted (minutes)", "value": 2000 }
        ]
      },
      {
        "name": "Dep 2",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 7500 },
          { "name": "Time wasted (minutes)", "value": 2500 }
        ]
      },
      {
        "name": "Dep 3",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 9000 },
          { "name": "Time wasted (minutes)", "value": 1000 }
        ]
      },
      {
        "name": "Dep 4",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 8500 },
          { "name": "Time wasted (minutes)", "value": 1500 }
        ]
      },
      {
        "name": "Dep 5",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 8500 },
          { "name": "Time wasted (minutes)", "value": 1500 }
        ]
      },
      {
        "name": "Dep 6",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 7000 },
          { "name": "Time wasted (minutes)", "value": 3000 }
        ]
      },
      {
        "name": "Dep 7",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 9000 },
          { "name": "Time wasted (minutes)", "value": 1000 }
        ]
      },
      {
        "name": "Dep 8",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 6000 },
          { "name": "Time wasted (minutes)", "value": 4000 }
        ]
      },
      {
        "name": "Dep 9",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 9000 },
          { "name": "Time wasted (minutes)", "value": 1000 }
        ]
      },
      {
        "name": "Dep 10",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 9000 },
          { "name": "Time wasted (minutes)", "value": 1000 }
        ]
      },
    ];

    selectedDepartmentRooms: DepartmentRooms;
    selectedDepartmentRoomsBarChart = [
      {
        "name": "room 1",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 900 },
          { "name": "Time wasted (minutes)", "value": 100 }
        ]
      },
      {
        "name": "room 2",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 800 },
          { "name": "Time wasted (minutes)", "value": 200 }
        ]
      },
      {
        "name": "room 3",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 600 },
          { "name": "Time wasted (minutes)", "value": 400 }
        ]
      },
      {
        "name": "room 4",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 700 },
          { "name": "Time wasted (minutes)", "value": 300 }
        ]
      },
      {
        "name": "room 5",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 800 },
          { "name": "Time wasted (minutes)", "value": 200 }
        ]
      },
      {
        "name": "room 6",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 700 },
          { "name": "Time wasted (minutes)", "value": 300 }
        ]
      },
      {
        "name": "room 7",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 900 },
          { "name": "Time wasted (minutes)", "value": 100 }
        ]
      },
      {
        "name": "room 8",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 800 },
          { "name": "Time wasted (minutes)", "value": 200 }
        ]
      },
      {
        "name": "room 9",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 900 },
          { "name": "Time wasted (minutes)", "value": 100 }
        ]
      },
      {
        "name": "room 10",
        "series": [
          { "name": "Time Utilized (minutes)", "value": 900 },
          { "name": "Time wasted (minutes)", "value": 100 }
        ]
      },
    ];

    allRoomYearLineChart = [
        {
            "name": "Time Utilized",
            "series": [
              { "name": "August","value": 1360000},
              { "name": "September","value": 1380000},
              { "name": "October","value": 1400000},
              { "name": "November", "value": 1420000 },
              { "name": "December", "value": 1440000 },
              { "name": "January", "value": 1460000 },
              { "name": "Febuary", "value": 1480000 },
              { "name": "March", "value": 1500000 },
              { "name": "April", "value": 1520000 },
              { "name": "May", "value": 1540000 },
              { "name": "June", "value": 1560000 },
              {"name": "July", "value": 1580000},
            ]
        },
        {
            "name": "Time Not Utilized",
            "series": [
              { "name": "August", "value": 640000 },
              { "name": "September", "value": 620000 },
              { "name": "October", "value": 600000 },
              { "name": "November", "value": 580000 },
              { "name": "December", "value": 560000 },
              { "name": "January", "value": 540000 },
              { "name": "Febuary", "value": 520000 },
              { "name": "March", "value": 500000 },
              { "name": "April", "value": 480000 },
              { "name": "May", "value": 460000 },
              { "name": "June", "value": 440000 },
              { "name": "July", "value": 420000 },
            ]
          }
    ];

    public loadFromJson(data){
        console.log(data);
        this.allRoomsTimeAvailable = data.allRoomsTimeAvailable;
        this.allRoomsTimeUtilized = data.allRoomsTimeUtilized;
        this.allRoomsTimeNotUtilized = data.allRoomsTimeAvailable - data.allRoomsTimeUtilized;

        //this.initAllRoomsUtilizationChart();
        
        // this.initDepartments();
        // this.setDepartments(data);
        // this.setDepartmentBarChart();

        // this.initDepartmentRoomsList();
        // this.setDepartmentRooms(data);
        // this.initSelectedDepartmentRooms();
        // this.setSelectedDepartmentRoomsBarChart();
    }
    
    private initAllRoomsUtilizationChart(){
        this.allRoomsPieChart.data[0].value = this.allRoomsTimeUtilized;
        this.allRoomsPieChart.data[1].value = this.allRoomsTimeNotUtilized;
    }

    private initDepartments(){
        this.departments = new Departments();
    }

    private setDepartments(data){
        this.departments.loadFromJson(Department, data.departments.departments);
    }

    // private setDepartmentBarChart(){
    //     this.departments.items.forEach(department => {
    //         let newBarChartItem = new BarChartItem();
    //         newBarChartItem.init();

    //         newBarChartItem.setName(department.title);
    //         newBarChartItem.addSeriesItem('All Rooms Time Utilized', department.allRoomsTimeUtilized);
    //         newBarChartItem.addSeriesItem('All Rooms Time Not Utilized', department.allRoomsTimeAvailable - department.allRoomsTimeUtilized);

    //         this.departmentsAllRoomBarChart.push(newBarChartItem);
    //     })
    // }

    public initDepartmentRoomsList(){
        this.departmentRoomsList = new Array<DepartmentRooms>();
    }

    private setDepartmentRooms(data){
        data.departments.departments.forEach(department => {
            let newDepartmentRooms = new DepartmentRooms();
            newDepartmentRooms.setDepartmentId(department.id);
            newDepartmentRooms.initRooms();
            newDepartmentRooms.addRooms(department);
            this.departmentRoomsList.push(newDepartmentRooms);
        });
    }

    public initSelectedDepartmentRooms(){
        this.selectedDepartmentRooms = this.departmentRoomsList[0];
    }

    public setSelectedDepartmentRoomsBarChart(){
        this.selectedDepartmentRooms.rooms.items.forEach((room: Room) => {
            let newChartItem = new BarChartItem();
            newChartItem.init();

            newChartItem.setName(room.title);

            newChartItem.addSeriesItem('Time Utilized', room.getTimeUtilized());
            newChartItem.addSeriesItem('Time Not Utilized', room.getTimeNotUtilized());

            this.selectedDepartmentRoomsBarChart.push(newChartItem);
        })

        console.log(this.selectedDepartmentRoomsBarChart);
    }


}
