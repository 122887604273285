import { BookingEvent } from "../BookingEvent";
import { Caterings } from "./Caterings";

export class CatererJob {

    roomId: number;
    departmentId: number;
    startTime: Date;
    endTime: Date;
    caterings: Caterings;


    public loadFromBookingEvent(bookingEvent: BookingEvent){
        if(bookingEvent.id == 2931){
            console.log(bookingEvent);
        }

        this.roomId = bookingEvent.roomId;
        this.departmentId = bookingEvent.department.id;
        this.startTime = this.calculateStartTime(bookingEvent);
        this.endTime = bookingEvent.startTime;
        this.caterings = bookingEvent.catering;

    }

    private calculateStartTime(bookingEvent: BookingEvent): Date{
        let date = new Date(bookingEvent.startTime);
        date.setMinutes(date.getMinutes() - bookingEvent.setupTimeMinutes);
        return date;
    }

}
