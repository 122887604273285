import { Department } from "./department";


export class DepartmentForm {

    id: number;
    title: string;

    init(department: Department){
        this.id = department.id;
        this.title = department.title;
    }
    
}
