import { BaseMasterItem } from './BaseMasterItem'
import { Room } from './room';

export class RoomBasic extends BaseMasterItem{
    
    // public loadFromJson(room: Room){
    //     this.id = room.id;
    //     this.title = room.title;
    // }

    public loadFromRoom(room: Room){
        this.id = room.id;
        this.title = room.title;
    }

    // public loadFromRoomBasic(room: RoomBasic){
    //     this.id = room.id;
    //     this.title = room.title;
    // }
}
