import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IDataService } from '../shared/interfaces/IDataService';
import { BaseMasterItems } from '../shared/models/BaseMasterItems';
import {  BookingEvents } from '../shared/models/BookingEvents';
import { isNullOrUndefined } from 'util';

export class Digest {
  digestToken: string;
  digestExpiryTime: string;

  constructor(digestToken, digestExpiryTime) {
    this.digestToken = digestToken;
    this.digestExpiryTime = digestExpiryTime;
  }

  digestIsValid(): boolean {
    if (this.digestToken === null || this.digestExpiryTime === null)
      return false;

    let currentTime = moment();

    // Check a minute before actual expiry time as digest could expire by the time its used
    currentTime = currentTime.add(60, 'seconds');
    const b = currentTime.isBefore(this.digestExpiryTime);
    return b;
  }
}

export enum EHttpMethod {
  get = 'get',
  post = 'post',
  delete = 'delete',
  put = 'put',
}

export enum EHeaderTypes {
  GETListItem = 'GETListItem',
  FASTGETListItem = 'FASTGetListItem',
  POSTListItem = 'POSTListItem',
  UPDATEListItem = 'UPDATEListItem',
  DELETEListItem = 'DELETEListItem',
  POSTFile = 'POSTFile',
  GETDIGEST = 'DIGEST',
  POSTAttachment = 'POSTAttachment',
  ITEMCOUNT = 'ITEMCOUNT',
}

@Injectable({
  providedIn: 'root',
})
export class SharepointService implements IDataService {
  ngOnInit() {}
  constructor(private httpClient: HttpClient) {
  }

  public getBookingEventsByDay(listName:string, date: string): Observable<BookingEvents> {
    console.log('getting booking events by day');

    return this.getCamlItems(listName, date);
  }

  /**
   * Update existing Sharepoint Item via Sharepoint Call
   */
  // private updateItem(
  //   listName: string,
  //   itemID: number,
  //   dataToUpdate: any
  // ): Observable<any> {
  //   const query =
  //     environment.sharepointurl +
  //     "_api/web/lists/getbytitle('" +
  //     listName +
  //     "')/items(" +
  //     itemID.toString() +
  //     ')';
  //   const dataObj = {
  //     __metadata: { type: `SP.Data.${listName}ListItem` },
  //     ...dataToUpdate,
  //   };
  //   const dataString = JSON.stringify(dataObj);
  //   return this.httpCallerHandler(
  //     EHttpMethod.post,
  //     EHeaderTypes.UPDATEListItem,
  //     query,
  //     dataString
  //   );
  // }

  /**
   * Creates new item in listName using dataToPost as item's info.
   * @param listName - Name of Sharepoint list
   * @param dataToPost - Information for Sharepoint item to create
   * @returns Observable with operation
   */
  public createNewItem(listName: string, dataToPost: any): Observable<any> {
    const query =
      environment.sharepointurl +
      "_api/web/lists/getbytitle('" +
      listName +
      "')/items";
    const dataObj = {
      __metadata: { type: `SP.Data.${listName}ListItem` },
      ...dataToPost,
    };
    const dataString = JSON.stringify(dataObj);
    console.log('dataObj');
    console.log(dataObj);

    return this.httpCallerHandler(
      EHttpMethod.post,
      EHeaderTypes.POSTListItem,
      query,
      dataString
    );
  }

  private getCamlItems(
    listName: string,
    where: string,
    orderBy: string = '',
    rowLimit: number = 0,
    restExtension: string = '',
    groupBy: string = '',
    custom: string = ''
  ): Observable<any> {
    let strRowLimit = '';
    if (!!rowLimit && rowLimit > 0) {
      strRowLimit = '<RowLimit>' + rowLimit + '</RowLimit>';
    }

    let orderByClause = '';
    if (!!orderBy) {
      orderByClause = '<OrderBy>' + orderBy + '</OrderBy>';
    }

    let whereClause = '';
    if (!!where) {
      whereClause =
        "<Where><Eq><FieldRef Name='EventDate'/><Value Type='DateTime'>" +
        where +
        '</Value></Eq></Where>';
    }

    const groupClause = '';
    if (!!groupBy) {
      whereClause = '<GroupBy Collapse="TRUE">' + groupBy + '</GroupBy>';
    }

    const params =
      '<View>' +
      '<Query>' +
      groupClause +
      whereClause +
      orderByClause +
      '</Query>' +
      '</View>' +
      strRowLimit +
      (!!custom ? custom : '');

    let restExt = '';
    if (typeof restExtension !== 'undefined' && !!restExtension) {
      restExt += '?' + restExtension;
    }

    const endpoint =
      environment.sharepointurl +
      "_api/web/lists/GetByTitle('" +
      listName +
      "')/GetItems" +
      restExt;
    const requestData = {
      query: {
        __metadata: {
          type: 'SP.CamlQuery',
        },
        ViewXml: params,
      },
    };

    const header = EHeaderTypes.FASTGETListItem;
    return this.httpCallerHandler(
      EHttpMethod.post,
      header,
      endpoint,
      requestData
    ).pipe(map((res) => res.d.results));
  }

  public getItems(
    listName: string,
    whereClause?: string[],
    columnsSelected?: string[],
    orderClause?: any,
    expandClause?: string[],
    restExtension?: string
  ): Observable<any> {

    const select =
      columnsSelected != undefined
        ? '$select=' +
          (typeof columnsSelected === 'object'
            ? columnsSelected.join(', ')
            : columnsSelected)
        : '';

    let where = '';
    if (whereClause != undefined) {
      if (typeof whereClause === 'object') {
        if (whereClause.length <= 1) {
          where = '$filter= ' + 'Id eq ' + whereClause;
        } else {
          where = '$filter= ' + 'Id eq ' + whereClause.join(' or Id eq ');
        }
      }
    } else {
      '';
    }

    const expand =
      expandClause != undefined
        ? '$expand=' +
          (typeof expandClause === 'object'
            ? expandClause.join(', ')
            : expandClause)
        : '';

    let orderBy = '';
    if (orderClause != undefined) {
      let orderCol = '';
      if (typeof orderClause === 'object') {
        const order =
          orderClause.filter((item) => item.search('asc') > -1) ||
          orderClause.filter((item) => item.search('desc') > -1);
        orderCol = order.length > 0 ? '' : 'desc';
        orderBy = '$orderby= ' + orderClause.join(', ') + ' ' + orderCol;
      } else {
        orderCol =
          orderClause.indexOf('asc') > -1 || orderClause.indexOf('desc') > -1
            ? ''
            : 'asc';
        orderBy = '$orderby=' + orderClause + ' ' + orderCol;
      }
    } else {
      orderBy = '';
    }

    if (!restExtension) restExtension = '';
    let options = [select, where, expand, orderBy, restExtension]
      .filter((clause) => !['', undefined].includes(clause))
      .join(' &');
    options = options !== '' ? '?' + options : options;
    return this.getItemsExplicit(listName, options).pipe(
      map((res) => res.d.results)
    );
  }

  /**
   * Gets current user in ECMT.
   * @returns Observale with operation.
   */
  private getCurrentUser() {
    const query =
      environment.sharepointurl + '/_api/Web/CurrentUser?$select=Id, Title';
    return this.httpCallerHandler(
      EHttpMethod.get,
      EHeaderTypes.GETListItem,
      query
    );
  }

  // Handles all HTTP requests in the following steps:
  //  1. checking the digest, updating if necessary
  //  2. THEN getting the appropriate header for the call
  //  3. Running the Http call
  //  4. Returing the data from the call
  /**
   * Https caller handler for ALL Sharepoint operations.
   * @param httpmethod - enum of HTTP method to call
   * @param headerType - enum of Header to use for this call
   * @param query - string query to send to Sharepoint
   * @param dataToPost - if POST or UPDATE, additional data to give to HTTP call.
   * @returns Observable call to Sharepoint
   */
  private httpCallerHandler(
    httpmethod: EHttpMethod,
    headerType: EHeaderTypes,
    query: string,
    dataToPost: any = null
  ): Observable<any> {
    const headers: HttpHeaders = this.getCorrectHeader(headerType);

    return this.selectHTTPMethod(httpmethod, query, headers, dataToPost).pipe(
      catchError((err) => of([err]))
    );
  }

  // Checks if Digest is valid, and if so, returns empty observable which immediately resolves
  // If not valid, a new digest is requested, and set, and its expiry time stored as a moment
  private getDigest(): Observable<Digest> {
    const url = environment.sharepointurl + '_api/contextinfo';
    const headers = this.getCorrectHeader(EHeaderTypes.GETDIGEST);
    return this.httpClient.post(url, null, { headers }).pipe(
      map((data: any) => {
        // tslint:disable-next-line: no-string-literal
        if (
          ![null, undefined].includes(
            data.d.GetContextWebInformation.FormDigestValue
          )
        ) {
          const digestToken = data.d.GetContextWebInformation.FormDigestValue;
          const expirySeconds: number =
            data.d.GetContextWebInformation.FormDigestTimeoutSeconds;
          let currentTime = moment();
          currentTime = currentTime.add(expirySeconds, 'seconds');

          const digestExpiryTime = currentTime;
          return new Digest(digestToken, digestExpiryTime);
        }
      })
    );
  }

  /**
   * Selects http method to call SP with by referring to httpMethod enum, and returns http call to make.
   * @param httpMethod - enum referencing text saying which HTTP method to use ('GET', 'POST', 'UPDATE' etc)
   * @param query - string query to make to SP
   * @param httpHeaders  - headers for HTTP call to make
   * @param dataToPost - stringified object with data if httpMethod ===  'POST' or 'UPDATE'
   * @returns Observable<any> of HTTP call
   */
  private selectHTTPMethod(
    httpMethod: EHttpMethod,
    query: string,
    httpHeaders: HttpHeaders,
    dataToPost = ''
  ): Observable<any> {
    switch (httpMethod) {
      case EHttpMethod.get:
        return this.httpClient.get(query, { headers: httpHeaders });
      case EHttpMethod.post:
        return this.getDigest().pipe(
          concatMap((res) => {
            httpHeaders = httpHeaders.set('X-RequestDigest', res.digestToken);
            return this.httpClient.post(query, dataToPost, {
              headers: httpHeaders,
            });
          })
        );
      case EHttpMethod.delete:
        return this.httpClient.delete(query, { headers: httpHeaders });
      case EHttpMethod.put:
        return this.httpClient.put(query, { headers: httpHeaders });
      default:
        break;
    }
  }

  /**
   * Gets correct header for HTTP call
   * @param commandType - header enum which stores string
   * @returns correct header in form of HttpHeader class
   */
  private getCorrectHeader(commandType: EHeaderTypes): HttpHeaders {
    let digestToken = '';

    let headers: any;
    switch (commandType) {
      case EHeaderTypes.POSTListItem:
        headers = new HttpHeaders({
          Accept: 'application/json; odata=verbose',
          'Content-Type': 'application/json;odata=verbose',
        });
        break;
      case EHeaderTypes.UPDATEListItem:
        headers = new HttpHeaders({
          Accept: 'application/json;odata=verbose',
          'Content-Type': 'application/json;odata=verbose',
          'IF-MATCH': '*',
          'X-HTTP-Method': 'MERGE',
        });
        break;
      case EHeaderTypes.POSTFile:
        headers = new HttpHeaders({
          Accept: 'application/json;odata=verbose',
          'Content-Type': 'application/json;odata=verbose',
          'IF-MATCH': '*',
          'X-HTTP-Method': 'PATCH',
        });
        break;
      case EHeaderTypes.DELETEListItem:
        headers = new HttpHeaders({
          Accept: 'application/json;odata=verbose',
          'IF-MATCH': '*',
        });
        break;
      case EHeaderTypes.GETListItem:
        headers = new HttpHeaders({
          Accept: 'application/json;odata=verbose',
          'Content-Type': 'application/json;odata=verbose',
        });
        break;

      // Used for CAML Queries which actually make a POST request
      // and need digest
      // Doesn't seem to work for 2013
      case EHeaderTypes.FASTGETListItem:
        headers = new HttpHeaders({
          Accept: 'application/json;odata=verbose',
          'Content-Type': 'application/json;odata=verbose',
        });
        break;

      case EHeaderTypes.GETDIGEST:
        headers = new HttpHeaders({
          Accept: 'application/json;odata=verbose',
          'Content-Type': 'application/json;odata=verbose',
        });
        break;

      case EHeaderTypes.POSTAttachment:
        headers = new HttpHeaders({
          Accept: 'application/json;odata=verbose',
          'Content-Type': 'application/json;odata=verbose',
        });
        break;
      default:
        return new HttpHeaders({
          'Content-Type': 'application/json;odata=verbose',
        });
        break;
    }
    return headers;
  }

  /**
   * Gets Request for list using REST, allowing for additional params
   * @param listName - SP list to get
   * @param options - additional params
   * @returns Observable<any> of GET request
   */
  private getItemsExplicit(
    listName: string,
    options: string = ''
  ): Observable<any> {
    const query =
      environment.sharepointurl +
      "_api/web/lists/getbytitle('" +
      listName +
      "')/items" +
      options;
    //console.log(query);
    return this.httpCallerHandler(
      EHttpMethod.get,
      EHeaderTypes.GETListItem,
      query
    );
  }

  private getUserById(id: number) {
    if (!!id) {
      let query =
        environment.sharepointurl + '_api/web/getuserbyid(' + id + ')';
      return this.httpCallerHandler(
        EHttpMethod.get,
        EHeaderTypes.GETListItem,
        query
      );
    }
  }

  private getUserInformation(username: string) {
    let query =
      environment.sharepointurl +
      `_api/SP.UserProfiles.PeopleManager/GetPropertiesFor(accountName=@v)?@v='${username}'`;
    return this.httpCallerHandler(
      EHttpMethod.get,
      EHeaderTypes.GETListItem,
      query
    );
  }

  private getAllUsers() {
    let query = environment.sharepointurl + '/_api/Web/SiteUsers';
    console.log('getting all users');
    return this.httpCallerHandler(
      EHttpMethod.get,
      EHeaderTypes.GETListItem,
      query
    );
  }

  public getBookingEventsByBookingId(Id: number):Observable<any> {
    console.log("not implemented in sharepoint service");
    return
  }

  public getFromCalendarData(calendarName): Observable<any> {
    console.log("not implemented")
    console.log("should pick what calendar to take data from")
    console.log("build a caml query to get the data...")
    return 
  }

  public deleteItem(listName, id, column?): Observable<any>{
    console.log("not yet implemented");
    console.log("deletes item by id");
    return;
  }

  updateItem(listName, item){
    console.log("TODO: ADD UPDATE ITEM METHOD SHAREPOINT SERVICE");
    return null;
  }
}
