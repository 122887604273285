import { MasterDataService } from 'src/app/services/MasterData.service';
import { BaseMasterItem } from './BaseMasterItem';

export class Equipment extends BaseMasterItem {

    // Data 
    equipmentId: number;
    roomEquipmentId: number;
    bookingEventEquipmentId: number;

    roomId: number;
    quantity: number;
    price: number;
    description: number;
    maxQuantity: number;

    selectedQuantity: number;
    availableQuantity: number;

    // Ui options
    hideInDropdown: boolean = false;
    valid: boolean = false;
    quantityDropdown: Array<number>;
    dropdownOptions: Array<{id: number, title: string, disabled: boolean}>;
    editQuantity: boolean = false;    

    loadFromJson(jsonItem){
        super.loadFromJson(jsonItem);

        this.equipmentId = jsonItem.equipmentId;
        this.quantity = jsonItem.quantity;
        this.roomId = jsonItem.roomId;
        this.price = jsonItem.price;
        this.maxQuantity = jsonItem.maxQuantity;
        this.description = jsonItem.description;

        // SET QUANTITY DROPDOWN
        if(jsonItem.maxQuantity){
            this.quantityDropdown = [];

            for (let i = 1; i <= jsonItem.maxQuantity; i++) {
                this.quantityDropdown.push(i);
            }
        }

    }

    public loadFromEquipment(equipment: Equipment){
        this.id = equipment.id;
        this.title = equipment.title;
        this.description = equipment.description;
    }

    addFormData(masterDataService: MasterDataService){
        console.log(masterDataService.equipments);

        masterDataService.equipments.items.forEach((equipment: Equipment) => {
            this.dropdownOptions.push({id: equipment.id, title: equipment.title, disabled: false});
        });
    }

    filterEquipmentsFormDropdown(selectedEquipment: Array<Equipment>){
        // find all of the selcted equipment in dropdown options and set them to false
        this.dropdownOptions.forEach((equipmentOption: {id: number, title: string, disabled: boolean}) => {
            equipmentOption.disabled = false;
            
            selectedEquipment.forEach((equipment: Equipment) => {
                // If equipmentOption Id = selectedEquipment Id set disabled to true
                if(equipmentOption.id == equipment.equipmentId){
                    equipmentOption.disabled = true;
                }
            });
        });
    }
    
    public editEquipmentQuantity(){
        console.log("edit equipment quantity");

        this.editQuantity == true ? this.editQuantity = false : this.editQuantity = true;

    }

}
