<div>
  <div>
    <mat-form-field class="example-full-width">
      <mat-label>Person</mat-label>
      <input matInput autocomplete="off" [(ngModel)]="peoplePickerInput" (ngModelChange)="changed_peoplePickerInput()">
    </mat-form-field>
  
    <!-- list of users -->
    <div *ngIf="users">
      <div *ngFor="let user of users.items">
        <div>{{user.firstName + " " + user.lastName}}</div>
      </div>
    </div>
  </div>

  <div>
    <button mat-button (click)="click_getAllCurrentUsersDetails()">Get All Current Users Details</button>
  </div>

  <div>
    <button mat-button (click)="click_getAllAdGroups()">Get All Groups</button>

    
    <!-- list of users -->
    <div *ngIf="groups">
      <!-- <div *ngFor="let user of users.items">
        <div>{{user.firstName + " " + user.lastName}}</div>
      </div> -->
    </div>
  </div>

</div>