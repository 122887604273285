import { BaseMasterItem } from './BaseMasterItem';
import { BaseMasterItems } from './BaseMasterItems';
import { Booking } from './booking';
import { BookingEvent } from './BookingEvent';

export class Bookings extends BaseMasterItems {

    loadBookingAndEventsFromData(data: Array<Object>){
        data.forEach(jsonItem => {
            let newItem:Booking = new Booking();
            newItem.loadBookingAndEventsFromData(jsonItem);
            this.items.push(newItem);
        });
    }

    updateTimesFromInputs(){
        this.items.forEach((b: Booking) => {
            b.bookingEvents.items.forEach((be: BookingEvent) => {
                // SET START TIME
                be.startTime = new Date(be.eventDate.getFullYear(), be.eventDate.getMonth(), be.eventDate.getDate(), be.startHour, be.startMinute,0) ;
                // SET END TIME
                be.endTime = new Date(be.eventDate.getFullYear(), be.eventDate.getMonth(), be.eventDate.getDate(), be.endHour, be.endMinute,0) ;
            });
        });
    }

}
