import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { RootService } from 'src/app/services/Root.service';
import { UserService } from 'src/app/services/User.service';
import { User } from 'src/app/shared/models/User/User';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements AfterViewInit {
  @ViewChild(MatSidenav) drawer : MatSidenav;

  currentUser: User;

  public expanded: boolean = false;
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver, 
    private msalService: MsalService, 
    private rootService: RootService,
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit(){
    this.showHideSideNav();
  }
  
  ngAfterViewInit(): void {
    this.isHandset$.subscribe(x => x == true ? this.expanded = true : false);
  }

  private showHideSideNav(){
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd){
        console.log(event);
        if(event.url == '/roomDisplay'){
          let sideNav = document.getElementById('side-nav');
          sideNav.style.display = 'none';
        } else {
          let sideNav = document.getElementById('side-nav');
          sideNav.style.display = 'block';
        }
      }
    });
  }
  
  public allowItemOfType(type: string){
    return this.userService.isAllowedItemOfType(type);
  }

  public toggleExpand(overwrite?: boolean): void {
    if (overwrite == true || overwrite == false) {
      this.expanded = overwrite;
    } else {
      this.expanded = !this.expanded;
    }

    this.rootService.navigationExpanded$.next(this.expanded);
  }

  public logout(): void {
    environment.redirectUrl = window.location.href;
    this.msalService.logout();
  }
}
