import { BaseMasterItem } from '../BaseMasterItem';

export class Catering extends BaseMasterItem {

    price: number;
    description: string;
    notes: string;
    quantity: number;

    public loadFromJson(data){
        super.loadFromJson(data);

        this.price = data.price;
        this.description = data.description;
        this.quantity = data.quantity;
        this.notes = data.notes;
    }

}
