import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Room } from '../shared/models/room';
import { Rooms } from '../shared/models/rooms';
import { SqlService } from './Sql.service';
import { IRoomsDTO } from '../shared/interfaces/IRooms'
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  
  constructor(
    private dataService: DataService,
    private sqlService: SqlService
  ) { }
    
  // --------------------------------------------------- GET --------------------------------------------------
  public getAllRooms(): Observable<Rooms> {
    return this.dataService.getItems("rooms", "getAllRooms").pipe(map((roomsData: IRoomsDTO) => {
      let rooms = new Rooms();
      rooms.loadFromJson(Room, roomsData.items);
      return rooms;
    }))
  }
  
  public getRoom(id: number): Observable<Room> {
    return this.dataService.getItemById('rooms', id).pipe(map((roomData) => {
      let room = new Room();
      room.loadFromJson(roomData);

      return room;
    }))
  }
  
  public getAllRoomsWithBookingEventsByDate(selectedDate: Date): Observable<Rooms> {
    console.log("TODO: MAKE THIS GENERIC/ USE DATA SERVICE");
    return this.sqlService.getAllRoomsWithBookingEventsByDate(selectedDate).pipe(map((data: Array<any>) => {
      let rooms = new Rooms();
      rooms.loadFromJson(Room, data);
      return rooms;
    }))
  }

  // --------------------------------------------------- Create --------------------------------------------------
  public createRoom(room: Room): Observable<Room> {
    return this.dataService.createNewItem("rooms", room).pipe(map(roomData => {
      let roomToReturn = new Room();
      roomToReturn.loadFromJson(roomData);
      
      return roomToReturn;
    }))
  }
  
  public updateRoom(room: Room): Observable<Room>{
    return this.dataService.updateItem("rooms", room).pipe(map(roomData => {
      let roomToReturn = new Room();
      roomToReturn.loadFromJson(roomData);
      
      return roomToReturn;
    }))
  }
 
  public deleteRoom(room: Room): Observable<any> {
    return this.dataService.deleteItem('rooms', room).pipe(map(res => {
      console.log(res);
      
      return res;
    }))
  }

}
