

export class BarChartItem {

    name: string;
    series: Array<{
        name: string,
        value: number
    }>

    public init(){
        this.series = new Array<{name: string, value: number}>();
    }

    public setName(name: string){
        this.name = name;
    }

    public addSeriesItem(name: string, value: number){
        let newSeriesItem = {
            name: name,
            value: value
        }

        this.series.push(newSeriesItem);
    }

}
