import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingService } from './booking.service';
import { BookingEventsService } from './BookingEvents.service';
import { DataService } from './data.service';
import { InitializationService } from './initialization.service';
import { MasterDataService } from './MasterData.service';
import { SharepointService } from './sharepoint.service';
import { SignalRService } from './signalR.service';
import { SqlService } from './Sql.service';


@Injectable({
  providedIn: 'root'
})
export class AllServicesService {

constructor(
  public bookingService: BookingService,
  public bookingEventsService: BookingEventsService,
  public dataService: DataService,
  public initializationService: InitializationService,
  public masterDataService: MasterDataService,
  public sharepointService: SharepointService,
  public signalRService: SignalRService,
  public sqlService: SqlService,

  public httpClient: HttpClient,
) { }

}
