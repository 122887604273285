import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable, Subject } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { User } from '../shared/models/User/User';
import { Users } from '../shared/models/User/Users';
import { DataService } from './data.service';
import { IUsersDto } from '../shared/interfaces/IUsersDto';
import { MsalService } from '@azure/msal-angular';
import { UserGroups } from '../shared/models/User/UserGroups';
import { UserGroup } from '../shared/models/User/UserGroup';
import { cwd } from 'node:process';

@Injectable({
  providedIn: 'root'
})
export class UserService {

constructor(
  private dataService: DataService,
  private msalService: MsalService,
) { }

  currentUser: User;

  public init(){
    this.initCurrentUser();
  }

  public initCurrentUser(){
    let currentUserAdId = this.getCurrentUsersId();
    
    this.getUserById(currentUserAdId).subscribe((user: User) => {
      this.currentUser = user;
      // this.currentUser.role = "Admin";
    });
  }

  // ------------------------ GET -------------------------
  public getUserById(adAccountId: string): Observable<User> {
    return this.getAccessToken('User.ReadBasic.All').pipe(mergeMap((accessToken) => {
      return this.dataService.getItemByIdString('User', 'getUserByAdAccountId', adAccountId, accessToken).pipe(map(userData => {
        let user = new User();
        user.loadFromJson(userData);
        return user;
      }));
    }));
  }

  public getAllUsers() : Observable<Users> {
    return this.getAccessToken('user.readbasic.all').pipe(mergeMap((accessToken: string) => {
      return this.dataService.getItems('user', 'getAllAdUsers', null, accessToken).pipe(map((usersData: IUsersDto) => {
        let users = new Users();
        users.loadFromJson(User, usersData.items);
        return users;
      }));
    }))
  }

  public getAllUsersStartsWith(InputText): Observable<Users> {
    return this.getAccessToken('user.readbasic.all').pipe(mergeMap((accessToken: string) => {
      return this.dataService.getItems('user', 'getAllAdUsersStartsWith', InputText, accessToken).pipe(map((usersData: IUsersDto) => {
        let users = new Users();
        users.loadFromJson(User, usersData.items);
        return users;
      }));
    }))
  }

  public getAllUserGroups(){
    return this.getAccessToken('Group.Read.All').pipe(mergeMap((accessToken: string) => {
      return this.dataService.getItems('user', 'getAllUserGroups', null, accessToken).pipe(map((groupsData: any) => {
        let userGroups = new UserGroups();
        console.log(groupsData);
        userGroups.loadFromJson(UserGroup, groupsData.items);
        return userGroups;
      }))
    }))
  }

  public getCurrentUsersId(): string {
    return this.msalService.instance.getActiveAccount().localAccountId;
  }

  private getAccessToken(permissionName: string): Observable<string> {
    return this.msalService.acquireTokenSilent({
      scopes: [permissionName]
    }).pipe(map(res => {
      return res.accessToken;
    }))
  }
  
  // ------------------------ UPDATE -------------------------
  public updateUser(user: User) : Observable<User> {
    return this.dataService.updateItem('user', user).pipe(map((userData) => {
      let user: User = new User();
      user.loadFromJson(userData);
      return user;
    }));
  }

  // ------------------------ HELPER -------------------------
  public isAllowedItemOfType(type: string){
    if(this.currentUser == null)
      return;
      
    if(type == "Admin"){
      return this.canViewAdmin();
    } else if(type == "User"){
      return this.canViewUser();
    } else if(type == "Manager"){
      return this.canViewManager();
    } else if(type == "TennantAdministrator"){
      return this.canViewTennantAdministrator();
    } else if(type == "Janitor"){
      return this.canViewJanitor();
    } else if(type == "Caterer"){
      return this.canViewCaterer();
    } else {
      console.log("INCORRENT TYPE NAME IN COMPONENT, TYPE: " + type);
    }

  }

  private canViewAdmin(){
    if(this.currentUser.role == "Admin"){
      return true;
    } else {
      return false;
    }
  }

  private canViewUser(){
    if(this.currentUser.role == "User" || this.currentUser.role == "Admin" || this.currentUser.role == "Manager" || this.currentUser.role == "TennantAdministrator"){
      return true;
    } else {
      return false;
    }
  }

  private canViewManager(){
    if(this.currentUser.role == "Admin" || this.currentUser.role == "Manager" || this.currentUser.role == "TennantAdministrator"){
      return true;
    } else {
      return false;
    }
  }

  private canViewTennantAdministrator(){
    if(this.currentUser.role == "Admin" || this.currentUser.role == "TennantAdministrator"){
      return true;
    } else {
      return false;
    }
  }

  private canViewJanitor(){
    if(this.currentUser.role == "Janitor" || this.currentUser.role == "Admin" || this.currentUser.role == "Manager" || this.currentUser.role == "TennantAdministrator"){
      return true;
    } else {
      return false;
    }
  }
  
  private canViewCaterer(){
    if(this.currentUser.role == "Caterer" || this.currentUser.role == "Admin" || this.currentUser.role == "Manager" || this.currentUser.role == "TennantAdministrator"){
      return true;
    } else {
      return false;
    }
  }

}
