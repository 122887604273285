import { CateringService } from 'src/app/services/Catering.service';
import { MasterDataService } from 'src/app/services/MasterData.service';
import { BookingEventFormFilterReturn } from './BookingEventFormFilterReturn';
import { Breaks } from './breaks';
import { Catering } from './Catering/Catering';
import { Caterings } from './Catering/Caterings';
import { Department } from './department';
import { DepartmentBasic } from './DepartmentBasic';
import { Departments } from './departments';
import { Equipments } from './Equipments';
import { Room } from './room';
import { Rooms } from './rooms';
import { Seating } from './seating';
import { Seatings } from './seatings';
import { Session } from './session';
import { Sessions } from './sessions'

export class BookingEventForm {
    
    id: number = -1;
    title: string = null;
    booking: number;
    departments: Departments;
    rooms: Rooms;
    maximumAttendees: number;
    setupTimeMinutes: number;
    packupTimeMinutes: number;
    isInviteOnly: boolean;
    
    seating: Seating;
    seatingDropdown: Seatings;

    catering: Catering;
    cateringDropdown: Caterings;

    sessions: Sessions;
    // change this to select catering items if needed
    hasCatering: boolean;

    // change this to be in the equipments dropdown
    hasVideoConferencing: boolean;


    public init(masterDataService: MasterDataService, room: Room, department: Department, bookingEventEquipments: Equipments, breaks: Breaks, startDateTime: Date, endDateTime: Date){
        this.setDepartmentDropdown(masterDataService, department);
        this.setRoomDropdown(masterDataService, room);
        this.setSession(masterDataService, bookingEventEquipments, breaks, startDateTime, endDateTime);
        this.setSeating(masterDataService);
        this.setCatering(masterDataService);
    }
    
    private setDepartmentDropdown(masterDataService: MasterDataService, department: Department){
        this.departments = masterDataService.getDepartments();
        this.departments.setSelectedDepartment(department);
        //this.departments.initSelectedDepartment(department);
    }
    
    private setRoomDropdown(masterDataService: MasterDataService, room: Room){
        this.rooms = new Rooms();
        this.rooms.loadFromMasterDataService(masterDataService);
        this.rooms.initSelectedRoom(room);
    }
    
    private setSession(masterDataService: MasterDataService, bookingEventEquipments: Equipments, breaks: Breaks, startDateTime: Date, endDateTime: Date){
        this.sessions = new Sessions();
        this.sessions.initForm(masterDataService, bookingEventEquipments, breaks, startDateTime, endDateTime);
    }

    private setSeating(masterDataService: MasterDataService){
        this.seating = new Seating();
        this.seatingDropdown = masterDataService.getSeatings();
    }

    private setCatering(masterDataService: MasterDataService){
        this.catering = new Catering;
        this.cateringDropdown = new Caterings;
        // masterDataS
    }

    public initNew(masterDataService: MasterDataService){
        this.initDepartments(masterDataService);
        this.initRoom(masterDataService);
        this.initSession(masterDataService);
    }

    private initDepartments(masterDataService){
        this.departments = masterDataService.getDepartments();
        this.departments.setSelectedDepartment();

        // this.departments = new Departments();
        // this.departments.loadFromMasterDataService(DepartmentBasic, masterDataService);
        // this.departments.initSelectedDepartment();
    }

    public initRoom(masterDataService){
        this.rooms = new Rooms();
        this.rooms.loadFromMasterDataService(masterDataService);
        this.rooms.initSelectedRoom();
    }

    public initSession(masterDataService){
        this.sessions = new Sessions();
        this.sessions.addSession(masterDataService);
    }

    // SETS THE DISSABLED INPUT FIELDS FROM THE BookingEventFormFilterReturn OBJECT
    public setDissabledInputs(formData: BookingEventFormFilterReturn){
        // SET DISABLED ROOMS
        for (let i = 0; i < this.rooms.items.length; i++) {
            let room: Room = this.rooms.items[i];
            let roomData: Room = formData.bookingEventForm.rooms.items[i];
            
            room.disabled = roomData.disabled;
        }
        
        // SET DISABLED DEPARTMENTS
        console.log("FILTERING DEPARTMENTS NOT CURRENTLY IMPLEMENTED");
        
        // LOOP THROUGH SESSIONS
        for (let i = 0; i < this.sessions.items.length; i++) {
            let formSession: Session = this.sessions.items[i];
            let sessionFilterData: Session = formData.bookingEventForm.sessions.items[i];

            formSession.SetDisabledFormInputs(sessionFilterData);
        }
        
    }

}
