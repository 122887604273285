import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { User } from '../shared/models/User/User';
import { Users } from '../shared/models/User/Users';
import { AdUsers } from '../shared/models/adUsers';
import { AdUserGroupUserRoleService } from './AdUserGroupUserRole.service';
import { AdUserGroupUserRole } from '../shared/models/AdUserGroupUserRole';

@Injectable({
  providedIn: 'root'
})
export class AzureAdService {

  constructor(
    private msalService: MsalService,
    private http: HttpClient
  ) { }

  public getAllAdUsers(): Observable<Users>{

    // GET ACCESS TOKEN
    return this.msalService.acquireTokenSilent({
      scopes: ['user.readbasic.all']
    }).pipe(mergeMap(res => {

      let httpOptions = this.getHttpOptions(res);

      // GET USERS AND CREATE USERS OBJECT TO RETURN
      return this.http.get("https://graph.microsoft.com/v1.0/users?$select=id,displayName,mail", httpOptions).pipe(map(res => {
        console.log(res);
        let user = new Users();
        user.loadFromGraphAdAccounts(res);
        return user;
      }))
    }))
  }

  public getCurrentUser(): Observable<User>{

    // GET ACCESS TOKEN
    return this.msalService.acquireTokenSilent({
      scopes: ['user.readbasic.all']
    }).pipe(mergeMap(res => {

      let httpOptions = this.getHttpOptions(res);

      // GET ALL USERS FROM AZURE AD
      return this.http.get("https://graph.microsoft.com/v1.0/users?$select=id,displayName,mail", httpOptions).pipe(map((userData: AdUsers) => {
        console.log(userData);

        let user = new User();
        user.loadFromGraphAdAccounts(userData, this.msalService);

        return user;
      }))
    }))
  }

  getUserByUserAdId(id: string): Observable<User>{

    return this.msalService.acquireTokenSilent({
      scopes: ['user.readbasic.all']
    }).pipe(mergeMap(res => {

      let httpOptions = this.getHttpOptions(res);

      return this.http.get("https://graph.microsoft.com/v1.0/users?$select=id,displayName,mail", httpOptions).pipe(map((userData: AdUsers) => {
        console.log(userData);
        let user = new User();
        let userAccounts = new AdUsers;
        userAccounts.loadFromAdAccountsData(userData);
        
        user.loadFromGraphAdAccountsByUserId(userAccounts, id);

        return user;
      }))
    }))
  }
  
  public getUserRole(user: User, userGroups: Array<string>){
    return this.msalService.acquireTokenSilent({
      scopes: ['Group.Read.All']
    }).pipe(mergeMap(res => {
  
      let httpOptions = this.getHttpOptions(res);
  
      return this.http.get("https://graph.microsoft.com/v1.0/users?$select=id,displayName", httpOptions).pipe(map((userGroups: AdUsers) => {
        console.log(userGroups)      
      }))
    }))
    
  }

  private getHttpOptions(res){
    return{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: res.accessToken
      })
    }
  }



}
