import { BaseMasterItem } from './BaseMasterItem';
import { Seating } from './seating';
import { Room } from './room';
import { MasterDataService } from 'src/app/services/MasterData.service';
import { Seatings } from './seatings';

export class MaxPers extends BaseMasterItem {

    number: number;
    setupTimePre: number;
    setupTimePost: number;
    limitCode: string;
    
    seating: Seating;
    room: Room;
    department:Room;

    seatingId: number;
    roomId: number;
    departmentId: number;

    loadFromJson(jsonItem){
        super.loadFromJson(jsonItem);
        this.number = jsonItem.number;
        this.setupTimePre = jsonItem.setupTimePost;
        this.setupTimePost = jsonItem.setupTimePost;
        this.limitCode = jsonItem.limitCode;
        this.seatingId = jsonItem.seatingId;
        this.roomId = jsonItem.roomId;
        this.departmentId = jsonItem.departmentId;
    }

    loadExtraData(seatings, rooms, departments){
        this.seating = seatings.getById(this.seatingId);
        this.room = rooms.getById(this.roomId);
        this.department = departments.getById(this.departmentId);
    }

}
