import { Department } from './department';
import { BaseMasterItems } from './BaseMasterItems';
import { MasterDataService } from 'src/app/services/MasterData.service';
import { BaseMasterItem } from './BaseMasterItem';
import { DepartmentBasic } from './DepartmentBasic';
import { Room } from './room';

export class Departments extends BaseMasterItems {

    selectedDepartment: Department;

    // public loadFromMasterDataService<T extends BaseMasterItem>(c: {new (): T}, masterDataService: MasterDataService){
    //     masterDataService.departments.items.forEach((item: Department) => {
    //         let newItem:BaseMasterItem = new c();
    //         newItem.loadFromJson(item)
    //         this.items.push(newItem);
    //     })
    // }

    // public initSelectedDepartment(department?: Department){
    //     this.selectedDepartment = new Department();

    //     if(department){
    //         this.items.forEach((d: Department) => {
    //             if(d.id == department.id){
    //                 this.selectedDepartment = d;
    //             }
    //         })
    //     }
    // }

    public setSelectedDepartment(department?: Department){
        console.log('set selected department');
        this.selectedDepartment = new Department();
        if(department){
            this.selectedDepartment = department;
        }
    }

    public addRoom(room: Room){
        let department: Department = super.getById(room.departmentId);
        department.rooms.items.push(room);
    }

    public updateRoom(room: Room){
        let oldRoom = this.getRoomById(room.id);
        console.log('oldRoom');
        console.log(oldRoom);
        console.log('newRoom');
        console.log(room);

        oldRoom.loadFromRoom(room);

        let department: Department = this.items.find(d => d.id == 1);
        let testUpdateRoom: Room = department.rooms.items.find(r => r.id == 1);
        // console.log('test update room')
        // console.log(testUpdateRoom);


        // oldRoom.companyPricePerMonth = 9999;
        // testUpdateRoom.companyPricePerMonth = 9999;


        console.log(this.items);
    }

    public getRoomById(id: number): Room{
        let roomToReturn: Room;

        for (let i = 0; i < this.items.length; i++) {
            let department: Department = this.items[i];
            for (let j = 0; j < department.rooms.items.length; j++) {
                const room = department.rooms.items[j];
                if(room.id == id){
                    roomToReturn = room;
                    i = this.items.length;
                    j = department.rooms.items.length;
                }
            }
        }

        return roomToReturn;
    }

}
