import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingEventsService } from 'src/app/services/BookingEvents.service';
import { InitializationService } from 'src/app/services/initialization.service';
import { MasterDataService } from 'src/app/services/MasterData.service';
import { SignalRService } from 'src/app/services/signalR.service';
import { BookingEvent } from 'src/app/shared/models/BookingEvent';
import { EventFormFilterService } from 'src/app/services/eventFormFilter.service';
import { Equipment } from 'src/app/shared/models/Equipment';
import { ConfigurationService } from 'src/app/services/Configuration.service';
import { MsalService } from '@azure/msal-angular';
import { Users } from 'src/app/shared/models/User/Users';
import { EmailService } from 'src/app/services/Email.service';
import { BookingEvents } from 'src/app/shared/models/BookingEvents';
import { Session } from 'src/app/shared/models/session';
import { BookingEventFormFilterReturn } from 'src/app/shared/models/BookingEventFormFilterReturn';
import { environment } from 'src/environments/environment';
import { Bookings } from 'src/app/shared/models/bookings';
import { BookingService } from 'src/app/services/booking.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  bookingEvent: BookingEvent;
  routeId: any;
  bookings: Bookings;

  stateItems: {
    showAddEventButton: Boolean;
    showForm: Boolean;
    submitButtonString: string;
  } = {
    showAddEventButton : true,
    showForm : false,
    submitButtonString : "Save Event"
  }

  uiOptions: {
    editing: boolean,
  } = {
    editing: false
  }

  equipmentTableColumns: Array<string> = ["title", "quantity", "equipmentId"];
  breaksTableColumns: Array<string> = ["start", "end", "breakId"];

  showDevTools: Boolean = environment.showDevTools;

  
  
  constructor(
    public initializationService: InitializationService,
    private route: ActivatedRoute,
    private bookingEventService: BookingEventsService,
    private signalRService: SignalRService,
    public masterDataService: MasterDataService,
    public eventFormFilterService: EventFormFilterService,
    private configuartionService: ConfigurationService,
    private msalService: MsalService,
    private emailService: EmailService,
    private bookingService: BookingService
  ) { }

  ngOnInit(): void {
    
    // WAIT FOR 
    this.initializationService.initializationComplete.subscribe((initializationComplete) => {
      if (initializationComplete == true) {      
        this.routeParamConfig();
        this.initBookingEvent();
        return;
        // this.setupSignalR();
        //this.getAllBookings();
      }
    });
  }

  private initBookingEvent(){
    console.log("add logic to see if creating new booking event or editing booking event");

    this.initNewBookingEvent();

    // if(this.routeId == "newEvent"){
    //   this.initNewBookingEvent();
    //   this.uiOptions.editing = true;
    // } else {
    //   this.initBookingEventFromBookingEvent();
    // }
  }

  private initNewBookingEvent(){
    this.bookingEvent = new BookingEvent();
    this.uiOptions.editing = true;
    this.bookingEvent.edit(this.masterDataService);
  }
  
  private initBookingEventFromBookingEvent(){
    this.bookingEventService.getFormBookingEventById(this.routeId).subscribe((bookingEvent: BookingEvent) => {
      this.bookingEvent = bookingEvent;
      console.log(this.bookingEvent);
    });
  }

  public getAllBookings(){
    console.log("Get all bookings");
    this.bookingService.getAllBookings().subscribe((bookings: Bookings) => {
      this.bookings = bookings;
      console.log(bookings);
    });
  }

  routeParamConfig(){
    // Route param stuff
    this.route.params.subscribe((param) => {
    
      if(param.id == "newEvent"){
        this.stateItems.showForm = true;
      }
    
      if(!isNaN(param.id)){
        this.routeId = parseInt(param.id);
      } else {
        this.routeId = param.id;
      }
    });
  }

  setupSignalR(){
    this.signalRService.startConnection();

  }


  checkAllowedToCreateEvent(){

    //check admin constraints
    let minHoursbeforeEventCreating = parseInt(this.configuartionService.get("minHoursbeforeEventCreating").value);
    let minStartTime = new Date();
    minStartTime.setHours(minStartTime.getHours() + minHoursbeforeEventCreating);

    let sessionCount = this.eventFormFilterService.eventFormInputs.sessions.numberOfSessions;
    for (let i = 0; i < sessionCount; i++) {
      let s = this.eventFormFilterService.eventFormInputs.sessions.session[i];

      if(s.startTime < minStartTime){
        console.log('checkAllowedToCreateEvent - fail - start time < min start time');
        return false; 
      }
    }

    console.log('checkAllowedToCreateEvent - success');
    return true;
  }


  adUsers: Users;
  formStates: {
    invitationForm: boolean,
    linkForm: boolean,
  } = {
    invitationForm: false,
    linkForm: false,
  }

  // ------------------- UI ACTIONS --------------------------------
  // invitation 
  showInvitationForm(){
    this.adUsers = this.masterDataService.allActiveDirectoryUsers;

    this.resetFormStates();
    this.formStates.invitationForm = true;
  }

  //  invitation
  InvitationUserClick(user) {
    user.toggleSendInvite();
  }
  
  // invitation
  sendInvitations(){
    this.emailService.sendUsersBookingEventInvitations(this.adUsers, this.bookingEvent).subscribe(res => {
      console.log(res);
    });
  }

  requestInvitation(){
    console.log("request ivitation");
    this.emailService.requestInvitationForBookingEvent(this.bookingEvent).subscribe(res => {
      console.log("RETURN REQUEST PERMISSION FOR BOOKING EVENT");
      console.log(res);
    })
    // email the person who created the event asking for invite
  }
  
  addAttendance(){
    this.bookingEvent.addAttendance(this.bookingEventService, this.msalService).subscribe(res => {
      console.log("ATTENDANCE ADDED!")
    });
  }

  deleteAttendance(){
    console.log("delete attenance");
    this.bookingEvent.deleteAttendance(this.bookingEventService, this.msalService).subscribe(res => {
      console.log("Delete Attendance finished.");
    })
  }

  
  logEvent(){
    console.log(this.bookingEvent);
  }

  logEventForm(){
    console.log(this.bookingEvent.bookingEventForm);
  }
  
  resetFormStates(){
    this.formStates.invitationForm = false;
    this.formStates.linkForm = false;
  }
  
  
  // --------------------------------------- UI FUNCTIONS START -----------------------------------------------
  public editBookingEvent(){
    this.uiOptions.editing ? this.uiOptions.editing = false : this.uiOptions.editing = true;
    this.bookingEvent.edit(this.masterDataService);
    
    if(this.uiOptions.editing == true){
      this.filterBookingEventForm();
    }
  }

  public addSession(){
    this.bookingEvent.bookingEventForm.sessions.addSession(this.masterDataService);
  }

  public removeEquipment(equipmentIndex: number, session: Session){
    session.equipments.removeEquipmentAtIndex(equipmentIndex);
    this.filterBookingEventForm();
  }

  public addEquipmentFormSubmit(session: Session){
    this.addEquipment(session);
    this.filterBookingEventForm();
    console.log(session);
  }

  public addEquipment(session: Session){
    session.equipments.addEquipmentToSelectedEquipment(session.equipments.equipmentToAdd);
    session.equipments.resetEquipmentToAddForm();
  }

  public editQuantity(equipment: Equipment){
    equipment.editEquipmentQuantity();

    if(!equipment.editQuantity){
      this.filterBookingEventForm();
    }
  }
  
  public filterBookingEventForm(){
    this.bookingEventService.filterBookingEventForm(this.bookingEvent.bookingEventForm).subscribe((res: BookingEventFormFilterReturn) => {
      console.log("filter booking event res")
      console.log(res);
      console.log("filter booking event res")
      this.bookingEvent.bookingEventForm.setDissabledInputs(res);
    });
  }
  
  // ------------------- crud start --------------------------
  // Create/ Update BookingEvent
  public saveEvent(){
    console.log("save event");
    
    console.log(this.bookingEvent);
    
    let bookingEventsToPost = new BookingEvents();
    bookingEventsToPost.loadFromBookingEventForm(this.bookingEvent.bookingEventForm);
    
    // this.bookingEventService.getCurrentUsersFutureBookingEvents(){
      
    // }
  }
    
  // Delete BookingEvent
  public deleteBookingEvent(id: number){
    this.bookingEventService.deleteBookingEventById(id).subscribe(event => {
      this.signalRService.deleteBookingEvent(event);
    });
  }

  // ------------------- crud end --------------------------
  public cancelEventForm(){

  }

  public showEventForm(){
    this.uiOptions.editing = true;
  }

  public showEventDisplay(){
    console.log("event component - show event display");
    this.uiOptions.editing = false;
    console.log(this.uiOptions);
  }
  
  // --------------------------------------- UI FUNCTIONS END -----------------------------------------------
  logEquipment(session: Session){
    console.log(session.equipments);
  }

  logForm(){
    console.log(this.bookingEvent.bookingEventForm);
  }

}
