import { Injectable } from '@angular/core';
import { MasterDataService } from './MasterData.service';
import { BehaviorSubject } from 'rxjs';
import { ConfigurationService } from './Configuration.service';
import { AzureAdService } from './AzureAd.service';
import { SqlService } from './Sql.service';
import { Users } from '../shared/models/User/Users';
import { UserService } from './User.service';

@Injectable({
  providedIn: 'root'
})
export class InitializationService {

  initializationComplete = new BehaviorSubject<boolean>(false);

  constructor(
    private masterDataService: MasterDataService,
    private configurationService: ConfigurationService,
    private azureAdService: AzureAdService,
    private sqlService: SqlService,
    private userService: UserService,
    ) { }

  /**
   * Called by APP_INITIALIZER in app.module when the application starts
   */
  init(){ 
    this.masterDataService.loadMasterData().subscribe(() => {
      this.initializationComplete.next(true);
    });

    this.initializationComplete.subscribe((initializationComplete) => {
      if (initializationComplete == true) {
        this.configurationService.loadConfigurationData();
        this.userService.init();
    
        // THIS HAS TO BE MOVED TO A SCHEDULED TASK
        //this.updateDbUsersData();
      }
    });
  }

  // private updateDbUsersData(){
  //   this.azureAdService.getAllAdUsers().subscribe((userData: Users) => {
  //     console.log("INITIALIZATION SERVICE SET DB USER INFO - this has to be moved from initilization service to a scheduled task");
  //     this.sqlService.updateDbUsersData(userData).subscribe();
  //   });
  // }

}
