import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ReportingService } from 'src/app/services/Reporting.service';
import { ReportingMonth } from 'src/app/shared/models/ReportingMonth';
import { RootService } from 'src/app/services/Root.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {
  selectedMonth: Date;
  reportingMonth: ReportingMonth;

  @ViewChild('chartWrapper', { static: false}) chartWrapper: ElementRef;
  chartDimensions: any[] = [0, 0];

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  constructor(
    private reportingService: ReportingService,
    private rootService: RootService,
  ) { }

  ngOnInit() {
    this.initSelectedMonth();
    this.getAllRoomsUtilization().subscribe(() => {
      this.resizeCharts();
    });
  }

  private initSelectedMonth(){
    this.selectedMonth = new Date();
  }

  private getAllRoomsUtilization(){
    return this.reportingService.getReportingMonth(this.selectedMonth).pipe(map((reportingMonth: ReportingMonth) => {
      this.reportingMonth = reportingMonth;
      return null;
    }));
  }

  ngAfterViewInit(): void {
    this.rootService.navigationExpanded$.subscribe(() => {
      this.resizeCharts();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeCharts();
  }

  private resizeCharts(): void {
    // Smooth the transition for the resizing
    for (var index = 1; index <= 5; index++) {
      setTimeout(() => {
        if(this.chartWrapper){
          this.chartDimensions = [this.chartWrapper.nativeElement.offsetWidth]
        }
      }, 50 * index);
    }
  }

}
