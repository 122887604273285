import { MasterDataService } from "src/app/services/MasterData.service";
import { BaseMasterItems } from "./BaseMasterItems";
import { RoomBasic } from "./roomBasic";
import { Room } from "./room";

export class SubRooms extends BaseMasterItems {

    roomsDropdown: Array<RoomBasic>;
    selected: RoomBasic;

    public init(){
        this.items = new Array<RoomBasic>();
        this.roomsDropdown = new Array<RoomBasic>();
    }

    public loadFromMasterDataService(masterDataService: MasterDataService){
        this.initRoomsDropdown(masterDataService);
    }

    public setRooms(rooms: Array<RoomBasic>){
        rooms.forEach((room: RoomBasic) => {
            this.items.push(room);
        });
    }

    public initRoomsDropdown(masterDataService: MasterDataService){
        masterDataService.rooms.items.forEach((r: Room) => {
            let room = new RoomBasic();
            room.loadFromRoom(r);

            this.roomsDropdown.push(room);
        });
    }

    public reset(){
        this.items = new Array<RoomBasic>();
        this.roomsDropdown = new Array<RoomBasic>();
        this.selected = undefined;
    }
}
